import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RenderCurrency, RenderDollar } from '../renderField/renderReadField';
import moment from 'moment';
import { Rate } from 'antd';
import "react-responsive-modal/styles.css";
import ModalPerfilTransportista from '../../Utils/Modales/ModalPerfilTransportista';
import { ADJUDICADO_PENDIENTE_DE_INICIAR, EN_CURSO, EN_RECEPCION_OFERTAS, FINALIZADO } from '../../../../utility/constants';

moment.locale('es');

const persona_img = require('assets/img/icons/person.svg');

function CardOfertaAdjudicar(props) {

    const [openModal, setOpenModal] = useState(false);

    const { poder_adjudicar = false, estadoProyecto, descripcionTransportista, descripcion, presupuesto, creado, idTransportista, nombreTransportista, avatarTransportista, calificacionTransportista, adjudicarPropuestaaFuncion, idOferta, idProyecto, adjudicado } = props;
    return (
        <div className={`card-proyecto px-3 pt-3 mb-3 ${estadoProyecto === EN_CURSO ? "borde-azul" : estadoProyecto === EN_RECEPCION_OFERTAS ? "borde-verde" : estadoProyecto === FINALIZADO ? "borde-gris-oscuro" : estadoProyecto === ADJUDICADO_PENDIENTE_DE_INICIAR ? "borde-naranja" : "borde-rojo"}`}>
            <ModalPerfilTransportista
                onClose={() => setOpenModal(false)}
                open={openModal}
                idTransportista={idTransportista}
                nombreTransportista={nombreTransportista}
                descripcionTransportista={descripcionTransportista}
                avatarTransportista={avatarTransportista}
                calificacionTransportista={calificacionTransportista}
            />
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                <div className="row col-md-12 p-0">
                    <div className="col-lg-4 p-0 d-flex justify-content-center mb-3 align-selt-start">
                        <div className="ver-perfil" onClick={() => setOpenModal(true)}>
                           <span className="bg-naranja bold px-2">Ver perfil</span>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="d-flex align-items-center">
                                <div className="flex-2 d-flex justify-content-center align-items-center">
                                    <img src={avatarTransportista ? avatarTransportista : persona_img} alt="Persona" className="img-fluid" />
                                </div>
                                <div className="flex-5 d-flex flex-column ml-2">
                                    <span className="bold gris-oscuro">
                                        {nombreTransportista ? nombreTransportista : ""}</span>
                                    <Rate disabled defaultValue={calificacionTransportista ? calificacionTransportista : 1} className="naranja" />
                                    <span className="gris-letras bold parrafo-dos-lineas" >{descripcionTransportista ? descripcionTransportista : ""} </span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 p-0">
                        <div className="col-md-12 col-sm-12">
                            <span htmlFor="descripcion" className="m-0 gris bold">Descripción de oferta: </span>
                        </div>
                        <div className="col-md-12">
                            <p className="gris-oscuro bold mb-2">{descripcion ? descripcion : ""}</p>
                        </div>
                        <div className="col-md-12 col-sm-12 d-flex flex-column">
                            <span htmlFor="presupuesto_oferta" className="mb-1 gris bold">Presupuesto (oferta): <RenderDollar value={presupuesto ? presupuesto : 0} className="gris-oscuro bold" /></span>
                            <span className="gris-letras text-xsm">{`Enviada ${creado ? moment(creado).format('DD [de] MMMM YYYY ') : moment().format('DD [de] MMMM YYYY ')}`}</span>
                        </div>

                        {poder_adjudicar && !adjudicado &&
                            <button type="button" className="btn py-1 mt-3 mb-2 ml-2 btn-naranja" onClick={() => adjudicarPropuestaaFuncion(idProyecto, idOferta)}>ADJUDICAR PROYECTO</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CardOfertaAdjudicar;
