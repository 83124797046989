import React, { Component, Fragment } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import { USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, USER_TRANSPORTISTA } from '../../../../../utility/constants';
import { SuscripcionTransportista, SuscripcionContratista } from '../../Suscripcion'
import { Link, Redirect } from 'react-router-dom';
export default class HomeContainer extends Component {
    render() {
        const { me } = this.props;

        if (me && me.tipo_usuario && me.tipo_usuario === USER_TRANSPORTISTA) {
            return <Redirect to="/proyectos-transportistas" />
        } else {
            return <Redirect to="/proyectos" />
        }

        return (
            <Fragment>
                {/* en este caso no hay home, solo se redirecciona a la pantalla que se necesita */}
            </Fragment>
        )
    }
}
