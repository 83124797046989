import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, USER_TRANSPORTISTA } from "../../../../utility/constants";
import { Menu } from 'antd';
const { SubMenu } = Menu;

class SideBar extends Component {
    constructor(props) {
        super(props);
    }

    rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

    state = {
        openKeys: [],
    };

    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };

    navigate = (path) => {
        this.props.navToggle();
        this.props.history.push(path);
    }

    render() {
        const { toggleOpen, navToggle, logOut, user } = this.props;
        return (
            <aside
                className={`main-sidebar px-0 col-12 col-md-3 col-lg-2 ${toggleOpen ? '' : 'open'
                    }`}
            >
                <div className="main-navbar">
                    <a href="/" className="w-100 mr-0 navbar-brand mt-4">
                        <div className="d-table m-auto">
                            <img
                                id="main-logo"
                                className="main-logo img-fluid"
                                height={50}
                                src={require('assets/img/logo.svg')}
                                alt="Logo"
                            />
                        </div>
                    </a>
                    <a
                        className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                        onClick={navToggle}
                    >
                        <i className="material-icons"></i>
                    </a>
                </div>
                <div className="nav-wrapper w-100">
                    <Menu
                        mode="inline"
                        openKeys={this.state.openKeys}
                        onOpenChange={this.onOpenChange}
                        style={{ width: 256 }}
                        subMenuCloseDelay={1}
                    >
                        <Menu.Item key="suscripcion" icon={<i className="icon-menu fa fa-address-card"></i>} onClick={key => this.navigate('/suscripcion')}>
                        {user && user.tipo_usuario && (user.tipo_usuario === USER_CONTRATISTA_INDIVIDUAL || user.tipo_usuario === USER_CONTRATISTA_EMPRESA) ?
                        "Pauta publicitaria"
                            :
                        "Suscripción"
                        }
                        </Menu.Item>
                        {user && user.tipo_usuario && (user.tipo_usuario === USER_CONTRATISTA_INDIVIDUAL || user.tipo_usuario === USER_CONTRATISTA_EMPRESA) && (
                            <Fragment>
                                <Menu.Item key="proyectos-contratistas" icon={<i className="icon-menu fa fa-truck"></i>} onClick={key => this.navigate('/proyectos')}>
                                    Proyectos
                                </Menu.Item>
                                <Menu.Item key="proyectos-en-curso" icon={<i className="icon-menu fa fa-street-view"></i>} onClick={key => this.navigate('/proyectos-en-curso')}>
                                    Rastreo de proyectos
                                </Menu.Item>
                            </Fragment>
                        )}
                        {user && user.tipo_usuario && user.tipo_usuario === USER_TRANSPORTISTA && (
                            <Fragment>
                                <SubMenu key="sub1" icon={<i className="icon-menu fa fa-truck"></i>} title="Proyectos">
                                    <Menu.Item className="" key="mis-proyectos-transportistas" onClick={key => this.navigate('/mis-proyectos-transportistas')}>Mis proyectos</Menu.Item>
                                    <Menu.Item className="" key="proyectos-transportistas" onClick={key => this.navigate('/proyectos-transportistas')}>Busqueda de oportunidad</Menu.Item>
                                </SubMenu>
                                <Menu.Item key="pilotos-transportistas" icon={<i className="icon-menu fa fa-users"></i>} onClick={key => this.navigate('/pilotos-transportista')}>
                                    Pilotos
                                </Menu.Item>
                                <Menu.Item key="panel-financiero" icon={<i className="icon-menu fa fa-money"></i>} onClick={key => this.navigate('/panel-financiero-transportista')}>
                                    Panel financiero
                                </Menu.Item>
                                <Menu.Item key="proyectos-en-curso-transportistas" icon={<i className="icon-menu fa fa-street-view"></i>} onClick={key => this.navigate('/proyectos-en-curso-transportistas')}>
                                    Rastreo de proyectos
                                </Menu.Item>
                            </Fragment>
                        )}

                        {user && user.suscripcion && (
                            <Menu.Item key="publicidad" icon={<i className="icon-menu fa fa-tag"></i>} onClick={key => this.navigate('/publicidades')}>
                                Publicidad
                            </Menu.Item>
                        )}
                    </Menu>

                    <div className="text-center py-3 bg-gris">
                        <img
                            className="main-logo img-fluid"
                            height={50}
                            src={require('assets/img/logomenu.png')}
                            alt="Logo"
                        />
                    </div>
                </div>
            </aside>
        );
    }
}

export default withRouter(SideBar);
