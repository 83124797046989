import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/pilotos';
import Crear from './PilotoCrear';

const mstp = state => {
    return {
        ...state.pilotos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Crear)
