import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';

const LOADER = 'LOADER_CHAT';
const DATA = 'DATA_CHAT';
const ITEM_DATA = 'ITEM_CHAT';
const PAGE = 'PAGE_CHAT';
const ORDERING = 'ORDERING_CHAT';
const SEARCH = 'SEARCH_CHAT';
const SOCKET = 'SOCKET_CHAT';
const CHAT_ACTIVE = 'CHAT_ACTIVE_CHAT';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setSocket = socketRef => ({
    type: SOCKET,
    socketRef,
});

const setChatActive = chatActive => ({
    type: CHAT_ACTIVE,
    chatActive,
});

// ------------------------------------
// Actions
// ------------------------------------

const getInfoChats = (page = 1) => (dispatch, getStore) => {
    const params = {};
    dispatch(setLoader(true));
    api.get('chats', {}, params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const createChat = (email) => (dispatch) => {
    const params = {};
    params.email = email;
    dispatch(setLoader(true));
    api.post('chats', {}, params).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        // dispatch(cleanForm()); // Limpiar el formulario
        // dispatch(push('/pilotos-transportistas'));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const updateChat = (chat_id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`${'chats'}/${chat_id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        // dispatch(push('/pilotos-transportistas'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    getInfoChats,
    createChat,
    updateChat,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SOCKET]: (state, { socketRef }) => {
        return {
            ...state,
            socketRef,
        };
    },
    [CHAT_ACTIVE]: (state, { chatActive }) => {
        return {
            ...state,
            chatActive,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    socketRef: null,
    chatActive: false,
};

export default handleActions(reducers, initialState);
