import React, { Component, Fragment } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import { USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, USER_TRANSPORTISTA } from '../../../../../utility/constants';
import { SuscripcionTransportista, SuscripcionContratista } from '../index'
import { Link, Redirect } from 'react-router-dom';
export default class HomeContainer extends Component {
    render() {
        const { me } = this.props;
        return (
            <Fragment>
                {/* USUARIOS TRANSPORTISTAS */}
                {me && me.tipo_usuario && me.tipo_usuario === USER_TRANSPORTISTA &&
                    <Fragment>
                        {
                            !me.suscripcion ?
                                <SuscripcionTransportista titulo="Adquirir Suscripción" />
                                :
                                <SuscripcionTransportista titulo="Cambiar suscripción" />
                        }
                    </Fragment>

                }

                {/* USUARIOS CONTRATISTAS */}
                {me && me.tipo_usuario && (me.tipo_usuario === USER_CONTRATISTA_INDIVIDUAL || me.tipo_usuario === USER_CONTRATISTA_EMPRESA) &&
                    <Fragment>
                        {
                            !me.suscripcion ?
                                <SuscripcionContratista titulo="Adquirir pauta publicitaria" />
                                :
                                <SuscripcionContratista titulo="Cambiar pauta publicitaria" />
                        }
                    </Fragment>
                }
            </Fragment>
        )
    }
}
