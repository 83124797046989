import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators, combine } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { USER_TRANSPORTISTA} from '../../../../../utility/constants'
import { renderField, renderFilePicker, renderMonthPicker, renderCVC, renderNumber, renderSwitch, SelectField } from '../../../Utils/renderField/renderField';
import { RenderDollar, RenderQuetzal } from '../../../Utils/renderField/renderReadField';
import moment from 'moment';
import { validateCreditCard } from '../../../../../utility/validationForm'

const RenderDollarAndQuetzal = ({ titulo, monto, tasa_cambio, grande = false }) => {
    titulo = titulo ? titulo : ""
    monto = monto ? monto : 0
    tasa_cambio = tasa_cambio ? tasa_cambio : 0
    return (
        <div className="col-md-6 mb-2">
            <label className="m-0">{titulo}</label>
            <br />
            <div className="d-flex flex-wrap bg-white">
                <RenderDollar className={`flex-1 border bold  naranja pl-1 ${grande ? "text-md" : "text-sm"}`} value={monto} />
                <RenderQuetzal className={`flex-1 border bold text-right azul pl-1 ${grande ? "text-md" : "text-sm"}`} value={monto * tasa_cambio} />

            </div>
        </div>
    )
}

let PasarelaForm = props => {
    const { handleSubmit, item, me, suscripcionPagar, suscripcion_activa, dias_para_vencer, tipoUsuario } = props;
    let texto = USER_TRANSPORTISTA == tipoUsuario ? "suscripción": "pauta publicitaria"
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                {suscripcion_activa &&
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-12 p-0">
                            <div className="col-md-12 col-sm-12">
                                <span htmlFor="nombre" className="m-0 pl-1 gris bold">Nota importante:</span>
                            </div>
                            <div className="col-md-12 form-group ml-1">
                                <span className="bold text-sm gris-oscuro">{`Tienes una ${texto} activa que vence en ${dias_para_vencer == 1 ? "1 día" : dias_para_vencer + " dias"}. Esta nueva ${texto} a pagar sustituirá la anterior y desde el momento del pago vuelve a correr el tiempo de tu nueva ${texto}.`}</span>
                            </div>
                        </div>
                    </div>
                }

                <div className="row  col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <span htmlFor="nombre" className="m-0 pl-1 gris bold">Nombre</span>
                        </div>
                        <div className="col-md-12 form-group">
                            <span className="bold text-sm gris-oscuro pl-1">{me && me.nombre ? me.nombre : ""}</span>
                        </div>
                    </div>
                </div>

                <div className="row d-flex align-items-end col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <span htmlFor="nombre" className="m-0 pl-1 gris bold">Teléfono</span>
                        </div>
                        <div className="col-md-12  form-group">
                            <span className="bold text-xs gris-oscuro pl-1">{me && me.telefono ? me.telefono : ""}</span>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <span htmlFor="telefono" className="m-0 pl-1 gris bold">E-mail</span>
                        </div>
                        <div className="col-md-12  form-group">
                            <span className="bold text-xs gris-oscuro pl-1">{me && me.email ? me.email : ""}</span>
                        </div>
                    </div>
                </div>
                {/* Inicio del formulario */}
                <div className="row  col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 my-3">
                            <span className="bold text-sm gris-oscuro pl-1">DATOS DE TARJETA</span>
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0 align-self-end">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="no_tarjeta" className="m-0">No. de tarjeta*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="no_tarjeta"
                                numberFormat="#### #### #### ####"
                                placeholder="#### #### #### ####"
                                component={renderNumber}
                                className="form-control"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="mes_anio_vencimiento" className="m-0">Mes / Año de vencimiento*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="mes_anio_vencimiento" component={renderMonthPicker} pago type="text" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="codigo_seguridad" className="m-0">Código de seguridad (CVC)*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="codigo_seguridad"
                                // numberFormat="###"
                                // placeholder="###"
                                component={renderCVC}
                                className="form-control"
                                type="password"
                                maxLength="4"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 text-right mt-auto mb-3">
                        <span className="azul bold text-xsm mr-4">* Campos requeridos</span>
                    </div>
                </div>
                <div className="row col-md-12 p-0 mx-0 border-publicidad py-3 bg-gris-muy-claro">
                    <RenderDollarAndQuetzal titulo='Costo de la suscripción' monto={item[suscripcionPagar]} tasa_cambio={item.tasa_cambio} />
                    <RenderDollarAndQuetzal titulo='IVA*' monto={item[suscripcionPagar] * 0.12} tasa_cambio={item.tasa_cambio} />
                    <div className="col-md-6 mb-2" />
                    <RenderDollarAndQuetzal grande titulo='COSTO TOTAL' monto={(item[suscripcionPagar] * 0.12) + item[suscripcionPagar]} tasa_cambio={item.tasa_cambio} />
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-dark btn-block" to={`/suscripcion`}>Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Pagar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};

PasarelaForm = reduxForm({
    form: 'PasarelaSuscripcionForm',
    validate: validateCreditCard,
})(PasarelaForm);

export default PasarelaForm
