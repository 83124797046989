import React, { Fragment } from 'react';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderTextArea } from '../../../Utils/renderField/renderField';

let SolicitarTrayectoForm = props => {
    const { handleSubmit, onClose } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                <div className="row col-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="origen" className="m-0">Origen*</label>
                        </div>
                        <div className="col-12  form-group">
                            <Field name="origen" component={renderField} type="text" className="form-control" maxLength="254" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="destino" className="m-0">Destino*</label>
                        </div>
                        <div className="col-12  form-group">
                            <Field name="destino" component={renderField} type="text" className="form-control" maxLength="254" />
                        </div>
                    </div>

                    <div className="col-12 p-0">
                        <div className="col-12">
                            <label htmlFor="descripcion" className="m-0">Descripción de la solicitud*</label>
                        </div>
                        <div className="col-12  form-group">
                            <Field name="descripcion" component={renderTextArea} type="text" className="form-control" maxLength="254" />
                        </div>
                    </div>
                    <div className="col-12 p-0 text-right">
                        <span className="azul bold text-xsm mr-2">* Campos requeridos</span>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                        <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                            <button type="button" className="btn btn-dark btn-block" onClick={onClose}>
                                Cancelar
                            </button>
                        </div>
                        <div className="col-md-5 col-lg-5 col-xl-4 col-12 mt-md-0 mt-3">
                            <button type="submit" className="btn btn-primary btn-block">Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
SolicitarTrayectoForm = reduxForm({
    form: 'SolicitarTrayectoForm',
    validate: data => {
        return validate(data, {
            'origen': validators.exists()('Campo requerido.'),
            'destino': validators.exists()('Campo requerido.'),
            'descripcion': validators.exists()('Campo requerido.'),
        })
    }
})(SolicitarTrayectoForm);

export default SolicitarTrayectoForm
