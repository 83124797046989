import { handleActions } from 'redux-actions';
import { initialize as initializeForm, change } from 'redux-form';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------
const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('register', {}));
};

export const onSubmit = (data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('user', data, attachments).then(() => {
        dispatch(push("/login"));
        dispatch(cleanForm()); // Limpiar el formulario
        NotificationManager.success('Su información fue enviada para ser validada', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Datos incorrectos, vuelva a intentar';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 5000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setPolizas = (polizas_nuevos) => (dispatch, getStore) => {
    const FORM_PRINCIPAL = getStore().form.register.values;
    let form_principal = FORM_PRINCIPAL ? _.cloneDeep(FORM_PRINCIPAL) : {};

    let polizas = form_principal.polizas ? _.cloneDeep(form_principal.polizas) : [];

    for (let index = 0; index < polizas.length; index++) {
        if (polizas_nuevos[index] != "x" && polizas_nuevos[index]) {
            polizas[index].imagen = polizas_nuevos[index]
        }

    }
    form_principal.polizas = polizas
    dispatch(initializeForm('register', form_principal));

}

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
};

const clearSelectPais = () => (dispatch) => {
    dispatch(change("register", "ciudad_residencia", null));
}

export const actions = {
    onSubmit,
    logOut,
    clearSelectPais,
    setPolizas,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

export const initialState = {
    loader: false,
};

export default handleActions(reducers, initialState);
