import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/proyectos';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import ProyectosList from './ProyectoCrear';

const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.proyectos,
    };
};

const md2p = { ...actions, getMe };

export default connect(ms2p, md2p)(ProyectosList);
