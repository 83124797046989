import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './PublicidadForm';
import EditarPublicidadForm from './PublicidadForm';

export default class Crear extends Component {
    state = {
        editar: false,

        img_publicidad_file_img: [],
        img_publicidad_base64: []

    };

    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
            this.setState({ editar: true });
        }
    }


    setImgsPublicidad = async (key, img_publicidad) => {
        const _img_publicidad = await fetch(img_publicidad);
        const blob_img_publicidad = await _img_publicidad.blob();
        // console.log("IMG ", key, " - ", blob_img_publicidad);
        // this.setState({ [key]: blob_img_publicidad });
        // console.log("files ", files);
        const img_publicidad_file_img = this.state.img_publicidad_file_img;
        const img_publicidad_base64 = this.state.img_publicidad_base64;

        for (let index = 0; index < key + 1; index++) {
            if (key == index) {
                img_publicidad_file_img[index] = blob_img_publicidad
                img_publicidad_base64[index] = img_publicidad
            } else {
                if (img_publicidad_file_img[index]) {
                    // console.log("key" + index + " tiene datos")
                } else {
                    // console.log("key" + index + " No tiene datos")
                    img_publicidad_file_img[index] = "x"
                    img_publicidad_base64[index] = "x"
                }
            }
        }
        this.setState({ img_publicidad_file_img });
        this.props.setArchivos(img_publicidad_base64);
    }


    eliminarImgsPublicidad = (index) => {
        let img_publicidad_file_img = this.state.img_publicidad_file_img;
        img_publicidad_file_img = _.filter(img_publicidad_file_img, (item, i) => i != index);
        // Seteamos en redux y el state
        this.setState({ img_publicidad_file_img });
        this.props.eliminarArchivo(index);

    }

    crear = (data) => {
        let datos = { ...data }
        const docs = []
        for (let i = 0; i < datos.detalle_publicidad.length; i++) {
            datos.detalle_publicidad[i].imagen = ""
            if (this.state.img_publicidad_file_img[i] != "x") {
                docs.push({ "file": this.state.img_publicidad_file_img[i], "name": `img_publicidad_${i}` })
            }
        }
        // console.log("DATA ", datos);
        // console.log("ATTACH ", docs);
        this.props.crear({ ...datos }, docs)
    }

    actualizar = (data) => {
        let datos = { ...data }
        const docs = []
        for (let i = 0; i < datos.detalle_publicidad.length; i++) {
            datos.detalle_publicidad[i].imagen = ""
            if (this.state.img_publicidad_file_img[i] != "x") {
                docs.push({ "file": this.state.img_publicidad_file_img[i], "name": `img_publicidad_${i}` })
            }
        }
        this.props.editar(this.props.match.params.id, { ...data }, docs);
    };

    render() {
        const { crear, editar } = this.props;
        const { loader, item, cleanForm } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm titulo= {this.state.editar ?"EDITAR PUBLICIDAD" :"PUBLICIDAD NUEVA"}>
                    <LoadMask loading={loader} blur>
                        {
                            this.state.editar ?
                                <EditarPublicidadForm
                                    onSubmit={this.actualizar}
                                    editando={true}
                                    item={item}
                                    setImgsPublicidad={this.setImgsPublicidad}
                                    eliminarImgsPublicidad={this.eliminarImgsPublicidad}
                                />
                                :
                                <Form
                                    onSubmit={this.crear}
                                    setImgsPublicidad={this.setImgsPublicidad}
                                    eliminarImgsPublicidad={this.eliminarImgsPublicidad}
                                    cleanForm={cleanForm}
                                />
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

