import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { tipo_contratista, USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL } from '../../../../utility/constants';
import { renderField } from '../../Utils/renderField';
import { SelectField, renderNumber, renderFilePicker } from '../../Utils/renderField/renderField';
import { validationContratista } from '../../../../utility/validationForm'

let RegisterForm = props => {
    const { handleSubmit, setRtuImg, nombre, registro_individual, registro_empresa } = props;
    return (
        <form name="loginForm" className="form-validate d-flex flex-sm-row align-items-center" onSubmit={handleSubmit}>
            <div className="col-12">
                <h3 className="azul text-center mb-2">Registro</h3>
                <div className="row col-md-12 p-0 m-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="tipo_usuario">Tipo de usuario</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="tipo_usuario" component={SelectField} options={tipo_contratista} placeholder="Tipo de usuario..." type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre">{nombre}*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="nombre"
                                component={renderField}
                                type="text"
                                className="form-control"
                                maxLength={validationContratista.nombre.MaxLength}
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0 m-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="username">Usuario*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="username"
                                component={renderField}
                                type="text"
                                className="form-control"
                                maxLength={validationContratista.username.MaxLength}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nit">Nit*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="nit"
                                component={renderField}
                                type="text"
                                className="form-control"
                                maxLength={validationContratista.nit.MaxLength}
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0 m-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email">E-Mail*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="email" className="form-control" maxLength={validationContratista.email.MaxLength} />
                        </div>
                    </div>
                    {registro_individual && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="telefono">Teléfono*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="telefono" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationContratista.telefono.MaxLength} />
                            </div>
                        </div>
                    )}
                    {registro_empresa && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="rtu">RTU / patente de comercio*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="rtu" component={renderField} type="text" className="form-control" maxLength={validationContratista.rtu.MaxLength} />
                            </div>
                        </div>
                    )}
                </div>

                {registro_empresa && (
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono">Teléfono*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationContratista.telefono.MaxLength} />
                        </div>
                    </div>
                )}
                {registro_empresa && (
                    <div className="row col-md-12 p-0 m-0">
                        <div className="col-md-12 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="rtu_img">Imagen de RTU (Opcional)</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field photo={null} setFile={setRtuImg} name="rtu_img" component={renderFilePicker} />
                            </div>
                        </div>
                    </div>
                )}
                <div className="col-12 text-right">
                    <span className="azul bold text-xsm">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-6 col-12">
                        <Link className="btn btn-dark btn-block" to="/login">Regresar</Link>
                    </div>
                    <div className="col-md-6 col-12 mt-md-0 mt-3 login">
                        <button type="submit" className="btn btn-primary btn-block">Registrarse</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const selector = formValueSelector('CuponForm');
RegisterForm = reduxForm({
    form: 'register', // a unique identifier for this form
    initialValues: {
        tipo_usuario: USER_CONTRATISTA_INDIVIDUAL
    },
    validate: (data) => {
        return validate(data, {
            username: validationContratista.username.Combine,
            nombre: validationContratista.nombre.Combine,
            rtu: validationContratista.rtu.Combine,
            telefono: validationContratista.telefono.Combine,
            nit: validationContratista.nit.Combine,
            email: validationContratista.email.Combine,
        });
    },
})(RegisterForm);

RegisterForm = connect(state => {
    const form = state.form.register;
    let nombre = "";
    let registro_individual = false;
    let registro_empresa = false;

    if (form && form.values) {
        const values = form.values;
        if (values.tipo_usuario === USER_CONTRATISTA_INDIVIDUAL) {
            nombre = "Nombre completo";
            registro_individual = true;
            registro_empresa = false;
            values.rtu = null;
        }
        if (values.tipo_usuario === USER_CONTRATISTA_EMPRESA) {
            nombre = "Nombre comercial";
            registro_individual = false;
            registro_empresa = true;
        }
    }
    return {
        nombre,
        registro_individual,
        registro_empresa,
    }
})(RegisterForm);

export default RegisterForm;
