import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import PasarelaForm from './PagarsuscripcionForm';
import { TIPO_SUSCRIPCION, USER_TRANSPORTISTA, SUSCRIPCIONES_PRECIO } from '../../../../../utility/constants'
import { Link, Redirect } from 'react-router-dom';
import moment from "moment";

export default class PasarelaPago extends Component {
    state = {
        suscripcionPagar: false,
        tipoSuscripcion: false,
        tipoUsuario: 0,
    };

    componentWillMount() {
        this.props.leer()
        const suscripcion = this.props.match.params.suscripcion

        if (suscripcion == "mensual" || suscripcion == "semestral" || suscripcion == "anual") {

            if (this.props.me.tipo_usuario === USER_TRANSPORTISTA) {
                this.setState({
                    suscripcionPagar: SUSCRIPCIONES_PRECIO.transportistaSuscripcion[suscripcion],
                    tipoUsuario: USER_TRANSPORTISTA,
                });
            } else {
                this.setState({
                    suscripcionPagar: SUSCRIPCIONES_PRECIO.contratistaSuscripcion[suscripcion]
                });
            }
            this.setState({
                tipoSuscripcion: TIPO_SUSCRIPCION[suscripcion]
            });


        }

    }

    pagar = (data) => {
        const { item } = this.props;
        const { suscripcionPagar, tipoSuscripcion } = this.state
        if (item && tipoSuscripcion && suscripcionPagar) {
            // En dado exisitiera un error al traer el precio se le envia un null, y el back no padra continuar
            data.monto_total = item && item[suscripcionPagar] ? item[suscripcionPagar] : null
            data.tipo_suscripcion = tipoSuscripcion
            data.mes_expiracion = moment(data.mes_anio_vencimiento).format("MM")
            data.anio_expiracion = moment(data.mes_anio_vencimiento).format("YYYY")
        }
        this.props.pagar(data)
    }

    render() {
        const { loader, me, item } = this.props;
        const suscripcion = this.props.match.params.suscripcion


        if (!(suscripcion == "mensual" || suscripcion == "semestral" || suscripcion == "anual")) {
            return <Redirect to="/" />
        }
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm titulo={`PAGO ${USER_TRANSPORTISTA == this.state.tipoUsuario ? "SUSCRIPCION": "PAUTA PUBLICITARIA"} ${suscripcion.toUpperCase()}`}>
                    <LoadMask loading={loader} blur>
                        <PasarelaForm
                            onSubmit={this.pagar}
                            me={me}
                            item={item}
                            tipoUsuario={this.state.tipoUsuario}
                            suscripcionPagar={this.state.suscripcionPagar}
                            suscripcion_activa={me && me.suscripcion ? me.suscripcion : false}
                            dias_para_vencer={me && me.suscripcion_activa && me.suscripcion_activa.dias_para_vencer ? me.suscripcion_activa.dias_para_vencer : false}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
