import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RenderCurrency, RenderDollar } from '../renderField/renderReadField';
import moment from 'moment';
import { Rate } from 'antd';
import "react-responsive-modal/styles.css";
import ModalPerfilTransportista from '../../Utils/Modales/ModalPerfilTransportista';

moment.locale('es');



const persona_img = require('assets/img/icons/person.svg');

function CardOfertaPequeno(props) {
    const [openModal, setOpenModal] = useState(false);

    const { descripcionTransportista, calificacionTransportista, presupuesto, creado, idTransportista, nombreTransportista, avatarTransportista, idOferta, idProyecto, adjudicado } = props;
    return (
        <div className="col-md-12 col-lg-6 col-xl-4 col-12 mb-3 px-0 px-lg-3">

            <ModalPerfilTransportista
                onClose={() => setOpenModal(false)}
                open={openModal}
                nombreTransportista={nombreTransportista}
                descripcionTransportista={descripcionTransportista}
                avatarTransportista={avatarTransportista}
                calificacionTransportista={calificacionTransportista}
                idTransportista={idTransportista}
            />
            <div className="card-proyecto borde-naranja">
                <div className="position-relative p-0 px-3 pt-3 d-flex flex-column flex-sm-row  ">
                    <div className="mt-n3 mx-n3 ver-perfil h-75" onClick={() => setOpenModal(true)}>
                        <span className="bg-naranja bold px-2">Ver perfil</span>
                    </div>

                    <div className="col-6 col-md-5 col-lg-4">
                        <img src={avatarTransportista ? avatarTransportista : persona_img} alt="Persona" className="img-fluid mr-2" />
                    </div>

                    <div className="d-flex flex-column">

                        <span className="bold gris-oscuro text-sm mb-0"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setOpenModal(true)}>
                            {nombreTransportista ? nombreTransportista : ""}
                        </span>

                        <Rate disabled defaultValue={calificacionTransportista ? calificacionTransportista : 1} className="naranja mt-0" />
                        <p className="gris-letras bold parrafo-dos-lineas" >{descripcionTransportista ? descripcionTransportista : ""}   </p>
                        <span className="mb-1 gris-letras bold">Presupuesto <RenderDollar value={presupuesto ? presupuesto : 0} className="gris-oscuro bold" /></span>
                        <span className="gris-letras text-xsm">{`Recibido ${creado ? moment(creado).format('DD [de] MMMM YYYY ') : moment().format('DD [de] MMMM YYYY ')}`}</span>
                    </div>
                </div>
                {!adjudicado &&
                    <div className="p-0 px-3 pt-1">
                        <div className="d-flex flex-wrap justify-content-end align-items-center">

                            <div className="mb-2">
                                <Link className="btn py-1 btn-naranja" to={`/proyecto_oferta/${idProyecto}/${idOferta}`} >Ver Oferta</Link>
                            </div>
                        </div>
                    </div>
                }


            </div>
        </div>
    )
}
export default CardOfertaPequeno;
