import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
const logo = require('../../../../../assets/img/logo_pequeno.png')
import "./verify_login.css";



class VerifyLogin extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="verify_login" >
                <div
                    style={{
                        position: "absolute",
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgb(50 50 50 / 100%)",
                    }}
                >
                    <img src={logo}/>
                    <p
                        style={{
                            animation: "color infinite linear 2s",
                            animationDelay: "2s",
                            fontSize: "2rem",
                            color: "white",
                            fontWeight: "bold",

                        }}
                        className="load-texto mbt-3"
                    >Cargando...</p>
                    <Loader
                        type={'Puff'}
                        color="#FFF"
                        height="100"
                        width="100"
                    />
                </div>
            </div>
        )
    }
}

export default VerifyLogin;
