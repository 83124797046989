import React,{useState} from 'react';
import { Modal } from 'react-responsive-modal';
const persona_img = require('assets/img/icons/person.svg');
import { Rate } from 'antd';

import LoadMask from '../../Utils/LoadMask/LoadMask'

let ModalCalificarTransportista = props => {
    const { open, onClose,nombreTransportista, avatarTransportista, nombreViaje, direccion_origen, direccion_destino, idViaje, loader} = props; 

    const [calificacion, setCalificacion] = useState(0)
    const [comentario, setComentario] = useState("")
    const [calificado, setCalificado] = useState(true)

    const calificarTransportista = () => {
        const data = {
            calificacion: calificacion,
            comentario: comentario,
            idViaje: idViaje,
        }
        if (calificacion == 0 ) {
            setCalificado(false)            
        }else{
            props.calificarTransportista(data)
            setComentario("")
            setCalificacion(0) 
        }
    }    

    return(
        <Modal open={open} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} showCloseIcon={false} classNames={{modal: "d-flex align-items-center"}}>
            <LoadMask loading={loader} blur>
                <div className="col-12 px-0 px-sm-3">            
                    <div className="row justify-content-center ">
                        <div className="col-12 px-1 px-sm-3" >
                            <div className="mb-0 form-group grid-container justify-content-center d-flex flex-column align-items-center" 
                            style={{
                                border: "1px solid #FA7D09",
                                borderRadius: "15px",
                                opacity: 1,
                            }}
                            >
                                <div className="d-flex w-100 bg-naranja" style={{ height: '26px', borderRadius: "25px" }} />
                                    <div className="px-0 col-9 text-center mb-3 pt-2">
                                        <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                                            <div className="flex-5 d-flex col-6 col-md-5 col-lg-4">
                                                <img src={avatarTransportista ? avatarTransportista: persona_img } alt="Persona" className="img-fluid"/>
                                            </div>
                                            <span className="text-lg gris-oscuro bold text-left">{nombreTransportista ? nombreTransportista : ""}</span>
                                        
                                        </div>
                                    </div>
                            </div>
                        </div>

                            <div className="col-11 pt-2 px-0">
                            <div className="col-12 p-0 d-flex justify-content-center pb-2"  style={{borderBottom: "1px black solid"}} >
                                <div className="px-0 py-3 col-10 d-flex flex-column flex-sm-row justify-content-start"  >
                                        <div className="p-0 d-flex flex-column col-12">
                                            <span className="text-sm  mb-1 naranja bold">VIAJE FINALIZADO</span>
                                            <span className="text-sm  gris-oscuro bold">{nombreViaje ? nombreViaje : ""}</span>
                                            <span className="mb-1 gris-letras bold">Origen: <span className="gris-oscuro bold">{direccion_origen ? direccion_origen : ""}</span></span>
                                            <span className="mb-1 gris-letras bold">Destino: <span className="gris-oscuro bold">{direccion_destino ? direccion_destino :  ""}</span></span>
                                        </div>
                                </div>
                            </div>                                 
                        </div>

                            <div className="col-11 pt-2 px-0">
                                <div className="col-12 p-0 d-flex justify-content-center pb-2"  style={{borderBottom: "1px black solid"}} >
                                    <div className="px-0 py-3 col-10 d-flex flex-column flex-sm-row justify-content-center align-items-center"  >
                                        <span className="gris-oscuro">Valoración de viaje</span>
                                            <div className="flex-1 d-flex flex-column ml-2 text-left">
                                            <Rate 
                                            onChange={(value) => {
                                                setCalificado(true)
                                                setCalificacion(value)
                                            }} 
                                            defaultValue={0} 
                                            value={calificacion}
                                            className="naranja estrellaGrande"/>

                                                {!calificado &&  <div className="invalid-feedback d-inline">Asigne un punteo</div>  }
                                        </div>
                                    </div>
                                </div>                                 
                            </div>                   

                        <div className="col-10 mt-3 px-0">                        
                            <span className="gris-oscuro">Haz un comentario al transportista:</span>
                            <textarea
                                placeholder="Opcional."
                                type="text"
                                className="form-control"
                                onChange={(message) => setComentario(message.target.value)}
                                autoFocus={true}
                                value={comentario}
                            />

                        </div>           

                        <div className="col-12 mt-2">
                            <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                                <div className="col-md-6 col-12">
                                    <button className="btn btn-primary btn-block"
                                        onClick={calificarTransportista}>
                                        GUARDAR
                                    </button>
                                </div>
                            </div>                        
                        </div>

                    </div>
                </div>
            </LoadMask>            
        </Modal>
    )
}
export default ModalCalificarTransportista;
