import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";

export default class ListarPilotos extends Component {
    componentWillMount() {
        const { listar, page, me } = this.props;
        listar(page, me.id);
    }
    onPageChange = (page) => {
        const { listar, me } = this.props;
        listar(page, me.id);
    }

    render() {
        const { onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <Card
                    titulo="PILOTOS">
                    <Header to="piloto-transportista/crear" textBtn="Crear piloto" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={this.onPageChange} onSortChange={onSortChange} page={page} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ editar: "piloto-transportista", eliminar })}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="no_licencia"
                            dataSort
                        >
                            LICENCIA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="telefono"
                            dataSort
                        >
                            TELÉFONO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
