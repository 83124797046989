import React, { useState } from 'react';
import { Carousel } from "react-responsive-carousel";
import { Collapse } from 'antd';
const { Panel } = Collapse;
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const flecha_left = require('../../../../../assets/img/icons/arrow-left.svg')
const flecha_right = require('../../../../../assets/img/icons/arrow-right.svg')

function BannerPublicidad(props) {
    const { items } = props;
    const [currentSlide, setCurrentSlide] = useState(0);

    const next = () => {
        setCurrentSlide(currentSlide + 1);
    };

    const prev = () => {
        setCurrentSlide(currentSlide - 1);
    };

    const updateCurrentSlide = (index) => {
        if (currentSlide !== index) {
            setCurrentSlide(index);
        }
    };
    return (
        <div className="mx-3 card-empty card-small">
            <Collapse defaultActiveKey={['1']} ghost>
                <Panel header="Promociones*" key="1">
                    <div className="card-content p-0 px-2 d-flex justify-content-between">
                        <div className="d-flex justify-content-center align-items-center mr-2" onClick={prev} style={{ cursor: "pointer" }}>
                            <img src={flecha_left} alt="Left arrow" className="img-fluid" width={items ? 150 : 25} />
                        </div>
                        <div >
                            <Carousel
                                className="tamano-carousel"
                                autoPlay={true}
                                showArrows={false}
                                showStatus={false}
                                showIndicators={false}
                                infiniteLoop
                                showThumbs={false}
                                useKeyboardArrows={false}
                                stopOnHover
                                interval={5000}
                                selectedItem={currentSlide}
                                onChange={updateCurrentSlide}
                            >
                                {items && items.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <img src={item.imagen} alt={index} />
                                        </div>
                                    )
                                })}
                                {/* <div>
                                    <img src={require("../../../../../../../media_devel/publicidades/blob.jpeg")} alt=""/>
                                </div>
                                <div>
                                    <img src={require("../../../../../../../media_devel/publicidades/blob_YXfr1AQ.jpeg")} alt=""/>
                                </div> */}
                            </Carousel>
                        </div>
                        <div className="d-flex justify-content-center align-items-center ml-2" onClick={next} style={{ cursor: "pointer" }}>
                            <img src={flecha_right} alt="Right arrow" className="img-fluid" width={items ? 150 : 25} />
                        </div>
                    </div>
                </Panel>
            </Collapse>
        </div>
    )
}

export default BannerPublicidad;
