import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux';
import { solicitarNuevoTrayecto } from '../../../../redux/modules/proyectos';

import SolicitarTrayectoForm from '../../../components/App/Proyectos/Crear/SolicitarTrayectoForm'

let ModalSolicitudTrayecto = props => {
    const { open, onClose, solicitarNuevoTrayecto } = props;

    return (
        <Modal open={open} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} showCloseIcon={false} classNames={{ modal: "d-flex align-items-center tamano-modal" }}>
            <div className="col-12 px-0 px-sm-4">
                <div className="row justify-content-center ">
                    <div className="col-12 " >
                        <div className="form-group grid-container justify-content-center d-flex flex-column align-items-center"
                            style={{
                                border: "1px solid #FA7D09",
                                borderRadius: "15px",
                                opacity: 1,
                            }}
                        >
                            <div className="d-flex w-100 bg-naranja" style={{ height: '26px', borderRadius: "25px" }} />
                            <div className="col-11 text-center">
                                <p className="text-sm gris-oscuro bold my-3">¿No encuentras el origen y destino deseado?</p>
                                <p className="text-justify gris-oscuro">Si no encuentras el lugar de origen o destino para tu proyecto por favor llena el formulario para poder solicitarlo y que los administradores verifiquen la viabilidad de agregar el lugar a la plataforma.</p>
                            </div>
                        </div>
                    </div>
                    <SolicitarTrayectoForm
                        onClose={onClose}
                        onSubmit={solicitarNuevoTrayecto}
                    />
                </div>
            </div>
        </Modal >
    )
}
const mstp = state => {
    return {
    }
};

const mdtp = {
    solicitarNuevoTrayecto
};

export default connect(mstp, mdtp)(ModalSolicitudTrayecto)


