import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
const persona_img = require('assets/img/icons/person.svg');
import { Rate } from 'antd';
import { connect } from 'react-redux';
import { listarComentariosTransportista } from '../../../../redux/modules/cuenta/profile';


let ModalPerfilTransportista = props => {
    const { open, onClose, nombreTransportista, avatarTransportista, calificacionTransportista, descripcionTransportista, idTransportista, listarComentariosTransportista, comentarios } = props;
    const [verMas, setVerMas] = useState(false);

    useEffect(() => {
        let id = idTransportista ? idTransportista : 0
        if (open) {
            listarComentariosTransportista(id)
        } if (verMas) {
            setVerMas(false)
        }

    }, [open]);

    return (
        <Modal open={open} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} showCloseIcon={false} classNames={{ modal: "d-flex align-items-center px-0 px-md-4" }}>
            <div className="col-12">
                <div className="row justify-content-center ">
                    <div className="col-12 px-0 px-md-4" >
                        <div className="form-group grid-container justify-content-center d-flex flex-column align-items-center"
                            style={{
                                border: "1px solid #FA7D09",
                                borderRadius: "15px",
                                opacity: 1,
                            }}
                        >
                            <div className="d-flex w-100 bg-naranja" style={{ height: '26px', borderRadius: "25px" }} />
                            <div className="col-9 text-center mb-3 pt-2">
                                <div className="d-flex w-100 flex-column justify-content-start">
                                    <div className="flex-5 d-flex col-6 col-md-5 col-lg-4 px-0">
                                        <img src={avatarTransportista ? avatarTransportista : persona_img} alt="Persona" className="img-fluid" />
                                    </div>
                                    <span className="text-sm gris-oscuro bold text-left">{nombreTransportista ? nombreTransportista : ""}</span>
                                    <div className="flex-1 d-flex flex-column text-left">
                                        <Rate disabled defaultValue={calificacionTransportista ? calificacionTransportista : 1} className="naranja estrellaGrande" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-9 text-center">
                                <p className="text-justify gris-oscuro">{descripcionTransportista ? descripcionTransportista : ""} </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-10 mt-3">
                        <span className="bold gris-letras text-sm ">COMENTARIOS</span>
                    </div>
                    <div className="comentarios">
                        {comentarios && comentarios.slice(0, verMas ? comentarios.length : 2).map((comentario, index) => {
                            return (
                                <div key={index} className="col-11 mt-3">
                                    <div className="col-12 p-0 d-flex justify-content-center pb-2" style={{ borderBottom: "1px black solid" }} >
                                        <div className="col-10 d-flex flex-column flex-sm-row"  >
                                            <div className="flex-1 d-flex justify-content-center align-items-center">
                                                <img src={comentario && comentario.calificador && comentario.calificador.avatar ? comentario.calificador.avatar : persona_img} alt="Persona" className="img-fluid" />
                                            </div>
                                            <div className="flex-5 d-flex flex-column ml-2">
                                                <span className="bold gris-oscuro">{comentario && comentario.calificador && comentario.calificador.nombre ? comentario.calificador.nombre : ""}</span>
                                                <span className="text-justify gris-oscuro">{comentario && comentario.comentario ? comentario.comentario : ""}</span>
                                                <Rate disabled defaultValue={comentario && comentario.calificacion ? comentario.calificacion : 1} className="naranja" />
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                        })}
                    </div>

                    {comentarios && !verMas && comentarios.length > 2 &&

                        <div className="col-12 mt-3 text-center">
                            <span className="bold naranja image-puntero "
                                onClick={() => setVerMas(true)}
                            >
                                Ver más
                            </span>
                        </div>
                    }

                    <div className="col-12 mt-2">
                        <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                            <div className="col-md-6 col-12">
                                <button className="btn btn-dark btn-block"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClose();
                                    }}
                                >
                                    Salir
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Modal>
    )
}
const mstp = state => {
    return {
        comentarios: state.profile.comentarios
    }
};

const mdtp = {
    listarComentariosTransportista
};

export default connect(mstp, mdtp)(ModalPerfilTransportista)
