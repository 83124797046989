import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardForm from 'Utils/Cards/CardForm';
import Form from './ProyectoForm';
import ProyectoUpdateForm from './ProyectoForm';
import ModalTerminos from '../../../Utils/Modales/ModalTerminos';
import moment from 'moment';
import { EN_RECEPCION_OFERTAS, BORRADOR, VENCIDO } from '../../../../../utility/constants';

export default class ProyectoCrear extends Component {
    state = {
        editar: false
    };
    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id, [EN_RECEPCION_OFERTAS, BORRADOR, VENCIDO], true);
            this.setState({
                editar: true,
            });
        }
        if (this.props.error_rango_precio) {
            this.props.cleanErrorCustom()
        }
    }
    actualizar = (data) => {
        const body = { ...data }

        body.pais_origen_nombre = body.pais_origen.nombre;
        body.ciudad_origen_nombre = body.ciudad_origen.nombre;
        body.pais_destino_nombre = body.pais_destino.nombre;
        body.ciudad_destino_nombre = body.ciudad_destino.nombre;
        body.tipo_vehiculo_nombre = body.tipo_vehiculo.nombre;

        body.pais_origen = body.pais_origen.id;
        body.ciudad_origen = body.ciudad_origen.id;
        body.pais_destino = body.pais_destino.id;
        body.ciudad_destino = body.ciudad_destino.id;
        body.tipo_vehiculo = body.tipo_vehiculo.id;

        body.fecha_hora_posicionamiento = moment(body.fecha_hora_posicionamiento).format();
        body.fecha_cierre_ofertas = moment(body.fecha_cierre_ofertas).format('YYYY-MM-DD');
        body.dia_entrega = moment(body.dia_entrega).format('YYYY-MM-DD');

        this.props.editar(this.props.match.params.id, body)
    };

    crearProyecto = (data) => {
        const body = { ...data }

        body.pais_origen_nombre = body.pais_origen.nombre;
        body.ciudad_origen_nombre = body.ciudad_origen.nombre;
        body.pais_destino_nombre = body.pais_destino.nombre;
        body.ciudad_destino_nombre = body.ciudad_destino.nombre;
        body.tipo_vehiculo_nombre = body.tipo_vehiculo.nombre;

        body.pais_origen = body.pais_origen.id;
        body.ciudad_origen = body.ciudad_origen.id;
        body.pais_destino = body.pais_destino.id;
        body.ciudad_destino = body.ciudad_destino.id;
        body.tipo_vehiculo = body.tipo_vehiculo.id;

        body.fecha_hora_posicionamiento = moment(body.fecha_hora_posicionamiento).format();
        body.fecha_cierre_ofertas = moment(body.fecha_cierre_ofertas).format('YYYY-MM-DD');
        body.dia_entrega = moment(body.dia_entrega).format('YYYY-MM-DD');

        this.props.crear(body)
    };

    closeModal = () => {
        this.props.history.goBack()
    }

    terminosAceptados = () => {
        const { me } = this.props;
        if (!!me && me.terminos_aceptados === true) {
            return false
        } else {
            return true
        }
    };

    aceptarTerminos = () => {
        this.props.aceptarTerminos()
    };

    render() {
        const { crear, update, clearSelectPais } = this.props;
        const { updateData, loader, me, item, error_rango_precio } = this.props;
        const terminosAceptados = this.terminosAceptados();
        return (
            <div className="mb-4 col-12 mt-4">
                <ModalTerminos
                    loader={loader}
                    onClose={this.closeModal}
                    open={terminosAceptados}
                    item={me}
                    aceptarTerminos={this.aceptarTerminos}
                />
                <CardForm titulo={this.state.editar ? "EDITAR PROYECTO" : "NUEVO PROYECTO"}>
                    <LoadMask loading={loader} blur>
                        {
                            this.state.editar ?
                                <ProyectoUpdateForm
                                    onSubmit={this.actualizar}
                                    updateData={updateData}
                                    editando={true}
                                    clearSelectPais={clearSelectPais}
                                    item={item}
                                    error_rango_precio={error_rango_precio}
                                />
                                :
                                <Form onSubmit={this.crearProyecto} clearSelectPais={clearSelectPais} error_rango_precio={error_rango_precio} />
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
