import axios from 'axios';
import { EASYTRUCK_SERVER_API } from './constants';

class WebSocketChatService {
    static instance = null;
    callbacks = {};

    static getInstance() {
        if (!WebSocketChatService.instance) {
            WebSocketChatService.instance = new WebSocketChatService();
        }
        return WebSocketChatService.instance;
    }

    constructor() {
        this.socketRef = null;
        this.chatActive = true;
    }

    _socketNewMessage(data) {
        const parsedData = JSON.parse(data);
        const command = parsedData.command;
        if (Object.keys(this.callbacks).length === 0) {
            return;
        }
        if (command === 'messages') {
            this.callbacks[command](parsedData.messages);
        }
        if (command === 'new_message') {
            this.callbacks[command](parsedData.message);
        }
        if (command === 'chat_closed') {
            this.callbacks[command](parsedData.message);
        }
        if (command === 'chat_inactive') {
            this.callbacks[command](parsedData.message);
        }
    }

    connect(chatId) {
        if (!this.chatActive) this.chatActive = true;
        const path = EASYTRUCK_SERVER_API.WEBSOCKET + '/chats/' + chatId + '/';
        this.socketRef = new WebSocket(path);

        this.socketRef.onopen = () => {
            console.log('WebSocket open');
        };
        this.socketRef.onmessage = (e) => {
            this._socketNewMessage(e.data);
        };

        this.socketRef.onerror = (e) => {
            console.log(e.message);
        };
        this.socketRef.onclose = (e) => {
            console.log('WebSocket closed');
            console.log(e.code, e.reason);
            if (this.chatActive) this.connect(chatId);
        };
    }

    disconnect() {
        this.chatActive = false;
        this.callbacks = {};
        this.socketRef.close();
    }

    waitForSocketConnection(callback) {
        const socket = this.socketRef;
        const recursion = this.waitForSocketConnection;
        setTimeout(function () {
            if (socket.readyState === 1) {
                console.log('Connection is made');
                if (callback != null) {
                    callback();
                }
                return;
            } else {
                console.log('wait for connection...');
                recursion(callback);
            }
        }, 1); // wait 5 milisecond for the connection...
    }

    stateConnection() {
        return this.socketRef.readyState;
    }

    addCallbacks(
        messagesCallback,
        newMessageCallback,
        chatClosedCallback,
        chatInactiveCallback
    ) {
        this.callbacks['messages'] = messagesCallback;
        this.callbacks['new_message'] = newMessageCallback;
        this.callbacks['chat_closed'] = chatClosedCallback;
        this.callbacks['chat_inactive'] = chatInactiveCallback;
    }

    _sendMessage(data) {
        try {
            this.socketRef.send(JSON.stringify({ ...data }));
        } catch (err) {
            console.log(err.message);
        }
    }

    fetchMessages(batch) {
        this._sendMessage({ command: 'fetch_messages', batch: batch });
    }

    newChatMessage(message) {
        this._sendMessage({
            command: 'new_message',
            from: message.from,
            text: message.text,
        });
    }
}

export const WebSocketChatInstance = WebSocketChatService.getInstance();
