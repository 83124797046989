import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card";
import LoadMask from 'Utils/LoadMask/LoadMask';
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Header from "Utils/Grid/Header";
import Select from 'react-select';
import classNames from 'classnames';
import chroma from 'chroma-js';
import { SWALMOD, ESTADO_PROYECTO, USER_CONTRATISTA_EMPRESA } from '../../../../../utility/constants'
import CardProyecto from '../../../Utils/Cards/CardProyecto';
import { Pagination } from 'antd';
const alerta_img = require('assets/img/icons/alert.svg');

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

export default class UsuariosList extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    eliminar = (id) => {
        return () => {
            SWALMOD.fire({
                title: '¿Eliminar?',
                text: '¡No podrás revertir esta acción!',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                imageUrl: alerta_img,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    };

    render() {
        const { listar: onPageChange, onSortChange, eliminar, onChangeEstadoProyecto, listar } = this.props; //Funciones
        const { data, loader, page, estado_proyecto, me } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="PROYECTOS">
                    <Header
                        to="proyecto/crear"
                        textBtn="Crear proyecto"
                        filtro1={
                            <Select
                                isClearable={true}
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={ESTADO_PROYECTO}
                                placeholder={"Filtrar por estado"}
                                value={estado_proyecto}
                                onChange={onChangeEstadoProyecto}
                                styles={colourStyles}
                            />
                        }
                    />
                    <LoadMask loading={loader} blur>
                        <div className="row px-0">
                            {data && data.results && data.results.map((proyecto, index) => {
                                return (
                                    <CardProyecto
                                        saldoAFavor={proyecto.saldo_a_favor ? proyecto.saldo_a_favor : 0}
                                        key={index}
                                        id={proyecto.id ? proyecto.id : ""}
                                        nombre={proyecto.nombre ? proyecto.nombre : ""}
                                        fecha_cierre={proyecto.fecha_cierre_ofertas ? proyecto.fecha_cierre_ofertas : ""}
                                        origen={proyecto.pais_origen && proyecto.ciudad_origen && proyecto.pais_origen.nombre && proyecto.ciudad_origen.nombre ? `${proyecto.ciudad_origen.nombre}, ${proyecto.pais_origen.nombre}` : ""}
                                        destino={proyecto.pais_destino && proyecto.ciudad_destino && proyecto.pais_destino.nombre && proyecto.ciudad_destino.nombre ? `${proyecto.ciudad_destino.nombre}, ${proyecto.pais_destino.nombre}` : ""}
                                        tipo_transporte={proyecto.tipo_vehiculo && proyecto.tipo_vehiculo.nombre ? proyecto.tipo_vehiculo.nombre : ""}
                                        presupuesto={proyecto.presupuesto ? proyecto.presupuesto : 0}
                                        fecha_hora_posicionamiento={proyecto.fecha_hora_posicionamiento ? proyecto.fecha_hora_posicionamiento : ""}
                                        ofertas={proyecto.total_ofertas ? proyecto.total_ofertas : 0}
                                        tipo={proyecto.estado_proyecto ? proyecto.estado_proyecto : ""}
                                        eliminarProyecto={this.eliminar}
                                        tipo_usuario={me && me.tipo_usuario ? me.tipo_usuario : USER_CONTRATISTA_EMPRESA}
                                        codigo_tracking={proyecto.codigo_tracking ? proyecto.codigo_tracking : null}
                                    />
                                )
                            })}
                            {data && data.results && data.results.length === 0 && (
                                <div className="col-12 d-flex justify-content-center align-items-center mb-3" style={{ height: "5rem" }}>
                                    <h2>Sin resultados</h2>
                                </div>
                            )}
                        </div>
                        {data && data.results && data.count > 0 && (
                            <div className="w-100 p-0 text-right mb-3">
                                <Pagination
                                    size="small"
                                    current={page}
                                    total={data && data.count ? data.count : 0}
                                    onChange={listar}
                                />
                            </div>
                        )}
                    </LoadMask>
                </Card>
            </div>
        )
    }
}
