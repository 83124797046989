import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { getMe } from '../cuenta/login';
import _ from "lodash";
import { reset } from 'redux-form';
import { ADJUDICADO_PENDIENTE_DE_INICIAR, BORRADOR, EN_CURSO, EN_EDICION, EN_RECEPCION_OFERTAS, FINALIZADO, USER_TRANSPORTISTA, VENCIDO } from "../../../utility/constants";

const LOADER = 'LOADER_PROYECTOS';
const DATA = 'DATA_PROYECTOS';
const DATA_ADJUDICADOS = 'DATA_PROYECTOS_ADJUDICADOS';
const ITEM_DATA = 'ITEM_PROYECTOS';
const PAGE = 'PAGE_PROYECTOS';
const PAGE_ADJUDICADOS = 'PAGE_PROYECTOS_ADJUDICADOS';
const ORDERING = 'ORDERING_PROYECTOS';
const SEARCH = 'SEARCH_PROYECTOS';
const FILTRO_ESTADO_PROYECTOS = 'FILTRO_ESTADO_PROYECTOS';
const OFERTAS_PROYECTOS = 'OFERTAS_PROYECTOS';
const OFERTA_DETALLE = 'OFERTA_DETALLE';
const VIAJE_DETALLE = 'VIAJE_DETALLE';
// FILTROS PROYECTOS TRANSPORTISTAS
const FILTRO_PAIS_PROYECTOS = 'FILTRO_PAIS_PROYECTOS';
const FILTRO_CIUDAD_PROYECTOS = 'FILTRO_CIUDAD_PROYECTOS';
const FILTRO_VEHICULO_PROYECTOS = 'FILTRO_VEHICULO_PROYECTOS';
const FILTRO_PRECIO_MAX_PROYECTOS = 'FILTRO_PRECIO_MAX_PROYECTOS';
const FILTRO_PRECIO_MIN_PROYECTOS = 'FILTRO_PRECIO_MIN_PROYECTOS';
const PRECIO_MAX_PROYECTOS = 'PRECIO_MAX_PROYECTOS';
const PRECIO_MIN_PROYECTOS = 'PRECIO_MIN_PROYECTOS';
const FILTRO_PILOTO_PROYECTOS = 'FILTRO_PILOTO_PROYECTOS';
const FILTRO_CONTRATISTA_PROYECTOS = 'FILTRO_CONTRATISTA_PROYECTOS';
const FILTRO_TRANSPORTISTA_PROYECTOS = 'FILTRO_TRANSPORTISTA_PROYECTOS';
// FILTROS DE CODIGO DE TRACKING
const FILTRO_TRANSPORTISTA_TRACKING = 'FILTRO_TRANSPORTISTA_TRACKING';
const FILTRO_CONTRATISTA_TRACKING = 'FILTRO_CONTRATISTA_TRACKING';
// ESTADO TAB
const ESTADO_PROYECTO_TAB = 'ESTADO_PROYECTO_TAB';
// ERROR DEL rango de precio
const ERROR_RANGO_PRECIO = 'ERROR_RANGO_PRECIO';
const ERROR_VEHICULO_SELECCIONADO = 'ERROR_VEHICULO_SELECCIONADO';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});
const setDataAdjudicados = data_adjudicados => ({
    type: DATA_ADJUDICADOS,
    data_adjudicados,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});
const setPageAdjudicados = page_adjudicados => ({
    type: PAGE_ADJUDICADOS,
    page_adjudicados,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setEstadoProyectoTab = estado_proyecto_tab => ({
    type: ESTADO_PROYECTO_TAB,
    estado_proyecto_tab,
});


const setErrorRangoPrecio = error_rango_precio => ({
    type: ERROR_RANGO_PRECIO,
    error_rango_precio,
});
const setErrorVehiculoSeleccionado = error_vehiculo_seleccionado => ({
    type: ERROR_VEHICULO_SELECCIONADO,
    error_vehiculo_seleccionado,
});
// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().proyectos;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (resource.estado_proyecto && resource.estado_proyecto.value > 0) {
        params.estado_proyecto = resource.estado_proyecto.value;
    }
    dispatch(setLoader(true));
    dispatch(setData({
        results: [],
        count: 0,
    }));
    api.get('proyecto/proyectos_contratista', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, estado_proyecto = "vacio", cargar_form = false) => (dispatch, getStore) => {
    const resource = getStore().login;
    if (estado_proyecto == "vacio") {
        // # Los transportistas pueden ver el proyecto si esta en alguno de estos estados
        estado_proyecto = [EN_RECEPCION_OFERTAS, ADJUDICADO_PENDIENTE_DE_INICIAR, EN_CURSO, FINALIZADO]
        if (resource.me.tipo_usuario != USER_TRANSPORTISTA) {
            estado_proyecto = [...estado_proyecto,
                BORRADOR,
                VENCIDO
            ]
        }

    }
    dispatch(setLoader(true));
    dispatch(setViajeDetalle(false));
    api.get(`${'proyecto'}/${id}`).then((response) => {
        if (!(estado_proyecto.includes(response.estado_proyecto))) {
            dispatch(push('/'));
        }
        dispatch(setItem(response));
        if (cargar_form)
            dispatch(initializeForm('ProyectoForm', response));

        if (response.adjudicado) {
            dispatch(viajeDetalle(id));
        }
    }).catch(() => {
        dispatch(push('/404'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const leerAsync = id => async (dispatch, getStore) => {
    const resource = getStore().login;
    const { me } = resource
    dispatch(setLoader(true));
    try {
        const response = await api.get(`proyecto/${id}`)
        if (response && response.creador && response.oferta_adjudicado &&
            (response.creador.id == me.id || me.id == response.oferta_adjudicado.transportista.id)) {
            dispatch(setItem(response));
        } else {
            dispatch(push('/'));
        }
    } catch (err) {
    } finally {
        dispatch(setLoader(false));
    }
};


const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setErrorRangoPrecio(""));
    api.post('proyecto', data).then(() => {
        NotificationManager.success('Proyecto creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/proyectos'));
    }).catch((error) => {
        let mensaje = 'Error en la creacion, vuelva a intentar';
        if (error) {
            if (error.detail) {
                mensaje = error.detail;
                if (mensaje.substring(0, 14) == "personalizado ") {
                    mensaje = mensaje.substring(14)
                    dispatch(setErrorRangoPrecio(mensaje));
                } else {
                    NotificationManager.error(mensaje, 'ERROR', 5000);
                }
            }
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const solicitarNuevoTrayecto = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('proyecto/solicitar_nuevo_trayecto', data).then(() => {
        NotificationManager.success('Solicitud enviada correctamente', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error en la solicitud, vuelva a intentar';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(push('/proyectos'));
    });
}

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setErrorRangoPrecio(""));
    api.put(`${'proyecto'}/${id}`, data).then(() => {
        NotificationManager.success('Proyecto actualizado', 'Éxito', 3000);
        dispatch(push('/proyectos'));
    }).catch((error) => {
        let mensaje = 'Error en la edición, vuelva a intentar';
        if (error) {
            if (error.detail) {
                mensaje = error.detail;
                if (mensaje.substring(0, 14) == "personalizado ") {
                    mensaje = mensaje.substring(14)
                    dispatch(setErrorRangoPrecio(mensaje));
                } else {
                    NotificationManager.error(mensaje, 'ERROR', 5000);
                }
            }
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'proyecto'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
        dispatch(push('/proyectos'));
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('ProyectoForm', {}));
};

const cleanErrorCustom = () => (dispatch) => {
    dispatch(setErrorRangoPrecio(""));
    dispatch(setErrorVehiculoSeleccionado(""));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onChangeEstadoProyecto = estado_proyecto => (dispatch) => {
    dispatch({ type: FILTRO_ESTADO_PROYECTOS, estado_proyecto })
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().proyectos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const clearSelectPais = (opcion) => (dispatch) => {
    dispatch(change('ProyectoForm', opcion, null));
}

const aceptarTerminos = () => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${'user'}/aceptar_terminos`).then(() => {
        dispatch(getMe());
        NotificationManager.success('Terminos aceptados', 'Éxito', 3000);
        window.location.reload();
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

// Proyectos de Transportistas
const listarProyectosPublicadados = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().proyectos;
    const params = { page };
    params.filtro_precio_min = resource.filtro_precio_min;
    params.filtro_precio_max = resource.filtro_precio_max;
    if (resource.filtro_pais) {
        params.filtro_pais = resource.filtro_pais.id;
    }
    if (resource.filtro_ciudad) {
        params.filtro_ciudad = resource.filtro_ciudad.id;
    }
    if (resource.filtro_vehiculo) {
        params.filtro_vehiculo = resource.filtro_vehiculo.id;
    }
    dispatch(setLoader(true));
    dispatch(setData({
        results: [],
        count: 0,
    }));
    api.get('proyecto/proyectos_publicados', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const infoProyectosPublicadados = () => async (dispatch, getStore) => {
    const resource = getStore().proyectos;
    const params = {};
    if (resource.filtro_pais) {
        params.filtro_pais = resource.filtro_pais.id;
    }
    if (resource.filtro_ciudad) {
        params.filtro_ciudad = resource.filtro_ciudad.id;
    }
    if (resource.filtro_vehiculo) {
        params.filtro_vehiculo = resource.filtro_vehiculo.id;
    }
    dispatch(setLoader(true));
    try {
        const response = await api.get('proyecto/info_proyectos_publicados', params)
        dispatch(PrecioMinTransportista(response.precio_minimo));
        dispatch(PrecioMaxTransportista(response.precio_maximo));
        dispatch(filtroPrecioMinTransportista(response.precio_minimo));
        dispatch(filtroPrecioMaxTransportista(response.precio_maximo));
    } catch (err) {
        // NotificationManager.error('Error en la edición', 'ERROR', 3000);
    } finally {
        dispatch(setLoader(false));
    }
};

const filtroPaisTransportista = filtro_pais => (dispatch) => {
    dispatch({ type: FILTRO_PAIS_PROYECTOS, filtro_pais })
    // dispatch(listarProyectosPublicadados());
};

const filtroCiudadTransportista = filtro_ciudad => (dispatch) => {
    dispatch({ type: FILTRO_CIUDAD_PROYECTOS, filtro_ciudad })
    dispatch(listarProyectosPublicadados());
};

const filtroVehiculoTransportista = filtro_vehiculo => (dispatch) => {
    dispatch({ type: FILTRO_VEHICULO_PROYECTOS, filtro_vehiculo })
    dispatch(listarProyectosPublicadados());
};

const filtroPrecioMaxTransportista = filtro_precio_max => (dispatch) => {
    dispatch({ type: FILTRO_PRECIO_MAX_PROYECTOS, filtro_precio_max })
};

const filtroPrecioMinTransportista = filtro_precio_min => (dispatch) => {
    dispatch({ type: FILTRO_PRECIO_MIN_PROYECTOS, filtro_precio_min })
};

const PrecioMaxTransportista = precio_max => (dispatch) => {
    dispatch({ type: PRECIO_MAX_PROYECTOS, precio_max })
};

const PrecioMinTransportista = precio_min => (dispatch) => {
    dispatch({ type: PRECIO_MIN_PROYECTOS, precio_min })
};

// Ofertas
const crearOferta = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('oferta', data).then(() => {
        NotificationManager.success('Oferta creada', 'Éxito', 3000);
        dispatch(cleanOfertaForm()); // Limpiar el formulario
        dispatch(leer(data.proyecto));
        dispatch(ofertasProyecto(data.proyecto));
    }).catch((error) => {
        let mensaje = 'Error al crear la oferta, vuelva a intentar';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cleanOfertaForm = () => (dispatch) => {
    dispatch(initializeForm('OfertaForm', {}));
};

const ofertasProyecto = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'proyecto'}/${id}/ofertas_x_transportista`).then((response) => {
        dispatch(setOfertasProyectos(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const ofertaDetalle = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'oferta'}/${id}`).then((response) => {
        dispatch(setOfertaDetalle(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const viajeDetalle = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'proyecto'}/${id}/detalle_viaje_proyecto`).then((response) => {
        dispatch(setViajeDetalle(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const reorganizarViaje = (id, justificacion = null) => (dispatch, getStore) => {
    const resource = getStore().login;
    const data = {}
    if (justificacion) {
        data.justificacion = justificacion;
    }
    dispatch(setLoader(true));
    api.put(`${'viaje'}/${id}/cancelar_viaje_y_reorganizar`, data).then(() => {
        NotificationManager.success('Viaje cancelado correctamente, Revise su proyecto en BORRADORES', 'Éxito', 3000);
    }).catch(() => {
        let mensaje = 'Error al cancelar el viaje';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 5000);
    }).finally(() => {
        dispatch(setLoader(false));
        if (resource.me.tipo_usuario == USER_TRANSPORTISTA) {
            dispatch(push('/proyectos-transportistas'));
        } else {
            dispatch(push('/proyectos'));
        }

    });
}


// Cancelar proyecto con saldo a favor.
const cancelarProyectoConSaldoAFavor = (id, justificacion = null) => (dispatch) => {
    const data = {}
    if (justificacion) {
        data.justificacion = justificacion;
    }
    dispatch(setLoader(true));
    api.put(`${'proyecto'}/${id}/cancelar_proyecto_con_saldo`, data).then(() => {
        NotificationManager.success('Proyecto cancelado correctamente, pronto se realizara la devolución monetario.', 'Éxito', 3000);
    }).catch(() => {
        let mensaje = 'Error al cancelar el Proyecto';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 5000);
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(push('/proyectos'));
    });
}

// Cancelar viaje Definitivamente, cancela un proyecto que tiene viaje, oferta adjudicado.
const cancelarViajeDefinitivamente = (id, justificacion = null) => (dispatch) => {
    const data = {}
    if (justificacion) {
        data.justificacion = justificacion;
    }
    dispatch(setLoader(true));
    api.put(`${'viaje'}/${id}/cancelar_viaje_definitivamente`, data).then(() => {
        NotificationManager.success('Viaje cancelado correctamente, pronto se realizara la devolución monetario.', 'Éxito', 3000);
    }).catch(() => {
        let mensaje = 'Error al cancelar el viaje';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 5000);
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(push('/proyectos'));
    });
}
const setOfertasProyectos = ofertas_proyectos => (dispatch) => {
    dispatch({ type: OFERTAS_PROYECTOS, ofertas_proyectos })
};

const setOfertaDetalle = oferta_detalle => (dispatch) => {
    dispatch({ type: OFERTA_DETALLE, oferta_detalle })
};

const setViajeDetalle = viaje_detalle => (dispatch) => {
    dispatch({ type: VIAJE_DETALLE, viaje_detalle })
};


const proyectosAdjudicadosTransportista = (estado_proyecto = 1, page = 1) => (dispatch, getStore) => {
    const resource = getStore().proyectos;
    const params = { page, estado_proyecto };
    dispatch(setLoader(true));
    api.get('proyecto/adjudicacion_x_transportista', params).then((response) => {
        dispatch(setDataAdjudicados(response));
        dispatch(setPageAdjudicados(page));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const estadoProyectoTab = estado => (dispatch) => {
    dispatch(setEstadoProyectoTab(estado));
    dispatch(proyectosAdjudicadosTransportista(estado));
};

const actualizarPiloto = (ids, data = {}, attachments = []) => (dispatch) => {
    data.tipo_vehiculo = data.transporte.vehiculo.id
    data.piloto = data.piloto.id
    data.transporte = data.transporte.id
    dispatch(setLoader(true));
    dispatch(setErrorVehiculoSeleccionado(""));
    api.putAttachments(`${'viaje'}/${ids.idViaje}`, data, attachments).then(() => {
        NotificationManager.success('Se envio los datos correctamente', 'Éxito', 3000);
        dispatch(leer(ids.idProyecto));
        dispatch(push(`/mi-proyecto-transportista/${ids.idProyecto}`));
    }).catch((error) => {
        let mensaje = 'Error al enviar los datos del piloto';
        if (error) {
            if (error.detail) {
                mensaje = error.detail;
                if (mensaje.substring(0, 14) == "personalizado ") {
                    mensaje = mensaje.substring(14)
                    dispatch(setErrorVehiculoSeleccionado(mensaje));
                } else {
                    NotificationManager.error(mensaje, 'ERROR', 5000);
                }
            }
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}



const datosPiloto = (piloto) => (dispatch, getStore) => {
    const value = getStore().form.ConfirmarPilotoForm;
    if (value && value.values && value.values.piloto) {
        dispatch(change("ConfirmarPilotoForm", "telefono_piloto", value.values.piloto.telefono ? value.values.piloto.telefono : ""));
        dispatch(change("ConfirmarPilotoForm", "no_licencia", value.values.piloto.no_licencia ? value.values.piloto.no_licencia : ""));
    }
}

// LISTAOO DE PROYECTOS EN CURSO TRANSPORTISTA
const listarProyectosEnCursoTransportista = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().proyectos;
    const params = { page };
    if (resource.filtro_contratista) {
        params.contratista = resource.filtro_contratista.id;
    }
    if (resource.filtro_piloto) {
        params.piloto = resource.filtro_piloto.id;
    }
    if (resource.filtro_transportista_tracking) {
        params.codigo_tracking = resource.filtro_transportista_tracking;
    }
    dispatch(setLoader(true));
    api.get('proyecto/proyectos_en_curso_transportista', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const filtroPilotoTransportista = filtro_piloto => (dispatch) => {
    dispatch({ type: FILTRO_PILOTO_PROYECTOS, filtro_piloto })
    dispatch(listarProyectosEnCursoTransportista());
};

const filtroContratistaTransportista = filtro_contratista => (dispatch) => {
    dispatch({ type: FILTRO_CONTRATISTA_PROYECTOS, filtro_contratista })
    dispatch(listarProyectosEnCursoTransportista());
};

const filtroTransportistaTracking = filtro_transportista_tracking => (dispatch) => {
    dispatch({ type: FILTRO_TRANSPORTISTA_TRACKING, filtro_transportista_tracking })
    dispatch(listarProyectosEnCursoTransportista());
};

// LISTAOO DE PROYECTOS EN CURSO CONTRATISTA
const listarProyectosEnCursoContratista = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().proyectos;
    const params = { page };
    if (resource.filtro_transportista) {
        params.transportista = resource.filtro_transportista.id;
    }
    if (resource.filtro_contratista_tracking) {
        params.codigo_tracking = resource.filtro_contratista_tracking;
    }
    dispatch(setLoader(true));
    api.get('proyecto/proyectos_en_curso_contratista', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const filtroTransportistaContratista = filtro_transportista => (dispatch) => {
    dispatch({ type: FILTRO_TRANSPORTISTA_PROYECTOS, filtro_transportista })
    dispatch(listarProyectosEnCursoContratista());
};

const filtroContratistaTracking = filtro_contratista_tracking => (dispatch) => {
    dispatch({ type: FILTRO_CONTRATISTA_TRACKING, filtro_contratista_tracking })
    dispatch(listarProyectosEnCursoContratista());
};

export const actions = {
    listar,
    leer,
    leerAsync,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    onChangeEstadoProyecto,
    cleanForm,
    clearSelectPais,
    aceptarTerminos,
    // Proyectos de Transportistas
    infoProyectosPublicadados,
    listarProyectosPublicadados,
    filtroPaisTransportista,
    filtroCiudadTransportista,
    filtroVehiculoTransportista,
    filtroPrecioMaxTransportista,
    filtroPrecioMinTransportista,
    PrecioMaxTransportista,
    PrecioMinTransportista,

    proyectosAdjudicadosTransportista,
    estadoProyectoTab,
    // Oferta
    crearOferta,
    ofertasProyecto,
    ofertaDetalle,
    // VIAJE
    viajeDetalle,
    actualizarPiloto,
    datosPiloto,
    // VIAJE DESADJUDICAR Y CANCELAR
    cancelarViajeDefinitivamente,
    reorganizarViaje,
    // CANCELAR PROYECTO CON SALDO A FAVOR
    cancelarProyectoConSaldoAFavor,
    // Viajes en curso - transportista
    listarProyectosEnCursoTransportista,
    filtroPilotoTransportista,
    filtroContratistaTransportista,
    // Viajes en curso - contratista
    listarProyectosEnCursoContratista,
    filtroTransportistaContratista,
    // Filtros de tracking
    filtroContratistaTracking,
    filtroTransportistaTracking,

    cleanErrorCustom,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_ADJUDICADOS]: (state, { data_adjudicados }) => {
        return {
            ...state,
            data_adjudicados,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE_ADJUDICADOS]: (state, { page_adjudicados }) => {
        return {
            ...state,
            page_adjudicados,
        };
    },
    [ESTADO_PROYECTO_TAB]: (state, { estado_proyecto_tab }) => {
        return {
            ...state,
            estado_proyecto_tab,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [FILTRO_ESTADO_PROYECTOS]: (state, { estado_proyecto }) => {
        return {
            ...state,
            estado_proyecto,
        };
    },
    // Proyectos transportistas
    [FILTRO_PAIS_PROYECTOS]: (state, { filtro_pais }) => {
        return {
            ...state,
            filtro_pais,
        };
    },
    [FILTRO_CIUDAD_PROYECTOS]: (state, { filtro_ciudad }) => {
        return {
            ...state,
            filtro_ciudad,
        };
    },
    [FILTRO_VEHICULO_PROYECTOS]: (state, { filtro_vehiculo }) => {
        return {
            ...state,
            filtro_vehiculo,
        };
    },
    [FILTRO_PRECIO_MAX_PROYECTOS]: (state, { filtro_precio_max }) => {
        return {
            ...state,
            filtro_precio_max,
        };
    },
    [FILTRO_PRECIO_MIN_PROYECTOS]: (state, { filtro_precio_min }) => {
        return {
            ...state,
            filtro_precio_min,
        };
    },
    [PRECIO_MAX_PROYECTOS]: (state, { precio_max }) => {
        return {
            ...state,
            precio_max,
        };
    },
    [PRECIO_MIN_PROYECTOS]: (state, { precio_min }) => {
        return {
            ...state,
            precio_min,
        };
    },
    [OFERTAS_PROYECTOS]: (state, { ofertas_proyectos }) => {
        return {
            ...state,
            ofertas_proyectos,
        };
    },
    [OFERTA_DETALLE]: (state, { oferta_detalle }) => {
        return {
            ...state,
            oferta_detalle,
        };
    },
    [VIAJE_DETALLE]: (state, { viaje_detalle }) => {
        return {
            ...state,
            viaje_detalle,
        };
    },
    [FILTRO_PILOTO_PROYECTOS]: (state, { filtro_piloto }) => {
        return {
            ...state,
            filtro_piloto,
        };
    },
    [FILTRO_CONTRATISTA_PROYECTOS]: (state, { filtro_contratista }) => {
        return {
            ...state,
            filtro_contratista,
        };
    },
    [FILTRO_TRANSPORTISTA_PROYECTOS]: (state, { filtro_transportista }) => {
        return {
            ...state,
            filtro_transportista,
        };
    },
    // Filtro de tracking
    [FILTRO_TRANSPORTISTA_TRACKING]: (state, { filtro_transportista_tracking }) => {
        return {
            ...state,
            filtro_transportista_tracking,
        };
    },
    [FILTRO_CONTRATISTA_TRACKING]: (state, { filtro_contratista_tracking }) => {
        return {
            ...state,
            filtro_contratista_tracking,
        };
    },
    [ERROR_RANGO_PRECIO]: (state, { error_rango_precio }) => {
        return {
            ...state,
            error_rango_precio,
        };
    },
    [ERROR_VEHICULO_SELECCIONADO]: (state, { error_vehiculo_seleccionado }) => {
        return {
            ...state,
            error_vehiculo_seleccionado,
        };
    },
};



export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_adjudicados: {
        results: [],
        count: 0,
    },
    item: {},
    page_adjudicados: 1,
    page: 1,
    ordering: '',
    search: '',
    estado_proyecto: -1,
    // Proyectos transportistas
    filtro_pais: null,
    filtro_ciudad: null,
    filtro_vehiculo: null,
    filtro_precio_min: 0,
    filtro_precio_max: 100,
    precio_min: 0,
    precio_max: 100000,
    filtro_piloto: null,
    filtro_contratista: null,
    filtro_transportista: null,
    // Ofertas
    ofertas_proyectos: [],
    oferta_detalle: {},
    viaje_detalle: {},
    estado_proyecto_tab: ADJUDICADO_PENDIENTE_DE_INICIAR,
    // Filtro tracking
    filtro_contratista_tracking: null,
    filtro_transportista_tracking: null,
    error_rango_precio: "",
    error_vehiculo_seleccionado: "",
};

export default handleActions(reducers, initialState);
