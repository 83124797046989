import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { validators, validate, combine } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { Rate } from 'antd';
import { renderNumber, renderField, renderFilePicker, renderSwitch, renderTextArea, renderCurrency, AsyncSelectField2 } from '../../../Utils/renderField/renderField';
import { connect } from 'react-redux'
import { validationPiloto } from '../../../../../utility/validationForm'
import { api } from "api";
const alerta_img = require('assets/img/icons/alert.svg');

const getPiloto = (search) => {
    return api.get("user/pilotos_por_transportista", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getVehiculo = (search) => {
    return api.get("tipo_vehiculo/vehiculo_por_transportista", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

class ConfirmarPilotoForm extends Component {
    state = {
        DatosPiloto: {},
        imagenLicencia: null
    }

    componentDidUpdate(prevProps) {
        if (this.props.piloto !== prevProps.piloto) {
            this.setState({ imagenLicencia: this.props.piloto.licencia_img });
        }

    }


    render() {

        const { handleSubmit, setLicenciaImg, setLCartaPorteManifiestoImg, datosPiloto, piloto, error_vehiculo_seleccionado } = this.props;
        return (
            <div className={`card-proyecto borde-naranja px-3 pt-3 mb-3`}>
                <form onSubmit={handleSubmit}>
                    <div className="form-group grid-container col-12 px-0 px-sm-4">
                        <div className="row col-md-12 p-0">

                            <div className="col-md-12 col-sm-12 ">
                                <h5 className="mb-3 bold gris-oscuro">Datos del piloto</h5>
                            </div>

                            <div className="col-lg-4 p-0">

                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="piloto" className="m-0">Piloto*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="piloto"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField2}
                                        loadOptions={getPiloto}
                                        onCambio={() => datosPiloto(piloto)}
                                    />
                                </div>

                            </div>

                            <div className="col-lg-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="telefono_piloto" className="m-0">Teléfono del piloto*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="telefono_piloto" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationPiloto.telefono.MaxLength} />
                                </div>


                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="licencia_img" className="m-0">Imagen de la licencia</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field photo={this.state.imagenLicencia} setFile={setLicenciaImg} name="licencia_img" component={renderFilePicker} />
                                </div>
                            </div>

                            <div className="col-lg-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="no_licencia" className="m-0">No. de Licencia*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="no_licencia" component={renderField} type="text" className="form-control" maxLength={validationPiloto.no_licencia.MaxLength} />
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="carta_porte_manifiesto" className="m-0">Carta de porte y manifiesto</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field photo={null} setFile={setLCartaPorteManifiestoImg} name="carta_porte_manifiesto" component={renderFilePicker} />
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12 mt-3 ">
                                <h5 className="mb-3 bold gris-oscuro">Datos del vehículo</h5>
                            </div>

                            <div className="col-lg-4 p-0 align-self-end">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="transporte" className="m-0">Vehículo*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="transporte"
                                        labelKey="vehiculo_nombre"
                                        label="vehiculo_nombre"
                                        component={AsyncSelectField2}
                                        loadOptions={getVehiculo}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-4 p-0 align-self-end">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="placa" className="m-0">Placa*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="placa"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        maxLength="26"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="placa_tc" className="m-0">TC (placa del furgón, o chasis)</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="placa_tc"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        maxLength="26"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row col-12">
                            <span className="azul bold text-xsm ml-auto">* Campos requeridos</span>
                        </div>
                        {error_vehiculo_seleccionado &&
                            <div className="row col-md-12 p-3">
                                <div className="d-flex flex-column flex-sm-row align-items-center">
                                    <img src={alerta_img} alt="Alerta" width={25} height={20} className="mr-2  blink-danger" />
                                    <span className="bold rojo">{error_vehiculo_seleccionado}</span>
                                </div>
                            </div>
                        }
                        <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                            <div className="col-md-4 col-12 px-1 px-md-2">
                                <Link className="btn btn-dark btn-block" to="/mis-proyectos-transportistas">Cancelar</Link>
                            </div>
                            <div className="col-md-4 col-12 px-2 mt-md-0 mt-3">
                                <button type="submit" className="btn btn-primary btn-block">Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
};

ConfirmarPilotoForm = reduxForm({
    form: 'ConfirmarPilotoForm',
    validate: data => {
        let errors = {}

        if (data && data.placa_tc && data.placa_tc.length > 25) {
            errors.placa_tc = "Máximo 25 carácteres"
        }
        return {
            ...errors, ...validate(data, {
                'telefono_piloto': validationPiloto.telefono.Combine,
                'no_licencia': validationPiloto.no_licencia.Combine,
                'placa': combine(
                    validators.exists()('Campo requerido.'),
                    validators.regex(/^[a-zA-Z0-9\s]+$/)('Sólo se permiten letras y números'),
                    validators.length({ max: 25 })('Máximo 25 carácteres')
                ),
                'piloto': validators.exists()('Campo requerido.'),
                'transporte': validators.exists()('Campo requerido.'),
            })
        }
    }
})(ConfirmarPilotoForm);

ConfirmarPilotoForm = connect(state => {
    const form = state.form.ConfirmarPilotoForm;
    let piloto = "";

    if (form && form.values) {
        const values = form.values;
        if (values.piloto) {
            piloto = values.piloto
        }

    }
    return {
        piloto
    }
})(ConfirmarPilotoForm);

export default ConfirmarPilotoForm
