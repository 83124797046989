import React, { Component, Fragment } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import { MENSUAL, SEMESTRAL, ANUAL } from '../../../../../utility/constants';

import Card from "../../../Utils/Cards/Card";
import { Link } from 'react-router-dom';

const img_mensual = require('assets/img/suscripcion/mensual.png');
const img_semestral = require('assets/img/suscripcion/semestral.png');
const img_anual = require('assets/img/suscripcion/anual.png');
import CardSuscripcion from '../../../Utils/Cards/CardSuscripcion';


export default class Suscripcion extends Component {
    componentWillMount() {
        this.props.leer()
    }
    render() {
        const { loader, item, titulo, me } = this.props;

        return (

            <div className="mb-4 col-12 mt-4">
                <Card titulo="PAUTA PUBLICITARIA">
                    {me && me.suscripcion && me.suscripcion_activa &&
                        <Fragment>
                            <h5 className="mb-1 bold mb-3">Pauta publicitaria activa</h5>
                            <div className="row px-0">
                                <div className="col-12 col-lg-auto mb-3 px-0 px-lg-3 ">
                                    <div className={`card-proyecto ${me.suscripcion_activa.tipo_suscripcion === MENSUAL ? "borde-verde" : me.suscripcion_activa.tipo_suscripcion === SEMESTRAL ? "borde-naranja" : me.suscripcion_activa.tipo_suscripcion === ANUAL ? "borde-azul" : "borde-rojo"}`}>
                                        <div className="px-3 py-4 d-flex flex-column flex-sm-row align-items-center justify-content-center">

                                            <img src={me.suscripcion_activa.tipo_suscripcion === MENSUAL ? img_mensual : me.suscripcion_activa.tipo_suscripcion === SEMESTRAL ? img_semestral : me.suscripcion_activa.tipo_suscripcion === ANUAL ? img_anual : null} alt="Ver" style={{ height: 80 }} />

                                            <div className="d-flex flex-column ml-2 text-center text-lg-left">
                                                <span className={`mb-1 bold text-md ${me.suscripcion_activa.tipo_suscripcion === MENSUAL ? "verde" : me.suscripcion_activa.tipo_suscripcion === SEMESTRAL ? "naranja" : me.suscripcion_activa.tipo_suscripcion === ANUAL ? "azul" : "gris-oscuro"}`}>
                                                    {me.suscripcion_activa.tipo_suscripcion === MENSUAL ? "MENSUAL" : me.suscripcion_activa.tipo_suscripcion === SEMESTRAL ? "SEMESTRAL" : me.suscripcion_activa.tipo_suscripcion === ANUAL ? "ANUAL" : ""}
                                                </span>
                                                <span className={`mb-1 bold gris-oscuro `} style={{ marginTop: -17 }}>
                                                    La pauta vence en
                                                    {me.suscripcion_activa.dias_para_vencer == 1 ?
                                                        <Fragment>
                                                            <span className={`bold text-md ${me.suscripcion_activa.tipo_suscripcion === MENSUAL ? "verde" : me.suscripcion_activa.tipo_suscripcion === SEMESTRAL ? "naranja" : me.suscripcion_activa.tipo_suscripcion === ANUAL ? "azul" : "gris-oscuro"}`}> 1 </span>
                                                            <span>Día</span>
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <span className={`bold text-md ${me.suscripcion_activa.tipo_suscripcion === MENSUAL ? "verde" : me.suscripcion_activa.tipo_suscripcion === SEMESTRAL ? "naranja" : me.suscripcion_activa.tipo_suscripcion === ANUAL ? "azul" : "gris-oscuro"}`}> {me.suscripcion_activa.dias_para_vencer} </span>
                                                            <span>Días</span>
                                                        </Fragment>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    }
                    <h5 className="mb-1 bold mb-3">{titulo ? titulo : ""}</h5>
                    <LoadMask loading={loader} blur>
                        <div className="row px-0">
                            <CardSuscripcion
                                titulo="MENSUAL"
                                imagen={img_mensual}
                                precio={item && item["precio_mensual_contratista"] ? item["precio_mensual_contratista"] : "0"}
                                redireccion="/pago-suscripcion/mensual"
                                veneficios={[
                                    "Agregar 5 anuncios al mes."
                                ]}
                            />
                            <CardSuscripcion
                                titulo="SEMESTRAL"
                                imagen={img_semestral}
                                precio={item && item["precio_semestral_contratista"] ? item["precio_semestral_contratista"] : "0"}
                                redireccion="/pago-suscripcion/semestral"
                                veneficios={[
                                    "Cada 2 meses tiene la posibilidad de agregar 5 anuncios."
                                ]}
                            />
                            <CardSuscripcion
                                titulo="ANUAL"
                                imagen={img_anual}
                                precio={item && item["precio_anual_contratista"] ? item["precio_anual_contratista"] : "0"}
                                redireccion="/pago-suscripcion/anual"
                                veneficios={[
                                    "Cada 2 meses tiene la posibilidad de agregar 5 anuncios."
                                ]}
                            />
                        </div>
                    </LoadMask>
                </Card>
            </div>
        )
    }
}
