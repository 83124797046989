import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/proyectos';
import DetalleAdjudicado from './DetalleProyectoAdjudicado';

const mstp = state => {
    return {
        me: state.login.me,
        ...state.proyectos,
    };
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DetalleAdjudicado)
