import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import {setMe} from "./login";
import {initialize as initializeForm} from 'redux-form';

const LOADER = 'LOGIN_LOADER';
const SET_COMENTARIOS = 'SET_COMENTARIOS';

export const constants = {
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});
export const setComentarios = comentarios => ({
    type: SET_COMENTARIOS,
    comentarios,
});
// ------------------------------------
// Actions
// ------------------------------------
export const listarComentariosTransportista = id => (dispatch) => {
    dispatch(setComentarios([]))
    dispatch(setLoader(true));
    const params = { calificado: id}

    api.get("calificacion", params).then((response) => {
        dispatch(setComentarios(response))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};



export const update = (data = {}, attachments=[]) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.putAttachments('user/update_me', data, attachments).then((response) => {
        dispatch(setMe(response));
        dispatch(push('/'));
        NotificationManager.success('Datos actualizados exitosamente', 'Éxito', 1000);
    }).catch((error) => {
        let mensaje = 'Datos incorrectos, vuelva a intentar';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const reInitForm = () => (dispatch, getStore) => {
    const me = getStore().login.me;
    dispatch(initializeForm('profile', me));
};

export const actions = {
    update,
    reInitForm,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_COMENTARIOS]: (state, { comentarios }) => {
        return {
            ...state,
            comentarios,
        };
    },
};

export const initialState = {
    loader: false,
    comentarios: []
};

export default handleActions(reducers, initialState);
