import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RenderCurrency, RenderDollar } from '../renderField/renderReadField';
import { ADJUDICADO_PENDIENTE_DE_INICIAR, BORRADOR, EN_CURSO, EN_RECEPCION_OFERTAS, FINALIZADO, USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, USER_TRANSPORTISTA } from '../../../../utility/constants';
import { COLOR_BORDE_LEFT, COLOR_BOTON, COLOR_ESTADO, TITULO_ESTADO } from '../../../../utility/utils';
import moment from 'moment';
const editar_img = require('assets/img/icons/editar.svg');
const eliminar_img = require('assets/img/icons/borrar.svg');
const editar_hover_img = require('assets/img/icons/editar_hover.svg');
const eliminar_hover_img = require('assets/img/icons/borrar_hover.svg');

function CardProyecto(props) {
    const [editar, setEditar] = useState(true);
    const [eliminar, setEliminar] = useState(true);
    const { nombre, fecha_cierre,fecha_hora_posicionamiento , origen, destino, tipo_transporte, presupuesto, ofertas, tipo = 0, eliminarProyecto, saldoAFavor = 0, id, tipo_usuario, listado_transportista, listado_adjudicado_transportista, listado_proyectos_en_curso, fecha_hora_inicio_viaje, listado_proyectos_en_curso_contratista, codigo_tracking } = props;
    return (
        <div className="col-12 col-md-12 col-lg-6 mb-3 px-0 px-lg-3">
            <div className={`card-proyecto`} style={COLOR_BORDE_LEFT(tipo)}>
                <div className="p-0 px-3 pt-3">
                    <div className="d-flex flex-column">
                        <span className="mb-1 bold gris-oscuro text-sm text-lg-truncate">{nombre}</span>
                        {!!codigo_tracking && codigo_tracking !== null && (
                            <span className="mb-1 gris-letras bold text-lg-truncate">Código de trackeo <span className="gris-oscuro bold">{codigo_tracking}</span></span>
                        )}
                        <span className="mb-1 gris-letras bold text-lg-truncate">Fecha cierre de ofertas / {moment(fecha_cierre).format('DD-MM-YYYY')}</span>
                        <span className="mb-1 gris-letras bold text-lg-truncate">Origen <span className="gris-oscuro bold">{origen}</span></span>
                        <span className="mb-1 gris-letras bold text-lg-truncate">Destino <span className="gris-oscuro bold">{destino}</span></span>
                        <span className="mb-1 gris-letras bold text-lg-truncate">Tipo de transporte <span className="gris-oscuro bold">{tipo_transporte}</span></span>
                        {!!presupuesto && (
                            <span className="mb-1 gris-letras bold text-lg-truncate">Presupuesto <RenderDollar value={presupuesto} className="gris-oscuro bold" /></span>
                        )}
                        {!!fecha_hora_posicionamiento && (
                            <span className="mb-1 gris-letras bold text-lg-truncate">Fecha y hora de posicionamiento <span className="gris-oscuro bold">{moment(fecha_hora_posicionamiento).format('DD-MM-YYYY hh:mm A')}</span></span>
                        )}
                        {!!fecha_hora_inicio_viaje && (
                            <span className="mb-1 gris-letras bold text-lg-truncate">Fecha y hora de salida <span className="gris-oscuro bold">{moment(fecha_hora_inicio_viaje).format('DD-MM-YYYY hh:mm A')}</span></span>
                        )}
                        {tipo === EN_RECEPCION_OFERTAS &&
                            <span className="mb-1 gris-letras bold text-lg-truncate">Ofertas <span className="naranja bold">{ofertas}</span></span>
                        }
                        <div>
                            {tipo_usuario && (tipo_usuario === USER_CONTRATISTA_EMPRESA || tipo_usuario === USER_CONTRATISTA_INDIVIDUAL) ?
                                <span className={`mb-1 bold`} style={{ color: COLOR_ESTADO(tipo) }}>
                                    {TITULO_ESTADO(tipo)}
                                </span>
                                :
                                <span>&nbsp;</span>
                            }
                        </div>

                    </div>
                </div>
                <div className="p-0 px-3 pt-3">
                    <div className="d-flex flex-wrap justify-content-end align-items-center">
                        {tipo_usuario && (tipo_usuario === USER_CONTRATISTA_EMPRESA || tipo_usuario === USER_CONTRATISTA_INDIVIDUAL) && tipo === EN_RECEPCION_OFERTAS && !(saldoAFavor > 0) && (

                            <a
                                className="mr-2"
                                style={{ cursor: 'pointer', color: '#c4183c' }}
                                onClick={eliminarProyecto(id)}
                            >
                                <img onMouseOver={() => setEliminar(!eliminar)} onMouseOut={() => setEliminar(!eliminar)} src={eliminar ? eliminar_img : eliminar_hover_img} alt="Ver" className="action_img" />
                            </a>
                        )}
                        {tipo_usuario && (tipo_usuario === USER_CONTRATISTA_EMPRESA || tipo_usuario === USER_CONTRATISTA_INDIVIDUAL) && (tipo === EN_RECEPCION_OFERTAS || tipo === BORRADOR) && (
                            <Link to={`proyecto/${id}/editar`} className="mr-2">
                                <img onMouseOver={() => setEditar(!editar)} onMouseOut={() => setEditar(!editar)} src={editar ? editar_img : editar_hover_img} alt="Ver" className="action_img" />
                            </Link>
                        )}
                        <div className="mb-2">
                            {listado_adjudicado_transportista ?
                                <Link
                                    to={`mi-proyecto-transportista/${id}`}
                                    className="btn py-1"
                                    style={COLOR_BOTON(tipo)}
                                >
                                    Ver detalle
                                </Link>
                                :
                                listado_proyectos_en_curso ?
                                    <Link
                                        to={`proyecto-en-curso-transportista/${id}/mapa/`}
                                        className="btn py-1"
                                        style={COLOR_BOTON(tipo)}
                                    >
                                        Rastrear proyecto
                                    </Link>
                                    :
                                    listado_proyectos_en_curso_contratista ?
                                        <Link
                                            to={`proyecto-en-curso/${id}/mapa/`}
                                            className="btn py-1"
                                            style={COLOR_BOTON(tipo)}
                                        >
                                            Rastrear proyecto
                                        </Link>
                                        :
                                        <Link
                                            to={`${listado_transportista ? "proyectos-transportistas" : "proyecto"}/${id}`}
                                            className="btn py-1"
                                            style={COLOR_BOTON(tipo)}
                                        >
                                            Ver detalle
                                        </Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CardProyecto;
