import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { AsyncSelectField2, SelectField, renderNumber, renderField } from '../../Utils/renderField/renderField';
import { validationTransportista } from '../../../../utility/validationForm'

import { tipo_refrigeracion } from '../../../../utility/constants'


import { api } from "api";
const eliminar_img = require('assets/img/icons/borrar_hover.svg');


const getVehiculo = (search) => {
    return api.get("tipo_vehiculo", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


const renderVehiculos = ({ fields, meta: { error, submitFailed }, contactos }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <div className="col-12 form-group m-auto px-0 px-sm-0 pt-2">
            <div className="row my-n2">
                <div className="react-bs-container-header" style={{ height: 25 }} >
                    <div className="d-none d-md-flex row border-publicidad py-1 px-0 px-sm-1 px-lg-1 px-xl-1">
                        <div className="col-1 mb-2 d-none d-md-flex justify-content-center align-items-center">
                        </div>
                        <div className="col-md-4 mb-2 px-1">
                            <label htmlFor="tipo" className="ml-2 blanco d-block">TIPO</label>
                        </div>
                        <div className="col-md-4 mb-2 px-1">
                            <label htmlFor="regrigeracion" className="blanco d-block">REFRIGERACIÓN</label>
                        </div>
                        <div className="col-md-3 mb-2 px-1">
                            <label htmlFor="capacidad" className="blanco d-block">CAPACIDAD</label>
                        </div>
                    </div>
                </div>
            </div>

            {fields.map((transporte, index) => {
                return (
                    <div className="row border-publicidad py-3 px-0 px-sm-1 flex-md-row flex-column" key={index}>
                        <div className="order-1 order-md-0 mx-auto px-1">
                            {fields.length > 1 &&
                                <img src={eliminar_img} alt="Eliminar"
                                    className="action_img"
                                    onClick={() => {
                                        fields.remove(index)
                                    }}
                                />
                            }
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="tipo" className="gris-oscuro d-block d-md-none">TIPO</label>
                            <Field
                                valueKey="id"
                                name={`${transporte}.vehiculo`}
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField2}
                                loadOptions={getVehiculo}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="regrigeracion" className="gris-oscuro d-block d-md-none">REFRIGERACIÓN</label>
                            <Field name={`${transporte}.refrigeracion`} component={SelectField} options={tipo_refrigeracion} className="form-control" />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="capacidad" className="gris-oscuro d-block d-md-none">CAPACIDAD</label>
                            <Field
                                name={`${transporte}.capacidad`}
                                type="text"
                                component={renderField}
                                placeholder="30 pies"
                                maxLength={validationTransportista.vehiculosCapacidad.MaxLength}
                            />
                        </div>
                    </div>
                )
            })}
            <div className="d-flex justify-content-center mt-3">
                <button type="button" className="btn btn-secondary py-1" onClick={() => {
                    fields.push({})
                }}>
                    AGREGAR
                </button>
            </div>
        </div>
    )
}

let VehiculosForm = props => {
    const { handleSubmit, editarPerfil } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 col-lg-8 mx-auto mt-5 px-0 px-sm-4">
                <div className="row  justify-content-center p-0 mt-3">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="transportes" className="m-0">Vehículos</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <FieldArray name="transportes" component={renderVehiculos} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                        <button className="btn btn-dark btn-block" onClick={editarPerfil}>Cancelar</button>
                    </div>
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};


const validatePrimary = (values) => {
    let errors = {};
    let DetalleArrayErrors = [];
    if (values && values.transportes && values.transportes.length) {
        values.transportes.forEach((detalle, detalleIndex) => {
            DetalleArrayErrors[detalleIndex] = validate(detalle, {
                vehiculo: validationTransportista.vehiculosVehiculo.Combine,
                refrigeracion: validationTransportista.vehiculosRefrigeracion.Combine,
                capacidad: validationTransportista.vehiculosCapacidad.Combine,
            })
        });
    }
    errors.transportes = DetalleArrayErrors
    return errors

};


VehiculosForm = reduxForm({
    form: 'profile',
    validate: validatePrimary
})(VehiculosForm);

export default VehiculosForm;
