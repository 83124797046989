import React, { useState, useCallback, useEffect } from 'react';
import Select, { Creatable, Async } from 'react-select';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader/FileUploader';
import FileUploaderPequeno from '../FileUploaderPequeno/FileUploader';
import DatePicker from "react-datepicker";
import { Rate } from 'antd';
import { DatePicker as AntDatepicker, Space } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import _ from 'lodash';
import moment from 'moment';
import Slider, { Range } from 'rc-slider';
import Cropper from 'react-easy-crop';
import { Modal } from 'react-responsive-modal';
import './ImgCrop.css'
import { Collapse } from 'antd';
const { Panel } = Collapse;
const alerta_img = require('assets/img/icons/alert.svg');
import { SWALMODERROR } from '../../../../utility/constants'


export const renderColorPicker = ({
    input,
    meta: { touched, error },
    disabled,
    title,
}) => {
    const invalid = touched && error;
    return (
        <div>
            {   title &&
                <label>{title} &nbsp;</label>
            }
            <input
                type="color"
                {...input}
                className={classNames("", {
                    "is-invalid": invalid,
                })}
                disabled={disabled ? disabled : false}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderField = ({
    input,
    placeholder,
    type,
    maxLength,
    minLength,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                type={type}
                maxLength={maxLength}
                minLength={minLength}
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderPasswordField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const [watch, setWatch] = useState(false);
    return (
        <div className="gris-oscuro collapse-contrasena mb-3 mb-md-0">
            <Collapse defaultActiveKey={['0']} >
                <Panel header="Cambiar contraseña" key="1">
                        <div className="position-relative">
                            <input
                                maxLength="151"
                                style={{backgroundImage: "none"}}
                                {...input}
                                placeholder={placeholder}
                                type={`${watch ? "text" : "password"}`}
                                className={classNames('form-control', {
                                    'is-invalid': invalid,
                                })}
                            />
                            <button type="button" className="btn-password" onClick={() => {setWatch(!watch) }}>
                                <i className={`material-icons ${invalid? "rojo": watch ? "azul-claro" : "gris-oscuro"}`}>remove_red_eye</i>
                            </button>
                        </div>
                    </Panel>
            </Collapse>
            {invalid && <div className="invalid-feedback d-inline">{error}</div>}
        </div>
    );
};

export const renderTextArea = ({
    input,
    placeholder,
    rows,
    maxLength,
    meta: { touched, error },
    addClassDiv,
    addClassTextArea,
}) => {
    const invalid = touched && error;
    return (
        <div className={addClassDiv && addClassDiv}>
            <textarea
                {...input}
                placeholder={placeholder}
                style={{ resize: 'none' }}
                rows={rows || 3}
                maxLength={maxLength}
                className={`${addClassTextArea && addClassTextArea} ${classNames('form-control', {
                    'is-invalid': invalid,
                })}`}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderNumber = ({
    input,
    decimalScale,
    placeholder,
    meta: { touched, error },
    prefix = '',
    suffix = '',
    numberFormat,
    isNumericString,
    maxLength,
    type,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
                maxLength={maxLength}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                isNumericString={isNumericString || false}
                prefix={prefix}
                suffix={suffix}
                type={type}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderCVC = ({
    input,
    placeholder,
    meta: { touched, error },
    maxLength,
    type,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                placeholder={placeholder}
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
                maxLength={maxLength}
                value={input.value}
                onKeyPress={key => {
                    if(!key.key.match(/[0-9]/)) key.preventDefault()
                }}
                type={type}
                onChange={(value) => {
                    input.onChange(value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderCurrency = ({
    input,
    meta: { touched, error },
    prefix = 'Q ',
    placeholder,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderSwitch = ({
    input,
    meta: { touched, error },
    label,
    disabled,
}) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center gris-oscuro">
            <Switch
                onColor="#FA7D09"
                height={18}
                width={36}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
            // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderFieldCheck = ({
    input,
    label,
    value,
    disabled,
    type,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && <div className="invalid-feedback" style={{ display: "inline" }}> {error}</div>}
        </React.Fragment>
    );
};

export const renderFieldRadio = ({
    input,
    label,
    value,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const SelectField = ({
    input,
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    options,
    placeholder,
    labelKey = 'label',
    valueKey = 'value',
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', {
                    'is-invalid': invalid,
                })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e[valueKey] : null);
                }}
                value={value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const AsyncSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    loadOptions,
    placeholder,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', {
                    'is-invalid': invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const AsyncSelectField2 = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        onCambio,
        valueKey,
        labelKey,
        defaultOptions,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder="Seleccionar.."
                onChange={(e) => {
                    input.onChange(e ? e : null);
                    if (onCambio) onCambio(e);
                }}
                value={input.value}
                isDisabled={disabled}
                getOptionValue={(option) => (option[valueKey])}
                getOptionLabel={(option) => (option[labelKey])}
                defaultOptions={defaultOptions ? defaultOptions : true}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderDatePicker = ({ className, disabled, onCambio, showTimeSelect, maxDate, minDate, todayButton, addClass, dateFormat, timeIntervals, timeFormat, placeholder, showPopperArrow, fecha_inicial, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                showPopperArrow={showPopperArrow ? showPopperArrow : false}
                dateFormat={dateFormat ? dateFormat : "DD/MM/YYYY"}
                timeFormat={timeFormat ? timeFormat : "HH:mm"}
                timeIntervals={timeIntervals ? timeIntervals : 15}
                timeCaption="Hora"
                placeholderText={placeholder}
                onChange={(e) => {
                    input.onChange(e);
                    if (!!onCambio) {
                        onCambio(e)
                    }
                }}
                showTimeSelect={showTimeSelect ? showTimeSelect : false}
                selected={input.value ? moment(input.value) : fecha_inicial}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                todayButton={todayButton}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && (
                <div className="d-inline-flex invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    )
};

export const CreatableSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    options,
    placeholder,
    labelKey = 'label',
    valueKey = 'value',
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', {
                    'is-invalid': invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({
    photo,
    setFile,
    className,
    disabled,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploader
                disabled={disabled}
                img={!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }}
            />
            {invalid && <div className="d-inline-flex invalid-feedback">{error}</div>}
        </div>
    );
};
export const renderFilePickerPequeno = ({
    photo,
    setFile,
    className,
    disabled,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploaderPequeno
                disabled={disabled}
                img={!!photo ? photo : null}
                onFileChange={(e, file, toBase64) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file, toBase64);
                        }
                    };
                    reader.readAsDataURL(file);
                }}
            />
            {invalid && <div className="d-inline-flex invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderDayPicker = ({
    className,
    disabled,
    maxDate,
    minDate,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(e) => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderRating = ({ name, input, readonly, disabled, size, meta: { touched, error } }) => {
    let invalid = input.value === '' ? true : false;
    return (
        <div>
            <Rate
                value={input.value}
                onChange={(value) => {
                    input.onChange(value);
                }}
                disabled={disabled}
                className="naranja"
            />
            {invalid && (
                <div className="error-rating">
                    <span>{error}</span>
                </div>
            )}
        </div>
    );
};


export const renderMonthPicker = ({ className, pago, disabled, onCambio, allowClear, addClass, placeholder, fecha_inicial, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    function disabledDate(current) {
        return current && current.valueOf() < Date.now();
    }
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <AntDatepicker
                format="MMMM/yyyy"
                picker="month"
                placeholder={placeholder ? placeholder : 'Seleccionar'}
                onChange={(e) => {
                    input.onChange(e);
                    if (!!onCambio) {
                        onCambio(e)
                    }
                }}
                disabledDate={pago && disabledDate}
                allowClear={allowClear ? allowClear : false}
                disabled={disabled ? disabled : false}
                locale={locale}
                value={input.value ? moment(input.value) : fecha_inicial}
                className={classNames('form-control month-picker ', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    )
};

// Componente de recorte de imagenes
export const renderImageUpload = ({ aspect, className, componenteHorizontal, disabled, setFile, imagen, meta: { touched, error } }) => {
    const invalid = touched && error;
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [state_modal, setOpenModal] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [imageSrc, setImgSrc] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const [active, setActive] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [imagen_local, setImagen] = useState(null);

    // componentDidUpdate
    useEffect(() => {
        if (croppedImage === null && imagen !== undefined && imagen !== null) {
            setImagen(imagen);
            setLoaded(true);
        }
    }, [imagen, croppedImage]);

    // // componentDidMount
    // useEffect(() => {
    //     if (imagen !== null && imagen !== undefined) {
    //         // setea la imágen si se le envia una
    //         setImagen(imagen);
    //         setLoaded(true);
    //     }
    // }, []);

    // // componentWillReceiveProps
    // useEffect(() => {
    //     if (imagen !== undefined && imagen !== null) {
    //         setImagen(imagen);
    //         setLoaded(true);
    //     }
    // }, [imagen]);

    const onZoomChange = zoom => {
        setZoom(zoom[0])
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const setFinalCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
            setCroppedImage(croppedImage)
            setFile(croppedImage)
            setImagen(croppedImage);
            // console.log("CROP ", croppedImage);
            setLoaded(true);
            onClose()
        } catch (e) {
            console.error(e)
        }
    },
        [imageSrc, croppedAreaPixels]
    )
    const onClose = useCallback(() => {
        // setCroppedImage(null);
        setOpenModal(false);
    }, [])

    const onFileChange2 = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);
            setImgSrc(imageDataUrl);
            setOpenModal(true);
        }
    }

    const onFileChange = (e) => {
        if (!e.target.files[0].type.match('image.*')) {
            SWALMODERROR.fire({
                title: '¡Informacion!',
                text: '¡Selecciona un archivo de tipo imagen!',
                confirmButtonText: 'Regresar',
                reverseButtons: true,
                imageUrl: alerta_img,
                imageAlt: 'Alerta',
                imageWidth: 90,
            })
            return
        }else{
            onFileChange2(e)
        }
    }

    const onDragEnter = () => {
        setActive(true)
    }

    const onDragLeave = (e) => {
        setActive(false)
    }

    const onDragOver = (e) => {
        e.preventDefault();
    }

    const onDrop = (e) => {
        e.preventDefault();
        setActive(false)
        onFileChange(e);
    }

    return (
        <div className={classNames(`${className}`)}>
            <label
                className={`uploader ${loaded && 'loaded'}`} style={{ height: componenteHorizontal && 80 }}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDragOver={onDragOver}
                onDrop={onDrop}
            >
                <img
                    src={croppedImage ? croppedImage : imagen_local ? imagen_local : require('assets/img/folder.svg')}
                    className={loaded ? 'loaded' : undefined}
                />
                <img style={{ opacity: loaded ? 0 : 1, maxHeight: componenteHorizontal && "50%", maxWidth: componenteHorizontal && "20%" }} className="icon icon-upload" src={require(`assets/img/upload.svg`)} alt="" />
                <p className="texto gris text-center" style={{ opacity: loaded ? 0 : 1, height: componenteHorizontal && "110px" }}>Subir Imagen</p>
                <input
                    disabled={disabled} type="file"
                    accept="image/jpeg,image/jpg,image/png"
                    onChange={onFileChange}
                />

            </label>
            {invalid && <div className="invalid-feedback d-flex">
                {error}
            </div>}
            <Modal open={state_modal} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} showCloseIcon={false} classNames={{ modal: "" }}>
                <div className="col-12">
                    <div style={{ position: 'relative', width: '100%', height: 400, background: '#333', }}>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            showGrid
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={onZoomChange}
                            zoomWithScroll={true}
                        />
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-6 col-12">
                        <button className="btn btn-secondary btn-block"
                            onClick={(e) => {
                                e.preventDefault();
                                onClose();
                            }}
                        >
                            Cancelar
                        </button>
                    </div>
                    <div className="col-md-6 col-12 mt-md-0 mt-3">
                        <button className="btn btn-primary btn-block"
                            onClick={(e) => {
                                e.preventDefault();
                                setFinalCroppedImage();
                            }}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
};

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const createImage = url => new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
})

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    return canvas.toDataURL('image/jpeg');

    // As a blob
    // return new Promise((resolve) => {
    //     canvas.toBlob((blob) => {
    //         resolve(blob)
    //     }, 'image/jpeg')
    // })
}

export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
};
