import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/pilotos';
import ListarPilotos from './Pilotos';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.pilotos,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListarPilotos);
