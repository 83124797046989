import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderFilePicker } from '../../Utils/renderField/renderField';
import ProfileForm from "./ProfileForm";
import VehiculosForm from "./VehiculosForm";
import CardForm from "Utils/Cards/CardForm";
import { USER_TRANSPORTISTA } from '../../../../utility/constants';
import Card from "../../Utils/Cards/Card"
import { Link } from "react-router-dom";
const persona_img = require('assets/img/icons/person.svg');
import { Rate } from 'antd';
import ProfileTransportista from "./ProfileTransportista";


import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avatar: null,
            rtu_img: null,
            editarPerfil: false,
            blob_avatar: null,
        };
    }

    componentDidMount() {
        this.props.reInitForm();
    }

    setAvatar = async (avatar) => {
        const _avatar = await fetch(avatar);
        const blob_avatar = await _avatar.blob();
        this.setState({ avatar });
        this.setState({ blob_avatar });
    };

    setRtuImg = (rtu_img) => {
        this.setState({ rtu_img });
    };

    update = (data) => {
        const { update } = this.props;
        update({ ...data, avatar: null, rtu_img: null }, [{ "file": this.state.blob_avatar, "name": "avatar" }, { "file": this.state.rtu_img, "name": "rtu_img" }]);
    };

    render() {
        const { me } = this.props;

        return (
            <div className="mb-4 col-12 mt-4">
                {me && me.tipo_usuario && me.tipo_usuario === USER_TRANSPORTISTA && !this.state.editarPerfil ?
                    <ProfileTransportista
                        me={me}
                        editarPerfil={() => this.setState({ editarPerfil: true })}
                    />
                    :
                    <Fragment>
                        {me.tipo_usuario === USER_TRANSPORTISTA ?
                            <Card colorFondo='bg-gris-claro tabsNegro ' titulo="MI PERFIL">
                                <Tabs
                                    defaultActiveKey="PERFIL"
                                    tabBarPosition="top"
                                    onChange={this.callback}
                                    renderTabBar={() => <ScrollableInkTabBar />}
                                    renderTabContent={() => <TabContent />}
                                >
                                    <TabPane
                                        tab="PERFIL"
                                        key="PERFIL"
                                    >
                                        <ProfileForm
                                            className="col-12 col-lg-8 mx-auto mt-5"
                                            onSubmit={this.update}
                                            me={me}
                                            setAvatar={this.setAvatar}
                                            avatar={this.state.avatar}
                                            setRtuImg={this.setRtuImg}
                                            recargarMe={this.props.reInitForm}
                                            editarPerfil={() => this.setState({ editarPerfil: false })}
                                        />
                                    </TabPane>
                                    <TabPane
                                        tab="TRANSPORTE"
                                        key="TRANSPORTE"
                                    >
                                        <VehiculosForm
                                            onSubmit={this.update}
                                            editarPerfil={() => this.setState({ editarPerfil: false })}
                                        />
                                    </TabPane>
                                </Tabs>

                            </Card>
                            :
                            <CardForm col={10} titulo="MI PERFIL">
                                <ProfileForm
                                    onSubmit={this.update}
                                    me={me}
                                    setAvatar={this.setAvatar}
                                    avatar={this.state.avatar}
                                    setRtuImg={this.setRtuImg}
                                    recargarMe={this.props.reInitForm}
                                    editarPerfil={() => this.setState({ editarPerfil: false })}
                                />
                            </CardForm>
                        }
                    </Fragment>
                }

            </div>
        );
    }
}

export default reduxForm({
    form: 'profile', // a unique identifier for this form
})(Profile);
