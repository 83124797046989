import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import proyectos from './modules/proyectos';
import pilotos from './modules/pilotos';
import pasarela from "./modules/pasarela";
import chat from "./modules/chat";
import suscripcion from "./modules/suscripcion";
import publicidades from './modules/publicidades';
import panelFinanciero from './modules/panelFinanciero'

export default combineReducers({
    form: formReducer,
    login,
    register,
    profile,
    usuarios,
    routing,
    notificaciones,
    proyectos,
    pilotos,
    chat,
    pasarela,
    suscripcion,
    publicidades,
    panelFinanciero,
});
