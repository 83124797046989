import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators, combine } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderFilePicker, renderMonthPicker, renderCVC, renderNumber, renderSwitch, SelectField } from '../../../Utils/renderField/renderField';
import { RenderDollar, RenderQuetzal } from '../../../Utils/renderField/renderReadField';
import { render } from 'react-dom';
import { validateCreditCard } from '../../../../../utility/validationForm'

const RenderDollarAndQuetzal = ({ titulo, monto, tasa_cambio, grande = false }) => {
    titulo = titulo ? titulo : ""
    monto = monto ? monto : 0
    tasa_cambio = tasa_cambio ? tasa_cambio : 0
    return (
        <div className="col-md-6 mb-2">
            {titulo &&
                <Fragment>
                    <label className="m-0">{titulo}</label>
                    <br />
                </Fragment>
            }
            <div className="d-flex flex-wrap bg-white">
                <RenderDollar className={`flex-1 border bold naranja pl-1 ${grande ? "text-md" : "text-sm"}`} value={monto} />
                <RenderQuetzal className={`flex-1 border bold text-right azul pl-1 ${grande ? "text-md" : "text-sm"}`} value={monto * tasa_cambio} />
            </div>
        </div>
    )
}


const DesgloseDeCobro = ({ costo_proyecto, costo_comision, costo_iva, monto_costo_viaje, tasa_cambio }) => {
    costo_proyecto = costo_proyecto ? costo_proyecto : 0
    costo_comision = costo_comision ? costo_comision : 0
    costo_iva = costo_iva ? costo_iva : 0
    monto_costo_viaje = monto_costo_viaje ? monto_costo_viaje : 0
    tasa_cambio = tasa_cambio ? tasa_cambio : 0
    return (
        <div className="row col-md-12 p-0 mx-0 border-publicidad py-3 bg-gris-muy-claro">
            <RenderDollarAndQuetzal titulo='Costo del proyecto' monto={costo_proyecto} tasa_cambio={tasa_cambio} />
            <RenderDollarAndQuetzal titulo='Costo por servicio' monto={costo_comision} tasa_cambio={tasa_cambio} />
            <RenderDollarAndQuetzal titulo='Costo del IVA' monto={costo_iva} tasa_cambio={tasa_cambio} />
            <RenderDollarAndQuetzal grande titulo='COSTO TOTAL' monto={monto_costo_viaje} tasa_cambio={tasa_cambio} />
        </div>
    )
}
const DesgloseSaldoAFavor = ({ saldo_a_favor, monto_a_cobrar, tasa_cambio }) => {
    saldo_a_favor = saldo_a_favor ? saldo_a_favor : 0
    monto_a_cobrar = monto_a_cobrar ? monto_a_cobrar : 0
    tasa_cambio = tasa_cambio ? tasa_cambio : 0
    return (
        <div className="row col-md-12 p-0 mx-0 border-publicidad py-3 bg-gris-muy-claro my-3">
            <RenderDollarAndQuetzal titulo='Saldo a favor' monto={saldo_a_favor} tasa_cambio={tasa_cambio} />
            <RenderDollarAndQuetzal grande titulo='MONTO TOTAL A COBRAR' monto={monto_a_cobrar} tasa_cambio={tasa_cambio} />
        </div>
    )
}


let PasarelaForm = props => {
    const { handleSubmit, item, redireccionar } = props;
    const SALDO_DEUDOR = 10  // Deudor, es decir aun debe realizar una transferencia para completar el nuevo monto
    const SALDO_ACREDOR = 20  // Acredor, es decir aun posee residuo de su saldo
    const SALDO_JUSTO = 30  // Justo, no debe pagar y tampoco tiene residuo de su saldo
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 ">
                <div className="row  col-md-12 p-0 mx-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <span htmlFor="nombre" className="m-0 pl-1 gris bold">Nombre del proyecto</span>
                        </div>
                        <div className="col-md-12 form-group">
                            <span className="bold text-sm gris-oscuro pl-1">{item && item.proyecto && item.proyecto.nombre ? item.proyecto.nombre : ""}</span>
                        </div>
                    </div>
                </div>
                <div className="row d-flex align-items-end col-md-12 p-0 mx-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <span htmlFor="nombre" className="m-0 pl-1 gris bold">Origen</span>
                        </div>
                        <div className="col-md-12  form-group">
                            <p className="bold text-xs gris-oscuro mb-0 pl-1">{item && item.proyecto && item.proyecto.pais_origen && item.proyecto.ciudad_origen && item.proyecto.pais_origen.nombre && item.proyecto.ciudad_origen.nombre && item.proyecto.direccion_origen ? `${item.proyecto.direccion_origen}, ${item.proyecto.ciudad_origen.nombre}, ${item.proyecto.pais_origen.nombre}` : ""}</p>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <span htmlFor="telefono" className="m-0 pl-1 gris bold">Destino</span>
                        </div>
                        <div className="col-md-12  form-group">
                            <p className="bold text-xs gris-oscuro mb-0 pl-1">{item && item.proyecto && item.proyecto.pais_destino && item.proyecto.ciudad_destino && item.proyecto.pais_destino.nombre && item.proyecto.ciudad_destino.nombre && item.proyecto.direccion_destino ? `${item.proyecto.direccion_destino}, ${item.proyecto.ciudad_destino.nombre}, ${item.proyecto.pais_destino.nombre}` : ""}</p>
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0 mx-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <span htmlFor="nombre" className="m-0 pl-1 gris bold">Adjudicado a</span>
                        </div>
                        <div className="col-md-12 form-group">
                            {/* Cambiar el endpoint si se cambia */}
                            <span className="bold text-xs gris-oscuro pl-1">{item && item.oferta && item.oferta.transportista && item.oferta.transportista.nombre ? item.oferta.transportista.nombre : ""}</span>
                        </div>
                    </div>
                </div>
                
                {/* Inicio del formulario */}
                {item && item.tipo_de_saldo == SALDO_DEUDOR &&
                    <Fragment>
                        <div className="row  col-md-12 p-0 mx-0">
                            <div className="col-md-12 p-0">
                                <div className="col-md-12 my-3">
                                    <span className="bold text-sm gris-oscuro pl-1">DATOS DE TARJETA</span>
                                </div>
                            </div>
                        </div>
                        <div className="row  col-md-12 p-0 mx-0">
                            <div className="col-md-6 p-0 align-self-end">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="no_tarjeta" className="m-0">No. de tarjeta*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="no_tarjeta"
                                        numberFormat="#### #### #### ####"
                                        placeholder="#### #### #### ####"
                                        component={renderNumber}
                                        className="form-control"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="mes_anio_vencimiento" className="m-0">Mes / Año de vencimiento*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="mes_anio_vencimiento" component={renderMonthPicker} pago type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 p-0 mx-0">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="codigo_seguridad" className="m-0">Código de seguridad (CVC)*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="codigo_seguridad"
                                        // numberFormat="###"
                                        // placeholder="###"
                                        component={renderCVC}
                                        className="form-control"
                                        type="password"
                                        maxLength="4"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0 text-right mt-auto mb-3">
                                <span className="azul bold text-xsm mr-4">* Campos requeridos</span>
                            </div>
                        </div>
                    </Fragment>

                }

                <div className="col-12">
                    <DesgloseDeCobro
                        costo_proyecto={item.monto_proyecto}
                        costo_comision={item.monto_comision}
                        costo_iva={item.monto_iva}
                        monto_costo_viaje={item.monto_costo_viaje}
                        tasa_cambio={item.tasa_cambio}
                    />

                    {item && item.saldo_a_favor && item.saldo_a_favor > 0 &&
                        <Fragment>
                            <DesgloseSaldoAFavor
                                saldo_a_favor={item.saldo_a_favor}
                                monto_a_cobrar={item.monto_a_cobrar}
                                tasa_cambio={item.tasa_cambio}
                            />

                            {item.tipo_de_saldo === SALDO_ACREDOR && item.monto_residuo_devolucion && item.monto_residuo_devolucion > 0 &&
                                <div className="col-12">
                                    <label className="m-0">Proximamente se le estara realizando la transferencia de tu residuo, que tiene un valor de</label>
                                    <RenderDollarAndQuetzal monto={item.monto_residuo_devolucion} tasa_cambio={item.tasa_cambio} />
                                </div>
                            }

                            {item.tipo_de_saldo === SALDO_JUSTO &&
                                <div className="col-12">
                                    <label className="m-0">Tu saldo a favor cubro el costo del proyecto.</label>
                                </div>
                            }
                        </Fragment>
                    }
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-dark btn-block" to={`/proyecto/${redireccionar}`}>Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Pagar</button>
                    </div>
                </div>
            </div>
        </form >
    )
};
PasarelaForm = reduxForm({
    form: 'PasarelaForm',
    validate: validateCreditCard,
})(PasarelaForm);

export default PasarelaForm
