import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './RealizarRetiroForm';
export default class RealizarRetiro extends Component {
    componentWillMount() {

        this.props.datoMiCuenta()
    }
    render() {
        const { solicitudRetiroParcial } = this.props;
        const { totales: { saldo_total = 0 } = "", loader } = this.props;

        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm
                    titulo="HACER RETIRO">
                    <LoadMask loading={loader} blur>
                        <Form onSubmit={solicitudRetiroParcial} saldoDisponible={saldo_total} initialValues={{ monto_saldo: saldo_total }} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
