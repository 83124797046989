import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import RegisterForm from './RegisterForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Registro extends Component {
    constructor(props) {
        super(props);
        this.state = { rtu_img: null };
    }

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    setRtuImg = (rtu_img) => {
        this.setState({ rtu_img });
    };

    crear = (data) => {
        this.props.onSubmit({ ...data, rtu_img: null }, [{ "file": this.state.rtu_img, "name": "rtu_img" }])
    }

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="blue-gradient-bg">
                <div className="contenedor-principal-form">
                    <div className="col-12 h-100 d-flex justify-content-center align-items-center flex-wrap">
                        <div className="col-12 login-wrapper d-flex flex-column">
                            <div className="col-xl-2 col-lg-3 col-md-3 col-11 mb-3 text-center">
                                <img src={require('assets/img/logo_completo.svg')} className="img-fluid" alt="Logo" />
                            </div>
                            <div className="card card-login col-xl-5 col-lg-7 col-md-7 col-11 mb-5">
                                <LoadMask loading={loader} light>
                                    <RegisterForm onSubmit={this.crear} setRtuImg={this.setRtuImg} />
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Registro;
