import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import _ from "lodash";
import { reset } from 'redux-form';
import { USER_ADMIN, USER_TRANSPORTISTA, USER_PILOTO } from "../../../utility/constants";

const LOADER = 'LOADER_PILOTOS';
const DATA = 'DATA_PILOTOS';
const ITEM_DATA = 'ITEM_PILOTOS';
const PAGE = 'PAGE_PILOTOS';
const ORDERING = 'ORDERING_PILOTOS';
const SEARCH = 'SEARCH_PILOTOS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1, transportista) => (dispatch, getStore) => {
    const resource = getStore().pilotos;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (transportista) {
        params.transportista_user_id = transportista;
    }
    dispatch(setLoader(true));
    api.get('user', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'user'}/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('PilotoForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data = {}, attachments = []) => (dispatch) => {
    const _data = data;
    _data.tipo_usuario = USER_PILOTO;
    dispatch(setLoader(true));
    api.postAttachments('user', _data, attachments).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/pilotos-transportista'));
    }).catch((error) => {
        let mensaje = 'Error en la creación, vuelva a intentar';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (id, data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`${'user'}/${id}`, data, attachments).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/pilotos-transportista'));
    }).catch((error) => {
        let mensaje = 'Datos incorrectos, vuelva a intentar';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch, getStore) => {
    const me = getStore().login.me;
    dispatch(setLoader(true));
    api.eliminar(`${'user'}/${id}`).then(() => {
        dispatch(listar(1, me.id));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('PilotoForm', {}));
};

const searchChange = search => (dispatch, getStore) => {
    const me = getStore().login.me;
    dispatch(setSearch(search));
    dispatch(listar(1, me.id));
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().pilotos.ordering;
    const me = getStore().login.me;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar(1, me.id));
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    // desactivar,
    // activar,
    cleanForm,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
