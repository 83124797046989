// Ruta del websocket
export const EASYTRUCK_SERVER_API = {
    WEBSOCKET: process.env.NODE_ENV === 'production' ? 'wss://app.easytruckss.com/ws' : 'ws://localhost:8000/ws'
};

// Tipos de usuarios
export const USER_ADMIN = 10;
export const USER_CONTRATISTA_INDIVIDUAL = 20;
export const USER_CONTRATISTA_EMPRESA = 30;
export const USER_TRANSPORTISTA = 40;
export const USER_PILOTO = 50;

// Tipo de contratista
export const tipo_contratista = [
    { value: USER_CONTRATISTA_INDIVIDUAL, label: "Persona individual" },
    { value: USER_CONTRATISTA_EMPRESA, label: "Empresa" },
];

// Estados del proyecto
export const EN_RECEPCION_OFERTAS = 10;
export const ADJUDICADO_PENDIENTE_DE_INICIAR = 20;
export const EN_CURSO = 30;
export const FINALIZADO = 40;
export const BORRADOR = 50;
export const VENCIDO = 100;

export const ESTADO_PROYECTO = [
    { color: "#7EA04D", value: EN_RECEPCION_OFERTAS, label: "En recepción", title: "PROYECTO EN RECEPCIÓN" },
    { color: "#FA7D09", value: ADJUDICADO_PENDIENTE_DE_INICIAR, label: "Adjudicados", title: "PROYECTO ADJUDICADO" },
    { color: "#10426D", value: EN_CURSO, label: "En curso", title: "PROYECTO EN CURSO" },
    { color: "#323232", value: FINALIZADO, label: "Finalizados", title: "PROYECTO FINALIZADO" },
    { color: "#804000", value: BORRADOR, label: "Borrador", title: "PROYECTO BORRADOR" },
    { color: "#D92027", value: VENCIDO, label: "Vencidos", title: "PROYECTO VENCIDO" },
];

export const tipo_refrigeracion = [
    { value: 10, label: "Refrigerado" },
    { value: 20, label: "Seco" },
];

// Tipo de suscripcion
export const MENSUAL = 10
export const SEMESTRAL = 20
export const ANUAL = 30

export const TIPO_SUSCRIPCION = {
    "mensual": MENSUAL,
    "semestral": SEMESTRAL,
    "anual": ANUAL,
}

//  cada suscripcion tiene adjuntado el nombre de la columna de la configuracion global

export const SUSCRIPCIONES_PRECIO = {
    "contratistaSuscripcion": {
        "mensual": "precio_mensual_contratista",
        "semestral": "precio_semestral_contratista",
        "anual": "precio_anual_contratista",
    },
    "transportistaSuscripcion": {
        "mensual": "precio_mensual_transportista",
        "semestral": "precio_semestral_transportista",
        "anual": "precio_anual_transportista",
    }
}

//estado de publicidad
export const EN_EDICION = 10
export const EN_REVISION = 20
export const VALIDADA = 30
export const RECHAZADA = 40
//Tipo de movimiento, detalle de movimiento
export const INGRESO = 10
export const EGRESO = 20

// Cambiar estilo para los Swal,
import Swal from 'sweetalert2';
export const SWALMOD = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-naranja m-2',
        cancelButton: 'btn btn-gris-oscuro m-2',
        title: 'text-azul-alerta',
        image: 'blink-danger',
        validationMessage: 'my-validation-message'
    },
    preConfirm: (value) => {
      if (!value) {
        SWALMOD.showValidationMessage(
          'La justificación es importante.'
        )
      }
    },
    buttonsStyling: false,
    focusConfirm: false,
    focusCancel: false,
})


export const SWALMODERROR = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-gris-oscuro m-2',
        title: 'text-azul-alerta',
        image: 'blink-danger',
    },
    buttonsStyling: false,
    focusConfirm: false,
    focusCancel: false,
})
