import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validators } from 'validate-redux-form';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { Rate } from 'antd';
import { RenderDollar, RenderQuetzal } from '../../../Utils/renderField/renderReadField';
import { renderCurrency, renderField, renderFilePicker, renderSwitch, renderTextArea, SelectField } from '../../../Utils/renderField/renderField';

const persona_img = require('assets/img/icons/person.svg');

const RenderDollarAndQuetzal = ({ titulo, monto, tasa_cambio, grande = false }) => {
    titulo = titulo ? titulo : ""
    monto = monto ? monto : 0
    tasa_cambio = tasa_cambio ? tasa_cambio : 0
    return (
        <div className="col-md-6 mb-2">
            {titulo &&
                <Fragment>
                    <label className="m-0">{titulo}</label>
                    <br />
                </Fragment>
            }
            <div className="d-flex flex-wrap bg-white">
                <RenderDollar className={`flex-1 border bold naranja pl-1 ${grande ? "text-md" : "text-sm"}`} value={monto} />
                <RenderQuetzal className={`flex-1 border bold text-right azul pl-1 ${grande ? "text-md" : "text-sm"}`} value={monto * tasa_cambio} />
            </div>
        </div>
    )
}

const DesgloseDeCobro = ({ costo_proyecto, confGlobal={}, pais_origen={}, pais_destino={}, ciudad_origen={}, ciudad_destino={} }) => {
    let viajeLargo = pais_origen.id !== pais_destino.id ? true : 
                        ciudad_origen.id !== ciudad_destino.id ? true : false
    const porcentaje_comision = confGlobal.porcentaje_comision_transportista
    costo_proyecto = Number(costo_proyecto ? costo_proyecto : 0)
    const costo_comision = -(costo_proyecto * (porcentaje_comision / 100))
    const costo_iva = (costo_proyecto) * 0.12
    const monto_costo_viaje = costo_proyecto + costo_comision + costo_iva
    const tasa_cambio = confGlobal.tasa_cambio
    return (
        <div className="row col-md-12 p-0 mx-0 border-publicidad py-3 bg-gris-muy-claro">
            <RenderDollarAndQuetzal titulo='Costo del proyecto' monto={costo_proyecto} tasa_cambio={tasa_cambio} />
            <RenderDollarAndQuetzal titulo='Costo por servicio' monto={costo_comision} tasa_cambio={tasa_cambio} />
            <RenderDollarAndQuetzal titulo='Costo del IVA' monto={costo_iva} tasa_cambio={tasa_cambio} />
            <RenderDollarAndQuetzal grande titulo='COSTO TOTAL' monto={monto_costo_viaje} tasa_cambio={tasa_cambio} />
        </div>
    )
}

let OfertaForm = props => {
    const { handleSubmit, me, mostrarBoton, item={} } = props;
    const { confGlobal={}, pais_origen={}, pais_destino={}, ciudad_origen={}, ciudad_destino={} } = item
    return(
        <div className={`card-proyecto borde-naranja px-3 pt-3 mb-3`}>
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container col-12 px-0 px-sm-4">
                    <div className="row col-md-12 p-0">
                        <div className="col-lg-4 p-0 d-flex justify-content-center align-items-center">
                            <div className="col-md-12 col-sm-12">
                                <div className="d-flex align-items-center">
                                    <div className="flex-2 d-flex justify-content-center align-items-center">
                                        <img src={me && me.avatar ? me.avatar : persona_img} alt="Persona" className="img-fluid"/>
                                    </div>
                                    <div className="flex-5 d-flex flex-column ml-2">
                                        <span className="bold gris-oscuro">{me && me.nombre ? me.nombre : ""}</span>
                                        <Rate disabled defaultValue={me && me.calificacion ? me.calificacion : 1} className="naranja"/>
                                        <span className="gris-letras bold parrafo-dos-lineas" >{me.descripcion ? me.descripcion : ""} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="descripcion" className="m-0">Descripción de oferta*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="descripcion" component={renderTextArea} rows={3} type="text" className="form-control" />
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="presupuesto_oferta" className="m-0">Presupuesto (oferta)*</label>
                            </div>
                            <div className="col-md-12 form-group">
                                <div className="row px-auto px-md-3 px-lg-0">
                                    <div className="col-lg-6 col-12 p-0 px-lg-3 mb-2">
                                        <Field name="presupuesto_oferta" component={renderCurrency} prefix="$ " type="number" className="form-control" />
                                    </div>
                                    <div className="col-lg-6 col-12 p-0 px-lg-3 mb-2 text-right">
                                        <button type="submit" className="btn btn-verde">Enviar oferta</button>
                                    </div>
                                </div>
                                <div className="row px-auto px-md-3 px-lg-0">
                                    <div className="col-lg-12 col-12 p-0 px-lg-3 mb-2">
                                        <DesgloseDeCobro
                                            costo_proyecto={props.presupuesto_oferta}
                                            confGlobal={confGlobal}
                                            pais_origen={pais_origen}
                                            pais_destino={pais_destino}
                                            ciudad_origen={ciudad_origen}
                                            ciudad_destino={ciudad_destino}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

const validate = (values, props) => {
    const errors = {};
    let presupuesto = props && props.presupuesto ? props.presupuesto : 0;
    let porcentaje_presupuesto = parseFloat(presupuesto) * 0.10;
    if (!values.descripcion) {
        errors.descripcion = "Campo requerido";
    }
    if (!values.presupuesto_oferta) {
        errors.presupuesto_oferta = "Campo requerido";
    }
    if (values.presupuesto_oferta < (parseFloat(presupuesto) - porcentaje_presupuesto)) {
        errors.presupuesto_oferta = "El valor de la contraoferta no puede ser tan bajo.";
    }
    if (values.presupuesto_oferta > (parseFloat(presupuesto) + porcentaje_presupuesto)) {
        errors.presupuesto_oferta = "El valor de la contraoferta no puede ser tan alto.";
    }
    return errors;
};

OfertaForm = reduxForm({
    form: 'OfertaForm',
    validate: (data, props) => {
        return validate(data, props)
    }
})(OfertaForm);

const selector = formValueSelector('OfertaForm')
OfertaForm = connect(state => {
    const presupuesto_oferta = selector(state, 'presupuesto_oferta')

    return {
        presupuesto_oferta
    }
})(OfertaForm)

export default OfertaForm
