import { connect } from 'react-redux';
import SuscripcionContainer from './Suscripcion';


const ms2p = (state) => {
    return {
        me: state.login.me,
    };
};

const md2p = {};

export default connect(ms2p, md2p)(SuscripcionContainer);
