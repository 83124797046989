import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './PilotoForm';
import EditarPilotoForm from './PilotoForm';

export default class Crear extends Component {
    state = {
        editar: false,
        licencia_img: null,
    };

    setLicenciaImg = (licencia_img) => {
        this.setState({licencia_img: licencia_img});
    };

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({editar: true});
        }
    }

    crear = (data) => {
        this.props.crear({...data, licencia_img: null}, [{"file": this.state.licencia_img, "name": "licencia_img"}])
    }

    actualizar = (data) =>{
        this.props.editar(this.props.match.params.id, {...data, licencia_img: null}, [{"file": this.state.licencia_img, "name": "licencia_img"}]);
    };

    render() {
        const { crear, editar } = this.props;
        const { updateData, loader, item } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm titulo={this.state.editar ? "EDITAR PILOTO" :"PILOTO NUEVO" }>
                    <LoadMask loading={loader} blur>
                        {
                            this.state.editar ?
                                <EditarPilotoForm
                                    onSubmit={this.actualizar}
                                    updateData={updateData}
                                    setLicenciaImg={this.setLicenciaImg}
                                    editando={true}
                                    item={item}
                                />
                            :
                            <Form onSubmit={this.crear} setLicenciaImg={this.setLicenciaImg}/>
                        }
                    </LoadMask>
                </CardForm>
        </div>
        )
    }
}
