import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import {Login, Profile,  RegistroTrasportista, RegistroOpcion} from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';
import sendRecoverPassword from './common/components/LoginRegister/RecoverPassword/SendRecovery';
import PassRecover from './common/components/LoginRegister/ChangePassword/';
import PilotoInfoChangePass from './common/components/LoginRegister/PilotoInfoChangePass'
import Loader from './common/components/layout/Loader/Loader';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from "./common/components/Examples/Grids";
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
require('../style/index.css');

try { require('../assets/sw') } catch (e) {
    console.error('sw ', e)
}

try {require('../assets/firebase-messaging-sw')}catch (e) {
    console.error('Firebase messaging', e)
}

// HOME
import { Home } from './common/components/App/Home';

// Suscripcion
import { SuscripcionContainer } from './common/components/App/Suscripcion';

// PROYECTOS CONTRATISTAS
import { ProyectosList, CrearProyecto, ProyectoDetalle, ProyectoDetalleOferta, ChatContratista, ProyectosEnCursoContratista, ProyectoMapaContratista } from './common/components/App/Proyectos';
// PROYECTOS TRANSPORTISTAS
import { ProyectosPublicados, ProyectoTransDetalle, ProyectosAdjudicados, ProyectoDetalleAdjudicado, ChatTransportistas, ProyectoMapa, ProyectosEnCurso } from './common/components/App/ProyectosTransportistas';
// PILOTOS TRANSPORTISTAS
import { PilotosList, CrearPiloto } from './common/components/App/PilotosTranportista';
// PUBLICIDAD
import { PublicidadesList, CrearPublicidad, VerPublicidad} from './common/components/App/Publicidad';
// Suscripcion TRANSPORTISTAS
import { PagarSuscripcion} from './common/components/App/Suscripcion';
// PASARELA DE PAGO
import { PasarelaPago } from "./common/components/App/PagoProyecto";
// PANEL FINANCIERO
import { PanelFinancieroListarMovimiento, PanelFinancieroRealizarRetiro} from "./common/components/App/PanelFinanciero"

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/registro" component={RegistroOpcion} />
                <Route exact path="/registro_transportista" component={RegistroTrasportista} />
                <Route exact path="/recuperar_pass" component={sendRecoverPassword}/>
                <Route exact path="/cambiar_pass/:token" component={PassRecover}/>
                <Route exact path="/info-piloto" component={PilotoInfoChangePass}/>
                {/* COMPONENTES */}
                <ProtectedRoute exact path="/" component={Home} />
                <ProtectedRoute exact path="/loader" component={Loader} />
                <ProtectedRoute exact path="/page2" component={Examples} />
                <ProtectedRoute exact path="/user-profile" component={Profile} />
                <ProtectedRoute exact path="/grids" component={Grids} />
                <ProtectedRoute exact path="/notifications" component={Notificaciones} />
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} />

                {/* SuscripcionContainer */}
                <ProtectedRoute exact path="/suscripcion" component={SuscripcionContainer} />

                {/* PROYECTOS CONTRATISTAS*/}
                <ProtectedRoute exact path="/proyectos" component={ProyectosList} />
                <ProtectedRoute exact path="/proyecto/crear" component={CrearProyecto} />
                <ProtectedRoute exact path="/proyecto/:id" component={ProyectoDetalle} />
                <ProtectedRoute exact path="/proyecto_oferta/:idProyecto/:idOferta" component={ProyectoDetalleOferta} />
                <ProtectedRoute exact path="/proyecto/:id/editar" component={CrearProyecto} />
                <ProtectedRoute exact path="/proyecto/:id/chat" component={ChatContratista} />
                <ProtectedRoute exact path="/proyectos-en-curso" component={ProyectosEnCursoContratista} />
                <ProtectedRoute exact path="/proyecto-en-curso/:id/mapa" component={ProyectoMapaContratista} />

                {/* PROYECTOS TRANSPORTISTAS*/}
                <ProtectedRoute exact path="/proyectos-transportistas" component={ProyectosPublicados} />
                <ProtectedRoute exact path="/proyectos-transportistas/:id" component={ProyectoTransDetalle} />
                <ProtectedRoute exact path="/mis-proyectos-transportistas" component={ProyectosAdjudicados} />
                <ProtectedRoute exact path="/mi-proyecto-transportista/:id" component={ProyectoDetalleAdjudicado} />
                <ProtectedRoute exact path="/mi-proyecto-transportista/:id/chat" component={ChatTransportistas} />
                <ProtectedRoute exact path="/proyectos-en-curso-transportistas" component={ProyectosEnCurso} />
                <ProtectedRoute exact path="/proyecto-en-curso-transportista/:id/mapa" component={ProyectoMapa} />

                {/* PILOTOS - TRANSPORTISTAS*/}
                <ProtectedRoute exact path="/pilotos-transportista" component={PilotosList} />
                <ProtectedRoute exact path="/piloto-transportista/crear" component={CrearPiloto} />
                <ProtectedRoute exact path="/piloto-transportista/:id/editar" component={CrearPiloto} />

                 {/* PANEL FINANCIERO - TRANSPORTISTAS*/}
                <ProtectedRoute exact path="/panel-financiero-transportista" component={PanelFinancieroListarMovimiento} />
                <ProtectedRoute exact path="/panel-financiero-transportista-retiro" component={PanelFinancieroRealizarRetiro} />

                {/* PUBLICIDAD */}
                <ProtectedRoute exact path="/publicidades" component={PublicidadesList} />
                <ProtectedRoute exact path="/publicidad/crear" component={CrearPublicidad} />
                <ProtectedRoute exact path="/publicidad/:id/editar" component={CrearPublicidad} />
                <ProtectedRoute exact path="/publicidad/:id" component={VerPublicidad} />

                {/* PAGO DE LA SUSCRIPCION */}
                <ProtectedRoute exact path="/pago-suscripcion/:suscripcion" component={PagarSuscripcion} />

                {/* PASARELA DE PAGO */}
                <ProtectedRoute exact path="/pasarela/:idProyecto/:idOferta" component={PasarelaPago} />

                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
