import { api } from "api";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchInput from './SearchInput';

export default class Header extends Component {
    render() {
        const { filtro1, filtro2 } = this.props;
        const { search, to, textBtn, textBtn2, disabledBtn2, btn2Click, searchChange, placeholder} = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-lg-row col-12 mb-3 px-0">
                    <div className={`d-flex flex-row justify-content-start justify-content-lg-start flex-2 mx-1 px-0 ${(to !== undefined || textBtn2 !== undefined) ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                        {(to !== undefined) && (
                            <Link to={to} className="btn btn-primary mb-3">{textBtn}</Link>
                        )}
                        {(textBtn2 !== undefined) && (
                            <button onClick={btn2Click} disabled={disabledBtn2 ? disabledBtn2 : false} className="btn btn-secondary ml-2 mb-3">{textBtn2}</button>
                        )}
                    </div>
                    <div className={`d-flex flex-column flex-1 mx-1 px-0 ${filtro2 ? "my-2 my-lg-0" : "d-sm-none d-md-none d-lg-inline"}`}>
                        {filtro2 && filtro2}
                    </div>
                    <div className={`d-flex flex-column flex-1 mx-1 px-0 ${filtro1 ? "my-2 my-lg-0" : "d-sm-none d-md-none d-lg-inline"}`}>
                        {filtro1 && filtro1}
                    </div>
                    {(search !== undefined) && (
                        <div className={`d-flex flex-column flex-2 mx-1 px-0 ${search !== undefined ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                                <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
