import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import PasarelaForm from './PasarelaForm';
import moment from 'moment';

export default class PasarelaPago extends Component {
    componentWillMount(){
        if(this.props.match.params.idProyecto && this.props.match.params.idOferta){
            this.props.leer(this.props.match.params.idProyecto, this.props.match.params.idOferta );
        }
    }

    pagar = (datos) => {
        let data = { ...datos }
        data.mes_expiracion = moment(data.mes_anio_vencimiento).format("MM");
        data.anio_expiracion = moment(data.mes_anio_vencimiento).format("YYYY");
        this.props.pagar(data)
    }

    render() {
        const { pagar, editar } = this.props;
        const { updateData, loader, item } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm titulo="PAGO PROYECTO ADJUDICADO">
                    <LoadMask loading={loader} blur>
                        <PasarelaForm
                            onSubmit={this.pagar}
                            {...this.props}
                            redireccionar={ this.props.match.params.idProyecto ? this.props.match.params.idProyecto : "" }
                        />
                    </LoadMask>
                </CardForm>
        </div>
        )
    }
}
