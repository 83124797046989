import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import _ from "lodash";
import { getMe } from '../cuenta/login';

const LOADER = 'LOADER_PUBLICIDADES';
const DATA = 'DATA_PUBLICIDADES';
const ITEM_DATA = 'ITEM_PUBLICIDADES';
const PAGE = 'PAGE_PUBLICIDADES';
const ORDERING = 'ORDERING_PUBLICIDADES';
const SEARCH = 'SEARCH_PUBLICIDADES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().publicidades;
    const params = { page };
    params.ordering = "-modificado";
    params.search = resource.search;

    dispatch(setLoader(true));
    api.get('publicidad', params).then((response) => {
        dispatch(getMe());
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'publicidad'}/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('PublicidadForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('publicidad', data, attachments).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        // dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/publicidades'));
    }).catch((error) => {
        let mensaje = 'Error en la creación';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 4000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (id, data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`${'publicidad'}/${id}`, data, attachments).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/publicidades'));
    }).catch((error) => {

        let mensaje = 'Erro en la edición';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 4000);
        dispatch(push('/publicidades'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch, getStore) => {
    const me = getStore().login.me;
    dispatch(setLoader(true));
    api.eliminar(`${'publicidad'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('PublicidadForm', {}));
};

const envioARevision = id => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${'publicidad'}/${id}/envio_a_revision`).then(() => {
        dispatch(listar());
        dispatch(push('/publicidades'));
        NotificationManager.success('Publicidad enviado a revisión', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error al enviar la publicidad';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
        dispatch(push('/publicidades'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const searchChange = search => (dispatch, getStore) => {
    const me = getStore().login.me;
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().publicidades.ordering;
    const me = getStore().login.me;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const eliminarArchivo = (index) => (dispatch, getStore) => {
    const FORM_PRINCIPAL = getStore().form.PublicidadForm.values;
    let form_principal = FORM_PRINCIPAL ? _.cloneDeep(FORM_PRINCIPAL) : {};

    let publicidades = _.cloneDeep(form_principal.detalle_publicidad);
    publicidades = _.filter(publicidades, (item, i) => i != index);

    form_principal.detalle_publicidad = publicidades

    dispatch(initializeForm('PublicidadForm', {}));

    setTimeout(() => {
        dispatch(initializeForm('PublicidadForm', form_principal));
    }, 1);

}

const setArchivos = (archivos_nuevos) => (dispatch, getStore) => {
    const FORM_PRINCIPAL = getStore().form.PublicidadForm.values;
    const form_principal = FORM_PRINCIPAL ? _.cloneDeep(FORM_PRINCIPAL) : {};

    const publicidades = form_principal.detalle_publicidad ? _.cloneDeep(form_principal.detalle_publicidad) : [];

    for (let index = 0; index < publicidades.length; index++) {
        if (archivos_nuevos[index] != "x" && archivos_nuevos[index]) {
            publicidades[index].imagen = archivos_nuevos[index]
        }

    }
    form_principal.detalle_publicidad = publicidades
    dispatch(initializeForm('PublicidadForm', form_principal));
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    envioARevision,
    eliminarArchivo,
    setArchivos,
    cleanForm,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
