import React, { Component, Fragment } from 'react';
import { USER_TRANSPORTISTA } from '../../../../utility/constants';
import Card from "../../Utils/Cards/Card"
import { Link, Redirect } from "react-router-dom";
const persona_img = require('assets/img/icons/person.svg');
import { Rate } from 'antd';

import { connect } from 'react-redux';
import { listarComentariosTransportista } from '../../../../redux/modules/cuenta/profile';

class ProfileTransportista extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verMas: false
        };
    }



    componentWillMount() {
        const { me, listarComentariosTransportista } = this.props;

        listarComentariosTransportista(me.id)
    }

    render() {
        const { me, editarPerfil, comentarios } = this.props;
        const { verMas } = this.state;

        return (
            <div className="mb-4 col-12 mt-4 px-0">
                {me && me.tipo_usuario && me.tipo_usuario === USER_TRANSPORTISTA ?

                    <Card titulo="MI PERFIL">
                        <div className="card-proyecto my-0 my-sm-4 mx-0 mx-sm-4 borde-naranja px-0 px-sm-3 py-4 mb-3">

                            <div className="col-12 mb-lg-0 mb-3 text-right px-2 pr-4">
                                <button className="btn btn-secondary px-4" onClick={editarPerfil}>Editar Perfil</button>
                            </div>

                            <div className="col-12 px-0 px-sm-3 mb-4">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-8 col-md-6 col-lg-3  text-center">
                                        <img src={me.avatar ? me.avatar : persona_img} alt="Persona" className="img-fluid" />
                                    </div>
                                    <span className="text-lg gris-oscuro bold text-left">{me.nombre ? me.nombre : ""}</span>
                                    <Rate disabled defaultValue={me.calificacion ? me.calificacion : 1} className="naranja estrellaGrande" />
                                </div>
                            </div>


                            {/* <div className="col-9 px-0 px-sm-3 align-self-center d-flex flex-column flex-xl-row">      */}
                            <div className="col-9 px-0 px-sm-3 align-self-center d-flex flex-wrap flex-column flex-md-row">
                                {/* <div className="row"> */}
                                <span className="mt-2 mr-3 mb-1 gris-oscuro bold flex-1 d-flex flex-column">Usuario
                                        <span className="gris ">{me.username ? me.username : ""}</span>
                                </span>

                                <span className="mt-2 mr-3 mb-1 gris-oscuro bold flex-1 d-flex flex-column">E-mail
                                        <span className="gris ">{me.email ? me.email : ""}</span>
                                </span>

                                <span className="mt-2 mr-3 mb-1 gris-oscuro bold flex-1 d-flex flex-column">NIT
                                        <span className="gris ">{me.nit ? me.nit : ""}</span>
                                </span>

                                {/* </div>   */}
                            </div>
                            <div className="col-9 px-0 px-sm-3 align-self-center d-flex flex-wrap flex-column flex-md-row">
                                <span className="mt-2 mr-2 mb-1 gris-oscuro bold d-flex flex-column">Descripción<span className="gris ">{me.descripcion ? me.descripcion : ""}</span></span>

                            </div>

                            {/* <div className="col-12  d-flex justify-content-center flex-column align-items-center"> */}

                            <div className="row justify-content-center my-3">
                                {comentarios && comentarios.length > 0 ?
                                    <Fragment>
                                        <div className="col-10 mt-3">
                                            <span className="bold gris-letras text-sm ">COMENTARIOS</span>
                                        </div>
                                        <div className="comentarios" style={{ maxWidth: 700 }}>
                                            {comentarios.slice(0, verMas ? comentarios.length : 2).map((comentario, index) => {
                                                return (
                                                    <div key={index} className="col-12 mt-3 px-0 px-sm-4">
                                                        <div className="col-12 p-0 d-flex justify-content-center pb-2" style={{ borderBottom: "1px black solid" }} >
                                                            <div className="col-12 d-flex flex-column flex-sm-row"  >
                                                                <div className="flex-1 d-flex justify-content-center align-items-center">
                                                                    <img src={comentario && comentario.calificador && comentario.calificador.avatar ? comentario.calificador.avatar : persona_img} alt="Persona" className="img-fluid" />
                                                                </div>
                                                                <div className="flex-5 d-flex flex-column ml-2">
                                                                    <span className="bold gris-oscuro">{comentario && comentario.calificador && comentario.calificador.nombre ? comentario.calificador.nombre : ""}</span>
                                                                    <span className="text-justify gris-oscuro">{comentario && comentario.comentario ? comentario.comentario : ""}</span>
                                                                    <Rate disabled defaultValue={comentario && comentario.calificacion ? comentario.calificacion : 1} className="naranja" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        {comentarios && !verMas && comentarios.length > 2 &&
                                            <div className="col-12 mt-3 text-center">
                                                <span className="bold naranja image-puntero "
                                                    onClick={() => this.setState({ verMas: true })}
                                                >
                                                    Ver más
                                     </span>
                                            </div>
                                        }
                                    </Fragment>
                                    :
                                    <div className="col-10 mt-3">
                                        <span className="bold gris-letras text-sm ">No hay comentarios.</span>
                                    </div>
                                }

                            </div>

                            <div className="d-flex flex-wrap justify-content-center align-items-center mt-2">
                                <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                                    <Link className="btn btn-dark btn-block" to="/">Cancelar</Link>
                                </div>
                            </div>
                        </div>
                    </Card>
                    :
                    <Redirect to="/" />
                }

            </div>
        );
    }
}

const mstp = state => {
    return {
        comentarios: state.profile.comentarios
    }
};

const mdtp = {
    listarComentariosTransportista
};

export default connect(mstp, mdtp)(ProfileTransportista)
