import React, { Component, Fragment } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import { USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, USER_TRANSPORTISTA } from '../../../../../utility/constants';
import moment from 'moment';
import chat from '../../../../../redux/modules/chat';
import { WebSocketChatInstance } from '../../../../../utility/chat';
import { Link } from "react-router-dom";

const persona_img = require('assets/img/icons/person.svg');
const arrow_img = require('assets/img/icons/arrow.svg');

export default class ChatContratista extends Component {
    constructor(props) {
        super(props);
        this.state = { messages: [], batch: 1, inputMessage: '', chatInactive: false, chatClosed: false, msgLocked: '' };
    }

    componentDidMount() {
        this.props.leerAsync(this.props.match.params.id).then(() => {
            if (this.props.item && this.props.item.chat_proyecto) {
                WebSocketChatInstance.connect(this.props.item.chat_proyecto);
                this.waitForSocketConnection(() => {
                    WebSocketChatInstance.addCallbacks(
                        this.setMessages.bind(this), this.addMessage.bind(this), this.chatClosed.bind(this), this.chatInactive.bind(this)
                    )
                    WebSocketChatInstance.fetchMessages(1);
                });
            }
        });
    }

    componentWillUnmount() {
        try {
            WebSocketChatInstance.disconnect();
        } catch (error) {

        }
    }

    waitForSocketConnection(callback) {
        const component = this;
        setTimeout(
        function () {
            // Check if websocket state is OPEN
            if (WebSocketChatInstance.stateConnection() === 1) {
            console.log("Connection is made")
            callback();
            return;
            } else {
            console.log("wait for connection...")
            component.waitForSocketConnection(callback);
            }
        }, 100);
    }

    chatClosed(message) {
        this.setState({ chatClosed: true });
    }

    chatInactive(message) {
        this.setState({ chatInactive: true, msgLocked: message });
    }

    addMessage(message) {
        this.setState({ messages: [...this.state.messages, message]});
        document.getElementById('chat-container').scrollTop = document.getElementById('chat-container').scrollHeight
    }

    setMessages(messages) {
        this.setState({ messages: [...this.state.messages, ...messages]});
        document.getElementById('chat-container').scrollTop = document.getElementById('chat-container').scrollHeight
    }

    messageChangeHandler = (message) =>  {
        this.setState({ inputMessage: message.target.value })
    };

    getMessagesBatch = () => {
        const batch = this.state.batch + 1;
        WebSocketChatInstance.fetchMessages(batch);
        this.setState({ batch });
    };

    sendMessageHandler = () => {
        if(this.state.inputMessage !== ""){
            const messageObject = {
            from: this.props.me.username,
            text: this.state.inputMessage
            };
            WebSocketChatInstance.newChatMessage(messageObject);
            this.setState({ inputMessage: '' })
        }
    };

    render() {
        const { me } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <div className="d-flex align-items-baseline ml-3 mb-4">
                    <h3>CHAT CON TRANSPORTISTA</h3>
                </div>
                <div className="d-flex justify-content-center">
                    <div id="chat-container" className={`col-12 col-lg-10 chat bg-white borde-azul py-3 ${this.state.messages.length === 0 ? 'd-flex justify-content-center align-items-center' : ''}`}>
                        {this.state.messages.length !== 0 && this.state.messages.map(function(item, i){
                            return (
                                <div className="col-12 px-lg-2 px-0 bg-white" key={i}>
                                    {me && me.username && me.username !== item.sender.username && (
                                        <div className="row">
                                            <div className="col-lg-1 col-2 d-flex justify-content-center align-items-center">
                                                <img src={item && item.sender && item.sender.avatar ? item.sender.avatar : persona_img } alt="Persona" width="40" height="40"/>
                                            </div>
                                            <div className="col-lg-11 col-10 d-flex flex-column">
                                                <small className="gris-oscuro mb-1">{moment(item.created_at).format('DD/MM/YYYY [-] hh:mm a')}</small>
                                                <div className="bg-gris-claro mb-3 px-3 py-2" style={{borderRadius: "22px"}}>
                                                    <span className="gris-oscuro">{item.content}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {me && me.username && me.username === item.sender.username && (
                                        <div className="row">
                                            <div className="col-lg-11 col-10 d-flex flex-column">
                                                <small className="gris-oscuro mb-1 text-right">{moment(item.created_at).format('DD/MM/YYYY [-] hh:mm a')}</small>
                                                <div className="bg-gris-claro mb-3 px-3 py-2 text-right" style={{borderRadius: "22px"}}>
                                                    <span className="gris-oscuro">{item.content}</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-2 d-flex justify-content-center align-items-center">
                                                <img src={item && item.sender && item.sender.avatar ? item.sender.avatar : persona_img } alt="Persona" width="40" height="40"/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        )}
                        {this.state.messages.length === 0 && (
                            <span className="gris-oscuro bold text-lg">Sin mensajes en el chat</span>
                        )}
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className='col-12 col-lg-10 px-0'>
                        <form onSubmit={this.sendMessageHandler}>
                            <div className="bg-gris-muy-claro d-flex justify-content-center py-3" style={{borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>
                                <div className="col-lg-10 col-12 px-0">
                                    <div className="row px-0 d-flex align-items-center">
                                        <div className="col-lg-11 col-10">
                                            <input
                                                placeholder="Escribe un mensaje..."
                                                type="text"
                                                className="form-control input-chat"
                                                onChange={this.messageChangeHandler}
                                                value={this.state.inputMessage}
                                                autoFocus={true}
                                            />
                                        </div>
                                        <div className="col-lg-1 col-2 px-0">
                                            <button type="submit" className="btn-chat d-flex justify-content-center align-items-center mr-3">
                                                <img src={arrow_img} alt="Persona" className="img-fluid"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center py-3 py-md-4">
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                        <Link className="btn btn-dark btn-block" to={`/proyecto/${this.props.match.params.id}`}>Regresar</Link>
                    </div>
                </div>
            </div>
        )
    }
}
