import React, { Component } from 'react';
import Card from "Utils/Cards/Card";
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderTransportista from "Utils/Grid/HeaderTransportista";
import Select, { Async } from "react-select";
import Slider, { Range } from 'rc-slider';
import classNames from 'classnames';
import { Pagination } from 'antd';
import CardProyecto from '../../../Utils/Cards/CardProyecto';
import { ESTADO_PROYECTO, ADJUDICADO_PENDIENTE_DE_INICIAR, USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, EN_CURSO, USER_TRANSPORTISTA } from '../../../../../utility/constants'
import { api } from "api";
import 'rc-slider/assets/index.css';

const getTransportistas = (search) => {
    return api.get("user", { search, tipo_usuario: USER_TRANSPORTISTA, is_active: true }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

export default class ProyectosEnCurso extends Component {

    componentWillMount() {
        const { listarProyectosEnCursoContratista } = this.props;
        listarProyectosEnCursoContratista();
    }

    handleChange(event) {
        event.persist();
        this.props.filtroContratistaTracking(event.target.value)
    }

    render() {
        const { filtroTransportistaContratista } = this.props; //Funciones
        const { data, loader, page, filtro_transportista, me } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="PROYECTOS EN CURSO">
                    <HeaderTransportista
                        filtro3={
                            <input
                                placeholder="Ingresa tu codigo de tracking"
                                type="text"
                                className={classNames('form-control')}
                                // value={filtro_transportista_tracking}
                                onChange={(e) => this.handleChange(e)}
                            />
                        }
                        filtroText3="Código de tracking"
                        filtro4={
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={filtro_transportista}
                                isSearchable={true}
                                loadOptions={getTransportistas}
                                getOptionValue={(option) => option["id"]}
                                getOptionLabel={(option) => option["nombre"]}
                                type="text"
                                onChange={(e) => filtroTransportistaContratista(e)}
                                multi={false}
                                autoload={false}
                                cache={false}
                                className={classNames(
                                    "react-select-container w-100"
                                )}
                                defaultOptions={true}
                                placeholder="Filtrar por transportista.."
                            />
                        }
                        filtroText4="Transportista"
                    />
                    <LoadMask loading={loader} blur>
                        <div className="row px-0">
                            {data && data.results && data.results.map((viaje, index) => {
                                return(
                                    <CardProyecto
                                        key={index}
                                        id={viaje && viaje.proyecto && viaje.proyecto.id ? viaje.proyecto.id : ""}
                                        nombre={viaje && viaje.proyecto && viaje.proyecto.nombre ? viaje.proyecto.nombre : ""}
                                        fecha_cierre={viaje && viaje.proyecto && viaje.proyecto.fecha_cierre_ofertas ? viaje.proyecto.fecha_cierre_ofertas : ""}
                                        origen={viaje && viaje.proyecto && viaje.proyecto.pais_origen && viaje.proyecto.ciudad_origen && viaje.proyecto.pais_origen.nombre && viaje.proyecto.ciudad_origen.nombre ? `${viaje.proyecto.ciudad_origen.nombre}, ${viaje.proyecto.pais_origen.nombre}` : ""}
                                        destino={viaje && viaje.proyecto && viaje.proyecto.pais_destino && viaje.proyecto.ciudad_destino && viaje.proyecto.pais_destino.nombre && viaje.proyecto.ciudad_destino.nombre ? `${viaje.proyecto.ciudad_destino.nombre}, ${viaje.proyecto.pais_destino.nombre}` : ""}
                                        tipo_transporte={viaje && viaje.proyecto && viaje.proyecto.tipo_vehiculo && viaje.proyecto.tipo_vehiculo.nombre ? viaje.proyecto.tipo_vehiculo.nombre : ""}
                                        adjudicado
                                        fecha_hora_inicio_viaje={viaje && viaje.proyecto && viaje.proyecto && viaje.proyecto.fecha_hora_inicio_viaje ? viaje.proyecto.fecha_hora_inicio_viaje : null}
                                        tipo={EN_CURSO}
                                        listado_proyectos_en_curso_contratista
                                        tipo_usuario={me && me.tipo_usuario ? me.tipo_usuario : USER_CONTRATISTA_EMPRESA}
                                        codigo_tracking={viaje && viaje.proyecto && viaje.proyecto.codigo_tracking ? viaje.proyecto.codigo_tracking : null}
                                    />
                                )
                            })}
                            {data && data.results && data.results.length === 0  && (
                                <div className="col-12 d-flex justify-content-center align-items-center mb-3" style={{height: "5rem"}}>
                                    <h2>Sin resultados</h2>
                                </div>
                            )}
                        </div>
                        {data && data.results && data.count > 0 && (
                            <div className="w-100 p-0 text-right mb-3">
                                <Pagination
                                    size="small"
                                    current={page}
                                    total={data && data.count ? data.count : 0}
                                    onChange={this.props.listarProyectosEnCursoContratista}
                                />
                            </div>
                        )}
                    </LoadMask>
                </Card>
            </div>
        )
    }
}
