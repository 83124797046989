import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicidades';
import Crear from './PublicidadCrear';

const mstp = state => {
    return {
        ...state.publicidades
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Crear)
