import React, { Component, Fragment } from "react";
import Card from "Utils/Cards/Card";
import CardOferta from "../../../Utils/Cards/CardOferta";
import LoadMask from "Utils/LoadMask/LoadMask";
import { Link } from "react-router-dom";
import moment from 'moment';
import { ESTADO_PROYECTO, EN_RECEPCION_OFERTAS } from '../../../../../utility/constants';
import { RenderDollar } from "../../../Utils/renderField/renderReadField";
import OfertaForm from './OfertaForm';
import CardGrandeProyecto from "../../../Utils/Cards/CardGrandeProyecto";


const editar_img = require('assets/img/icons/editar.svg');
const eliminar_img = require('assets/img/icons/borrar.svg');


export default class Detalle extends Component {
    state = {
        oferta: false,
    };

    componentWillMount() {
        this.props.leer(this.props.match.params.id, [EN_RECEPCION_OFERTAS]);
        this.props.ofertasProyecto(this.props.match.params.id);
    }

    toggleOferta = () => {
        this.setState({ oferta: !this.state.oferta });
    }

    crearOferta = (data) => {
        const body = { ...data };
        body.proyecto = this.props.match.params.id;
        this.props.crearOferta(body);
        this.setState({ oferta: false });
    }

    render() {
        const { item, loader, me, crearOferta, ofertas_proyectos } = this.props;
        return (
            <div className="mt-4 col-12">
                <Card titulo="DETALLE DEL PROYECTO">
                    <LoadMask loading={loader} blur>
                        <div className="px-md-5 py-md-4">
                            <CardGrandeProyecto
                                nombre={item && item.nombre ? item.nombre : ""}
                                direccion_origen={item && item.direccion_origen ? item.direccion_origen : ""}
                                ciudad_origen={item && item.ciudad_origen && item.ciudad_origen.nombre ? item.ciudad_origen.nombre : ""}
                                pais_origen={item && item.pais_origen && item.pais_origen.nombre ? item.pais_origen.nombre : ""}
                                direccion_destino={item && item.direccion_destino ? item.direccion_destino : ""}
                                ciudad_destino={item && item.ciudad_destino && item.ciudad_destino.nombre ? item.ciudad_destino.nombre : ""}
                                pais_destino={item && item.pais_destino && item.pais_destino.nombre ? item.pais_destino.nombre : ""}
                                tipo_vehiculo={item && item.tipo_vehiculo && item.tipo_vehiculo.nombre ? item.tipo_vehiculo.nombre : ""}
                                presupuesto={item && item.presupuesto ? item.presupuesto : 0}
                                saldo_a_favor={item && item.saldo_a_favor ? item.saldo_a_favor : 0}
                                dia_entrega={item && item.dia_entrega ? item.dia_entrega : ""}
                                fecha_cierre_ofertas={item && item.fecha_cierre_ofertas ? item.fecha_cierre_ofertas : ""}
                                fecha_hora_posicionamiento={item && item.fecha_hora_posicionamiento ? item.fecha_hora_posicionamiento : ""}
                                descripcion_trabajo={item && item.descripcion_trabajo ? item.descripcion_trabajo : ""}
                                notas={item && item.notas ? item.notas : ""}
                                justificacion_cancelacion={item && item.justificacion_cancelacion ? item.justificacion_cancelacion : ""}
                                estado_proyecto={item && item.estado_proyecto ? item.estado_proyecto : 0}
                                codigo_tracking={item && item.codigo_tracking ? item.codigo_tracking : null}
                                total_ofertas={item && item.total_ofertas ? item.total_ofertas : 0}
                                tipo_usuario={me && me.tipo_usuario}
                                hijo_abajo
                            >

                                <div className="col-12">
                                    <div className="row">
                                        {ofertas_proyectos && ofertas_proyectos.length < 3 && (
                                            <div className="col-12 px-0 mb-1 text-center">
                                                <button type="button" onClick={() => this.toggleOferta()} className="btn btn-naranja">HACER OFERTA</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </CardGrandeProyecto>
                            <div className="mt-5" />
                            {this.state.oferta === true && (
                                <Fragment>
                                    <h5 className="mb-1 bold">Hacer oferta</h5>
                                    <OfertaForm
                                        onSubmit={this.crearOferta}
                                        presupuesto={item.presupuesto ? item.presupuesto : 0}
                                        {...this.props}
                                    />
                                </Fragment>
                            )}
                            {ofertas_proyectos && ofertas_proyectos.map((proyecto, index) => {
                                return (
                                    <CardOferta
                                        key={proyecto.id}
                                        id={proyecto.id ? proyecto.id : ""}
                                        descripcion={proyecto.descripcion ? proyecto.descripcion : ""}
                                        presupuesto={proyecto.presupuesto_oferta ? proyecto.presupuesto_oferta : 0}
                                        creado={proyecto.creado ? proyecto.creado : moment()}
                                        {...this.props}
                                    />
                                )
                            })}
                            <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                                <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                                    <Link className="btn btn-dark btn-block" to="/proyectos-transportistas">Regresar</Link>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </Card >
            </div >
        );
    }
}
