import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';

import { SWALMOD } from '../../../../utility/constants'

const editar_img = require('assets/img/icons/editar.svg');
const eliminar_img = require('assets/img/icons/borrar.svg');
const detalle_img = require('assets/img/icons/detalle.svg');
const editar_hover_img = require('assets/img/icons/editar_hover.svg');
const eliminar_hover_img = require('assets/img/icons/borrar_hover.svg');
const detalle_hover_img = require('assets/img/icons/detalle_hover.svg');

const alerta_img = require('assets/img/icons/alert.svg');
class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        editar: false,
        eliminar: false,
        detalle: false,
    };

    eliminar = (id) => {
        return () => {
            SWALMOD.fire({
                title: '¿Eliminar?',
                text: '¡No podrás revertir esta acción!',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                imageUrl: alerta_img,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    };

    render() {
        const { id, ver, editar, eliminar } = this.props;
        return (
            <div className="d-flex justify-content-center">
                {ver !== undefined && (
                    <Link to={`${ver}/${id}/`} className="px-2">
                        <img onMouseOver={() => this.setState({ detalle: !this.state.detalle })} onMouseOut={() => this.setState({ detalle: !this.state.detalle })} src={this.state.detalle ? detalle_hover_img : detalle_img} alt="Ver" className="action_img" />
                    </Link>
                )}
                {editar !== undefined && (
                    <Link
                        className="text-warning"
                        to={`${editar}/${id}/editar`}
                    >
                        <img onMouseOver={() => this.setState({ editar: !this.state.editar })} onMouseOut={() => this.setState({ editar: !this.state.editar })} src={this.state.editar ? editar_hover_img : editar_img} alt="Editar" className="action_img" />
                    </Link>
                )}
                {eliminar !== undefined && (
                    <a
                        className="px-2"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.eliminar(id)}
                    >
                        <img onMouseOver={() => this.setState({ eliminar: !this.state.eliminar })} onMouseOut={() => this.setState({ eliminar: !this.state.eliminar })} src={this.state.eliminar ? eliminar_hover_img : eliminar_img} alt="Eliminar" className="action_img" />
                    </a>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {};

export function standardActions(acciones) {
    return (cell, row) => {
        return <Acciones id={cell} {...acciones} />;
    };
}
