import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { USER_TRANSPORTISTA } from '../../../../utility/constants';
import { renderField } from '../../Utils/renderField';
import { renderFilePickerPequeno } from '../../Utils/renderField/renderField';
import { renderNumber } from '../../../../../../../frontend_admin/src/js/common/components/Utils/renderField/renderField';
import { validationTransportista } from '../../../../utility/validationForm'
const eliminar_img = require('assets/img/icons/borrar_hover.svg');


const renderPolizas = ({ fields, meta: { error, submitFailed }, contactos, setPoilizaImgs, eliminarImgsPoliza }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <div className="col-12 form-group m-auto px-0 px-sm-0 pt-2">
            <div className="row my-n2">
                <div className="react-bs-container-header" style={{ height: 25 }} >
                    <div className="d-none d-md-flex row border-publicidad py-1 px-0 px-sm-1 px-lg-1 px-xl-1">
                        <div className="col-1 mb-2 d-none d-md-flex justify-content-center align-items-center">
                        </div>
                        <div className="col-md-4 mb-2 px-1">
                            <label htmlFor="numero de poliza" className="ml-2 blanco d-block">NO. DE POLIZA</label>
                        </div>
                        <div className="col-md-4 mb-2 px-1">
                            <label htmlFor="empresa" className="blanco d-block">EMPRESA</label>
                        </div>
                        <div className="col-md-3 mb-2 px-1">
                            <label htmlFor="imagen del seguro" className="blanco d-block">IMAGEN</label>
                        </div>
                    </div>
                </div>
            </div>
            {fields.map((poliza, index) => {
                const polizaActual = fields.get(index)
                return (
                    <div className="row border-publicidad py-3 px-0 px-sm-1 flex-md-row flex-column" key={index}>
                        <div className="order-1 order-md-0 mx-auto px-1">
                            {fields.length > 1 &&
                                <img src={eliminar_img} alt="Eliminar"
                                    className="action_img"
                                    onClick={() => {
                                        fields.remove(index)
                                        eliminarImgsPoliza(index)
                                    }}
                                />
                            }
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="numero de poliza" className="gris-oscuro d-block d-md-none">NO. DE POLIZA</label>
                            <Field
                                name={`${poliza}.numero`}
                                type="text"
                                component={renderField}
                                maxLength={validationTransportista.polizaNumero.MaxLength}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="empresa" className="gris-oscuro d-block d-md-none">EMPRESA</label>
                            <Field
                                name={`${poliza}.nombre`}
                                type="text"
                                component={renderField}
                                maxLength={validationTransportista.polizaNombre.MaxLength}
                            />                            </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="imagen del seguro" className="gris-oscuro d-block d-md-none">IMAGEN</label>
                            <Field
                                name={`${poliza}.imagen`}
                                photo={polizaActual.imagen ? polizaActual.imagen : null}
                                setFile={(file, toBase64) => setPoilizaImgs(index, file, toBase64)}
                                component={renderFilePickerPequeno}
                            />
                        </div>
                    </div>
                )
            })}
            <div className="d-flex justify-content-center mt-3">
                <button type="button" className="btn btn-secondary py-1" onClick={() => {
                    fields.push({})
                }}>
                    AGREGAR
                </button>
            </div>
        </div>
    )
}



class RegisterForm extends Component {

    render() {
        const { handleSubmit, setRtuImg, setPatenteImg, setFianzaImg, previousStep, setPoilizaImgs, eliminarImgsPoliza, fianza, rtu_img, patente_img } = this.props;
        return (
            // <form name="loginForm" className="form-validate d-flex flex-sm-row align-items-center" onSubmit={handleSubmit}>
            <form name="loginForm" onSubmit={handleSubmit}>

                <div className="row justify-content-center mt-5 mb-3 ">

                    <div className="col-12 col-md-5 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="nit">NIT Facturación*</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field
                                name="nit"
                                component={renderField}
                                type="text"
                                className="form-control"
                                maxLength={validationTransportista.nit.MaxLength}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-5 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="fianza">Fianza (jpg o pdf)</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field photo={fianza ? fianza : null} setFile={setFianzaImg} name="fianza" component={renderFilePickerPequeno} />
                        </div>
                    </div>

                    <div className="col-12 col-md-5 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="rtu">Constancia RTU*</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field name="rtu" component={renderField} type="text" className="form-control" maxLength={validationTransportista.rtu.MaxLength} />
                        </div>
                    </div>

                    <div className="col-12 col-md-5 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="rtu_img">Constancia RTU (jpg o pdf)</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field photo={rtu_img ? rtu_img : null} setFile={setRtuImg} name="rtu_img" component={renderFilePickerPequeno} />
                        </div>
                    </div>

                    <div className="col-12 col-md-5 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="patente_img">Patente (jpg o pdf)*</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field photo={patente_img ? patente_img : null} setFile={setPatenteImg} name="patente_img" component={renderFilePickerPequeno} />
                        </div>
                    </div>
                    <div className="col-12 col-md-5 mb-2 mb-sm-3 d-flex align-items-end">
                        <div className="col-md-12 col-sm-12 text-right">
                            <span className="azul bold text-xsm">* Campos requeridos</span>
                        </div>
                    </div>

                </div>

                <div className="row justify-content-center px-0">
                    <div className="col-md-10">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="polizas" className="m-0">Pólizas de seguro*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <FieldArray name="polizas" setPoilizaImgs={setPoilizaImgs} eliminarImgsPoliza={eliminarImgsPoliza} component={renderPolizas} />
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center ">
                    <div className="col-xl-8 col-lg-9 col-md-10 col-sm-10 col-12 d-flex flex-column">

                        <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                            <div className="col-md-5 col-12">
                                <button type="button" className="btn btn-dark btn-block" onClick={previousStep}>Anterior</button>
                            </div>
                            <div className="col-md-5 col-12 mt-md-0 mt-3">
                                <button type="submit" className="btn btn-primary btn-block">Siguiente</button>
                            </div>
                        </div>

                    </div>
                </div>

            </form>
        );
    };
}


const validatePrimary = (values) => {
    let errors = {};
    let DetalleArrayErrors = [];
    if (values && values.polizas && values.polizas.length) {
        values.polizas.forEach((detalle, detalleIndex) => {
            DetalleArrayErrors[detalleIndex] = validate(detalle, {
                nombre: validationTransportista.polizaNombre.Combine,
                numero: validationTransportista.polizaNumero.Combine,
            })
        });
    }
    errors.polizas = DetalleArrayErrors

    errors = {
        ...errors,
        ...validate(values, {
            nit: validationTransportista.nit.Combine,
            patente_img: validationTransportista.patente_img.Combine,
            rtu: validationTransportista.rtu.Combine,
        })
    }
    return errors

};

RegisterForm = reduxForm({
    form: 'register', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: {
        tipo_usuario: USER_TRANSPORTISTA
    },
    validate: validatePrimary
})(RegisterForm);


RegisterForm = connect(state => {
    const form = state.form.register;
    let fianza = "";
    let rtu_img = "";
    let patente_img = "";

    if (form && form.values) {
        const values = form.values;

        if (values.fianza) {
            fianza = values.fianza
        }
        if (values.rtu_img) {
            rtu_img = values.rtu_img
        }
        if (values.patente_img) {
            patente_img = values.patente_img
        }
    }
    return {
        fianza,
        rtu_img,
        patente_img,
    }
})(RegisterForm);


export default RegisterForm;
