import { connect } from 'react-redux';
import HomeContainer from './HomeContainer';


const ms2p = (state) => {
    return {
        me: state.login.me,
    };
};

const md2p = {};

export default connect(ms2p, md2p)(HomeContainer);
