import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoadMask from "../../Utils/LoadMask/LoadMask";

import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';
import PasoTresForm from './PasoTresForm';
import "react-responsive-modal/styles.css";
import { USER_TRANSPORTISTA } from '../../../../utility/constants';
import './index.css';

import { Modal } from "react-responsive-modal";

import ModalTerminosRegistro from '../../Utils/Modales/ModalTerminosRegistro';
import { relativeTimeThreshold } from 'moment';

class Registro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            rtu_img: null,
            patente_img: null,
            fianza: null,
            modalTerminos: false,


            img_poliza_file_img: [],
            img_poliza_base64: []
        };
    }

    setRtuImg = (rtu_img) => {
        this.setState({ rtu_img });
    };
    setPatenteImg = (patente_img) => {
        this.setState({ patente_img });
    };
    setFianzaImg = (fianza) => {
        this.setState({ fianza });
    };

    setPoilizaImgs = (key, poliza_img, toBase64) => {
        const img_poliza_file_img = this.state.img_poliza_file_img;
        const img_poliza_base64 = this.state.img_poliza_base64;

        for (let index = 0; index < key + 1; index++) {
            if (key == index) {
                img_poliza_file_img[index] = poliza_img
                img_poliza_base64[index] = toBase64
            } else {
                if (!img_poliza_file_img[index]) {
                    img_poliza_file_img[index] = "x"
                    img_poliza_base64[index] = "x"
                }
            }
        }
        this.setState({ img_poliza_file_img });
        this.props.setPolizas(img_poliza_base64);
    }

    eliminarImgsPoliza = (index) => {
        let img_poliza_file_img = this.state.img_poliza_file_img;
        img_poliza_file_img = _.filter(img_poliza_file_img, (item, i) => i != index);
        // Seteamos el state
        this.setState({ img_poliza_file_img });
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };


    previousStep = () => {
        this.setState({ step: this.state.step - 1 })
    };

    crear = (data) => {
        let datos = { ...data }
        datos.ciudad_residencia = data.ciudad_residencia.id
        datos.pais_residencia = data.pais_residencia.id
        datos.tipo_usuario = USER_TRANSPORTISTA
        const docs = [
            { "file": this.state.rtu_img, "name": "rtu_img" },
            { "file": this.state.patente_img, "name": "patente_img" },
            { "file": this.state.fianza, "name": "fianza" },
        ]
        for (let i = 0; i < datos.polizas.length; i++) {
            datos.polizas[i].imagen = ""
            if (this.state.img_poliza_file_img[i] != "x") {
                docs.push({ "file": this.state.img_poliza_file_img[i], "name": `poliza_${i}` })
            }

        }
        this.props.onSubmit({ ...datos, rtu_img: null, patente_img: null, fianza: null }, docs)

    }
    closeModal = () => {
        this.setState({ modalTerminos: false })
    }
    openModal = () => {
        this.setState({ modalTerminos: true })
    }


    render() {
        const { clearSelectPais, loader } = this.props;
        const { step, modalTerminos } = this.state;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="blue-gradient-bg">
                <ModalTerminosRegistro
                    onClose={this.closeModal}
                    open={modalTerminos}
                />
                <div className="contenedor-principal-form">
                    <div className="col-12 h-100 d-flex justify-content-center align-items-center flex-wrap">
                        <div className="col-12 login-wrapper d-flex flex-column">

                            <div className="card card-login col-xl-6 col-lg-8 col-md-9 col-12 my-5">
                                <LoadMask loading={loader} light>
                                    <div className="form-validate d-flex flex-sm-row align-items-center">
                                        <div className="col-12 d-flex flex-column justify-content-center px-0">
                                            <div className="row justify-content-center ">
                                                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-7 col-8 mb-3 text-center">
                                                    <img src={require('assets/img/logo_completo.svg')} className="img-fluid" alt="Logo" />
                                                </div>

                                                <div className="col-12 mb-4">
                                                    <h5 className="gris-oscuro text-center bold" >TRANSPORTISTA</h5>
                                                </div>


                                                <div className="col-12 text-center ">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div className="mx-3 circulo" />
                                                        <div className={`mx-3 circulo ${step < 2 && "bg-gris-claro"}`} />
                                                        <div className={`mx-3 circulo ${step < 3 && "bg-gris-claro"}`} />
                                                    </div>
                                                </div>
                                            </div>

                                            {step === 1 && (
                                                <PasoUnoForm
                                                    onSubmit={this.nextStep}
                                                    clearSelectPais={clearSelectPais}
                                                />
                                            )}

                                            {step === 2 && (
                                                <PasoDosForm
                                                    onSubmit={this.nextStep}
                                                    previousStep={this.previousStep}
                                                    setRtuImg={this.setRtuImg}
                                                    setPatenteImg={this.setPatenteImg}
                                                    setFianzaImg={this.setFianzaImg}
                                                    setPoilizaImgs={this.setPoilizaImgs}
                                                    eliminarImgsPoliza={this.eliminarImgsPoliza}

                                                />
                                            )}

                                            {step === 3 && (
                                                <PasoTresForm
                                                    onSubmit={this.crear}
                                                    previousStep={this.previousStep}
                                                    openModal={this.openModal}
                                                />
                                            )}
                                        </div>
                                    </div>

                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Registro;
