import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
// import './change.css';
import LoadMask from "Utils/LoadMask/LoadMask";
const alerta_img = require('assets/img/icons/alert.svg');
import {Link} from 'react-router-dom';

import { default as RegistroTrasportista } from "../RegisterTrasportista";
import { default as Registro } from "../Register";

class RegistroOpcion extends Component {
    constructor(props) {
        super(props);
        this.state = {
          opcion: 0
        };
      }
    render() {
        if (this.state.opcion == 1) {
        return(<Registro/>)
        }
        if (this.state.opcion == 2) {
            return(<RegistroTrasportista/>)
        }

        return (
            <div className="blue-gradient-bg">
                <div className="contenedor-principal">
                    <div className="col-12 h-100 d-flex justify-content-center align-items-center flex-wrap">
                        <div className="col-12 login-wrapper d-flex flex-column">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-11 mb-3 text-center">
                                <img src={require('assets/img/logo_completo.svg')} className="img-fluid" alt="Logo"/>
                            </div>
                            <div className="card card-login col-xl-5 col-lg-7 col-md-7 col-11 mb-5 px-0 px-md-5">
                                <div className="col-12">
                                    <div className=" d-flex justify-content-center flex-column align-items-center mb-5">
                                        <img src={alerta_img} alt="Alerta" width={50} className="img-fluid  blink-danger"/>
                                        <span className="gris-oscuro bold mt-1" style={{fontSize: "18px"}}>Selecciona un tipo de usuario</span>
                                        <span className="azul bold mt-0 "style={{fontSize: "11px"}}>Selecciona para continuar con el registro.</span>
                                    </div>
                                    <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                                        <div className="col-12 mt-md-0 mt-3 login mb-4">
                                            <button type="button" className="btn btn-primary btn-block" onClick={() => this.setState({ opcion: 1 })}>CONTRATISTA</button>
                                        </div>
                                        <div className="col-12 mt-md-0 mt-3 login mb-4">
                                            <button type="button" className="btn btn-secundary btn-block " onClick={() => this.setState({ opcion: 2 })}>TRANSPORTISTA</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegistroOpcion;
