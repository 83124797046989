import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import PilotoInfoChangePass from './PilotoInfoChangePass';
import {actions} from "../../../../redux/modules/cuenta/login";

const ms2p = state => ({
    ...state.login,
});
const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(PilotoInfoChangePass);
