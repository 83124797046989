import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import { getMe } from '../cuenta/login';
import _ from "lodash";
import {reset} from 'redux-form';

const LOADER = 'LOADER_SUSCRIPCION';
const ITEM_DATA = 'ITEM_SUSCRIPCION';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});
const setItem = item => ({
    type: ITEM_DATA,
    item,
});

// ------------------------------------
// Actions
// ------------------------------------

const leer = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('config_global').then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
        dispatch(push("/"));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const pagar = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('suscripcion', data).then(() => {
        dispatch(getMe());
        NotificationManager.success('El pago fue exitoso', 'Éxito', 3000);
        dispatch(push(`/`));
    }).catch((error) => {
        let mensaje = 'Error al pagar la suscripción, vuelva a intentar';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 4000);

    }).finally(() => {
        dispatch(setLoader(false));
    });
}



export const actions = {
    pagar,
    leer,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};

export const initialState = {
    loader: false,
    item: {},
};

export default handleActions(reducers, initialState);
