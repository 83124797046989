import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { api } from "api";
import { Link } from 'react-router-dom';
import { validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { RenderDollar, RenderQuetzal } from '../../../Utils/renderField/renderReadField';
import { renderField, renderSwitch, SelectField, renderCurrency, AsyncSelectField2, renderTextArea, renderDatePicker } from '../../../Utils/renderField/renderField';
import moment from 'moment';
import ModalSolicitudTrayecto from '../../../Utils/Modales/ModalSolicitarTrayecto';
const alerta_img = require('assets/img/icons/alert.svg');

const getPaisOrigen = (search) => {
    return api.get("pais/listar_pais", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getPaisDestino = (search) => {
    return api.get("pais/listar_pais", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getVehiculo = (search) => {
    return api.get("tipo_vehiculo", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

class ProyectoForm extends Component {
    state = {
        CiudadOrigen: [],
        CiudadDestino: [],
        openModal: false,
        confGlobal: {},
    }
    getCiudadOrigen = (search) => {
        const { pais_origen } = this.props;
        const params = { search }

        if (pais_origen.id) {
            params.pais = pais_origen.id
        }

        return api.get("ciudad", params).then((data) => {
            if (data) {
                this.setState({ CiudadOrigen: data.results });
                return data.results
            };
            return [];
        }).catch(() => {
            return [];
        });
    };
    getCiudadDestino = (search) => {
        const { pais_destino } = this.props;
        const params = { search }

        if (pais_destino.id) {
            params.pais = pais_destino.id
        }

        return api.get("ciudad", params).then((data) => {
            if (data) {
                this.setState({ CiudadDestino: data.results });
                return data.results
            };
            return [];
        }).catch(() => {
            return [];
        });
    };
    componentDidUpdate(prevProps) {
        if (this.props.pais_origen !== prevProps.pais_origen) {
            this.getCiudadOrigen('');
        }
        if (this.props.pais_destino !== prevProps.pais_destino) {
            this.getCiudadDestino('');
        }
    }
    componentDidMount() {
        api.get("proyecto/get_confGlobal").then((data) => {
            if (data) this.setState({ confGlobal: data.confGlobal });
            })
    }
    render() {
        const { handleSubmit, editando, item, clearSelectPais, destino_encontrado, error_rango_precio } = this.props;
        const RenderDollarAndQuetzal = ({ titulo, monto, tasa_cambio, grande = false }) => {
            titulo = titulo ? titulo : ""
            monto = monto ? monto : 0
            tasa_cambio = tasa_cambio ? tasa_cambio : 0
            return (
                <div className="col-md-6 mb-2">
                    {titulo &&
                        <Fragment>
                            <label className="m-0">{titulo}</label>
                            <br />
                        </Fragment>
                    }
                    <div className="d-flex flex-wrap bg-white">
                        <RenderDollar className={`flex-1 border bold naranja pl-1 ${grande ? "text-md" : "text-sm"}`} value={monto} />
                        <RenderQuetzal className={`flex-1 border bold text-right azul pl-1 ${grande ? "text-md" : "text-sm"}`} value={monto * tasa_cambio} />
                    </div>
                </div>
            )
        }
        const DesgloseDeCobro = ({ costo_proyecto }) => {
            const confGlobal = this.state.confGlobal
            let viajeLargo = this.props.pais_origen.id !== this.props.pais_destino.id ? true : 
                                this.props.ciudad_origen.id !== this.props.ciudad_destino.id ? true : false
            const porcentaje_comision = viajeLargo === true ? confGlobal.porcentaje_viajes_largos : 
                                        confGlobal.porcentaje_viajes_cortos
            costo_proyecto = Number(costo_proyecto ? costo_proyecto : 0)
            const costo_comision = costo_proyecto * (porcentaje_comision / 100)
            const costo_iva = (costo_proyecto + costo_comision) * 0.12
            const monto_costo_viaje = costo_proyecto + costo_comision + costo_iva
            const tasa_cambio = confGlobal.tasa_cambio
            return (
                <div className="row col-md-12 p-0 mx-0 border-publicidad py-3 bg-gris-muy-claro">
                    <RenderDollarAndQuetzal titulo='Costo del proyecto' monto={costo_proyecto} tasa_cambio={tasa_cambio} />
                    <RenderDollarAndQuetzal titulo='Costo por servicio' monto={costo_comision} tasa_cambio={tasa_cambio} />
                    <RenderDollarAndQuetzal titulo='Costo del IVA' monto={costo_iva} tasa_cambio={tasa_cambio} />
                    <RenderDollarAndQuetzal grande titulo='COSTO TOTAL' monto={monto_costo_viaje} tasa_cambio={tasa_cambio} />
                </div>
            )
        }
        return (
            <Fragment>
                <ModalSolicitudTrayecto
                    onClose={() => this.setState({ openModal: false })}
                    open={this.state.openModal}
                />
                <form onSubmit={handleSubmit}>
                    <div className="form-group grid-container col-12 px-0 px-sm-4">
                        <div className="row col-md-12 p-0">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Nombre del proyecto*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="nombre" component={renderField} type="text" className="form-control" maxLength="151" />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="tipo_vehiculo" className="m-0">Tipo de Vehículo*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="tipo_vehiculo"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField2}
                                        loadOptions={getVehiculo}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row  col-md-12 p-0">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="pais_origen" className="m-0">Pais Origen*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="pais_origen"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField2}
                                        loadOptions={getPaisOrigen}
                                        onCambio={() => clearSelectPais("ciudad_origen")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="ciudad_origen" className="m-0">Ciudad Origen*</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        valueKey="id"
                                        name="ciudad_origen"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField2}
                                        loadOptions={this.getCiudadOrigen}
                                        defaultOptions={this.state.CiudadOrigen}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 p-0">
                            <div className="col-md-12 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="direccion_origen" className="m-0">Dirección de origen*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="direccion_origen" component={renderField} type="text" className="form-control" maxLength="151" />
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 p-0">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="pais_destino" className="m-0">Pais Destino*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="pais_destino"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField2}
                                        loadOptions={getPaisDestino}
                                        onCambio={() => clearSelectPais("ciudad_destino")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="ciudad_destino" className="m-0">Ciudad Destino*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="ciudad_destino"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField2}
                                        loadOptions={this.getCiudadDestino}
                                        defaultOptions={this.state.CiudadDestino}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 p-0">
                            <div className="col-md-12 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="direccion_destino" className="m-0">Dirección de destino*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="direccion_destino" component={renderField} type="text" className="form-control" maxLength="151" />
                                </div>
                            </div>
                        </div>
                        {destino_encontrado ?
                            <Fragment>
                                <div className="row col-md-12 p-0">
                                    <div className="col-md-12 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="descripcion_trabajo" className="m-0">Descripción del trabajo*</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="descripcion_trabajo" component={renderTextArea} rows={3} type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-md-12 p-0">
                                    <div className="col-md-12 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="notas" className="m-0">Notas adicionales</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="notas" component={renderTextArea} rows={3} type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                {error_rango_precio &&
                                    <div className="row col-md-12 p-3">
                                        <div className="d-flex flex-column flex-sm-row align-items-center">
                                            <img src={alerta_img} alt="Alerta" width={25} height={20} className="mr-2  blink-danger" />
                                            <span className="bold rojo">{error_rango_precio}</span>
                                        </div>
                                    </div>
                                }
                                <div className="row col-md-12 p-0">
                                    <div className="col-md-6 p-0 align-self-end">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="presupuesto" className="m-0">Presupuesto*</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="presupuesto" component={renderCurrency} prefix="$ " type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="fecha_cierre_ofertas" className="m-0">Fecha de cierre de ofertas*</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="fecha_cierre_ofertas" component={renderDatePicker} minDate={moment()} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-md-12 p-0">
                                    <div className="col-md-6 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="fecha_hora_posicionamiento" className="m-0">Fecha y hora de posicionamiento*</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="fecha_hora_posicionamiento" component={renderDatePicker} showTimeSelect={true} minDate={moment()} dateFormat="DD/MM/YYYY HH:mm" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 p-0 align-self-end">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="dia_entrega" className="m-0">Fecha de entrega*</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="dia_entrega" component={renderDatePicker} minDate={moment()} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-md-12 p-0">
                                    <DesgloseDeCobro
                                        costo_proyecto={this.props.presupuesto}
                                    />
                                </div>
                            </Fragment>
                            : <div className="col-12">
                                <span className="naranja bold text-sm image-puntero" onClick={() => this.setState({ openModal: true })}>¿No encuentras el origen y destino deseado? </span>
                            </div>
                        }

                        {editando && item && item.saldo_a_favor > 0 &&
                            <div className="row">
                                <span className="naranja bold text-sm">Tu saldo a favor es <RenderDollar value={item.saldo_a_favor} className="naranja bold text-sm" />  </span>
                            </div>
                        }

                        <div className="col-12 text-right">
                            <span className="azul bold text-xsm">* Campos requeridos</span>
                        </div>

                        <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                            <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                                <Link className="btn btn-dark btn-block" to="/proyectos">Cancelar</Link>
                            </div>
                            <div className="col-md-5 col-lg-5 col-xl-4 col-12 mt-md-0 mt-3">
                                <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </Fragment>
        )

    }
}

const validate = (values) => {
    const errors = {};
    if (!values.nombre) {
        errors.nombre = "Campo requerido";
    }
    if (values.nombre && values.nombre.length > 150) {
        errors.nombre = "Máximo 150 carácteres"
    }
    if (!values.tipo_vehiculo) {
        errors.tipo_vehiculo = "Campo requerido";
    }
    if (!values.pais_origen) {
        errors.pais_origen = "Campo requerido";
    }
    if (!values.pais_destino) {
        errors.pais_destino = "Campo requerido";
    }
    if (!values.direccion_origen) {
        errors.direccion_origen = "Campo requerido";
    }
    if (values.direccion_origen && values.direccion_origen.length > 150) {
        errors.direccion_origen = "Máximo 150 carácteres"
    }
    if (!values.ciudad_origen) {
        errors.ciudad_origen = "Campo requerido";
    }
    if (!values.ciudad_destino) {
        errors.ciudad_destino = "Campo requerido";
    }
    if (!values.direccion_destino) {
        errors.direccion_destino = "Campo requerido";
    }
    if (values.direccion_destino && values.direccion_destino.length > 150) {
        errors.direccion_destino = "Máximo 150 carácteres"
    }
    if (!values.descripcion_trabajo) {
        errors.descripcion_trabajo = "Campo requerido";
    }
    if (!values.presupuesto) {
        errors.presupuesto = "Campo requerido";
    }
    if (values.presupuesto < 0) {
        errors.presupuesto = "El Campo no puede ser negativo";
    }
    if (!values.fecha_cierre_ofertas) {
        errors.fecha_cierre_ofertas = "Campo requerido";
    }
    if (!values.fecha_hora_posicionamiento) {
        errors.fecha_hora_posicionamiento = "Campo requerido";
    }
    if (!values.dia_entrega) {
        errors.dia_entrega = "Campo requerido";
    }

    var fecha_cierre_ofertas = moment(values.fecha_cierre_ofertas && values.fecha_cierre_ofertas).format('YYYY,MM,DD')
    fecha_cierre_ofertas = new Date(fecha_cierre_ofertas)

    var fecha_hora_posicionamiento = moment(values.fecha_hora_posicionamiento && values.fecha_hora_posicionamiento).format('YYYY,MM,DD')
    fecha_hora_posicionamiento = new Date(fecha_hora_posicionamiento)

    var dia_entrega = moment(values.dia_entrega && values.dia_entrega).format('YYYY,MM,DD')
    dia_entrega = new Date(dia_entrega)

    // console.log("fecha_cierre_ofertas>",fecha_cierre_ofertas)
    // console.log("fecha_hora_posicionamiento>", fecha_hora_posicionamiento)
    // console.log("dia_entrega>", dia_entrega)

    if (dia_entrega < fecha_hora_posicionamiento) {
        errors.dia_entrega = "La fecha de entrega tiene que ser mayor que el de posicionamiento o igual";
    }
    if (fecha_hora_posicionamiento < fecha_cierre_ofertas) {
        errors.fecha_hora_posicionamiento = "La fecha de posicionamiento tiene que ser mayor que el de cierre de ofertas o igual";
    }

    return errors;
};

ProyectoForm = reduxForm({
    form: 'ProyectoForm',
    validate
})(ProyectoForm);

const selector = formValueSelector('ProyectoForm')
ProyectoForm = connect(state => {
    const form = state.form.ProyectoForm;
    const presupuesto = selector(state, 'presupuesto')
    let pais_origen = "";
    let pais_destino = "";
    let ciudad_origen = "";
    let ciudad_destino = "";
    let destino_encontrado = false;

    if (form && form.values) {
        const values = form.values;

        if (values.pais_origen) {
            pais_origen = values.pais_origen
        }
        if (values.pais_destino) {
            pais_destino = values.pais_destino
        }

        if (values.pais_origen && values.pais_destino && values.ciudad_origen && values.ciudad_destino) {
            ciudad_origen = values.ciudad_origen
            ciudad_destino = values.ciudad_destino
            destino_encontrado = true;
        } else {
            destino_encontrado = false;
        }

    }
    return {
        presupuesto,
        pais_origen,
        pais_destino,
        ciudad_origen,
        ciudad_destino,
        destino_encontrado,
    }
})(ProyectoForm);

export default ProyectoForm;
