import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import { EN_EDICION, EN_REVISION, VALIDADA, RECHAZADA } from "../../../../../utility/constants"


const EstadoPublicidad = ({ value }) => {
    return (
        <div>
            <span className={`bold ${value == EN_EDICION ? "gris-oscuro" : value == EN_REVISION ? "azul" : value == VALIDADA ? "verde" : "rojo"}`}>
                {`${value == EN_EDICION ? "En edición" : value == EN_REVISION ? "En revisión" : value == VALIDADA ? "Validada" : "Rechazada"}`}
            </span>
        </div>
    )
}


export default class ListarPublicidades extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }


    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <Card
                    titulo="PUBLICIDAD">
                    <Header to="publicidad/crear" textBtn="Crear publicidad" />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} page={page} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={(cell, row) => {
                                return row.estado_publicidad == EN_REVISION || row.estado_publicidad == VALIDADA ?
                                    (standardActions({ ver: "publicidad" }))(cell, row)
                                    :
                                    (standardActions({ editar: "publicidad", eliminar, ver: "publicidad" }))(cell, row)
                            }}
                        />
                        <TableHeaderColumn
                            dataField="estado_publicidad"
                            dataSort
                            dataFormat={(cell, row) => cell ? <EstadoPublicidad value={cell} /> : '---'}

                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
