import React, { Component, Fragment } from "react";
import Card from "Utils/Cards/Card";
import CardOferta from "../../../Utils/Cards/CardOferta";
import LoadMask from "Utils/LoadMask/LoadMask";
import { Link } from "react-router-dom";
import moment from 'moment';
import { ESTADO_PROYECTO, EN_CURSO, EN_RECEPCION_OFERTAS, FINALIZADO, ADJUDICADO_PENDIENTE_DE_INICIAR } from '../../../../../utility/constants';
import { RenderDollar } from "../../../Utils/renderField/renderReadField";
import GoogleMap from 'google-map-react';

const pin_amarillo_img = require('assets/img/icons/pin_amarillo.svg');
const pin_morado_img = require('assets/img/icons/pin_morado.svg');
const pin_rojo_img = require('assets/img/icons/pin_rojo.svg');

const InformacionGPS = (props) => {
    const { lat, lng, numero, total_points, creado } = props;
    return (
        <div className="d-flex flex-column google-maps-marker">
            <img src={numero === 0 ? pin_rojo_img : numero === total_points ? pin_amarillo_img : pin_morado_img} alt="Marcador" width={18} height={20} className={numero === 0 ? "marker_indicator_grande" : numero === total_points ? "marker_indicator_grande" : "marker_indicator"} />
            <div className="marker_content">
                <div className="card-proyecto">
                    <div className="d-flex flex-column justify-content-start align-items-start mx-3 my-3">
                        <span className="bold azul mb-1">HORA: {moment(creado).format('hh:mm A')}</span>
                        <span className="bold azul mb-1">FECHA: {moment(creado).format('DD-MM-YYYY')}</span>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <span className="gris">Latitud:</span>
                                    <span className="gris">Longitud:</span>
                                </div>
                                <div className="d-flex flex-column justify-content-end align-items-end ml-1">
                                    <span className="gris">{lat}</span>
                                    <span className="gris">{lng}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default class DetalleProyectoEnCurso extends Component {
    state = {
        oferta: false,
    };

    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    render() {
        const { item, loader, me } = this.props;
        const points = item && item.points_gps ? item.points_gps : [];
        const total_points = item && item.points_gps ? item.points_gps.length : [];
        const keys_center_map = Object.keys(item.center_map ? item.center_map : {});
        const zoom = 10;
        return (
            <div className="mt-4 col-12">
                <Card titulo={`DETALLE DEL PROYECTO ${item.estado_proyecto && item.estado_proyecto === EN_CURSO ? "EN CURSO" : item.estado_proyecto === EN_RECEPCION_OFERTAS ? "EN RECEPCION" : item.estado_proyecto === FINALIZADO ? "FINALIZADO" : item.estado_proyecto === ADJUDICADO_PENDIENTE_DE_INICIAR ? "ADJUDICADO" : "VENCIDO"}`}>
                    <LoadMask loading={loader} blur>
                        <div className="px-lg-5 pt-lg-4">
                            <div className={`card-proyecto mb-3 ${item.estado_proyecto && item.estado_proyecto === EN_CURSO ? "borde-azul" : item.estado_proyecto === EN_RECEPCION_OFERTAS ? "borde-verde" : item.estado_proyecto === FINALIZADO ? "borde-gris-oscuro" : item.estado_proyecto === ADJUDICADO_PENDIENTE_DE_INICIAR ? "borde-naranja" : "borde-rojo"}`}>
                                <div className="px-3 py-3 row">
                                    <div className="col-12 mb-3">
                                        <h4 className="gris-oscuro bold">{item.nombre ? item.nombre : ""}</h4>
                                    </div>
                                    <div className="d-flex flex-column col-md-12 col-lg-6 col-12">
                                        {!!item.codigo_tracking && item.codigo_tracking !== null && (
                                            <span className="mb-1 gris-letras bold">Código de trackeo: <span className="gris-oscuro bold">{item.codigo_tracking}</span></span>
                                        )}
                                        <span className="mb-1 gris-letras bold">Origen: <span className="gris-oscuro bold">{item.pais_origen && item.ciudad_origen && item.pais_origen.nombre && item.ciudad_origen.nombre && item.direccion_origen ? `${item.direccion_origen}, ${item.ciudad_origen.nombre}, ${item.pais_origen.nombre}` : ""}</span></span>
                                        <span className="mb-1 gris-letras bold">Destino: <span className="gris-oscuro bold">{item.pais_destino && item.ciudad_destino && item.pais_destino.nombre && item.ciudad_destino.nombre && item.direccion_destino ? `${item.direccion_destino}, ${item.ciudad_destino.nombre}, ${item.pais_destino.nombre}` : ""}</span></span>
                                        <span className="mb-1 gris-letras bold">Tipo de transporte: <span className="gris-oscuro bold">{item.tipo_vehiculo && item.tipo_vehiculo.nombre ? item.tipo_vehiculo.nombre : ""}</span></span>
                                        <span className="mb-1 gris-letras bold">Presupuesto: <RenderDollar value={item.presupuesto ? item.presupuesto : 0} className="gris-oscuro bold" /></span>
                                        <span className="mb-1 gris-letras bold">Fecha de entrega: <span className="gris-oscuro bold">{moment(item.dia_entrega ? item.dia_entrega : "").format('DD-MM-YYYY')}</span></span>
                                        <span className="mb-1 gris-letras bold">Fecha y hora de salida: <span className="gris-oscuro bold">{moment(item.fecha_hora_inicio_viaje ? item.fecha_hora_inicio_viaje : "").format('DD-MM-YYYY hh:mm A')}</span></span>
                                    </div>
                                    <div className="d-flex flex-column col-md-12 col-lg-6 col-12 mb-lg-4">
                                        <span className="gris-letras bold">Especificaciones del viaje: </span>
                                        <span className="gris-oscuro bold mb-1 mb-lg-5">{item.descripcion_trabajo ? item.descripcion_trabajo : ""}</span>
                                        <span className="gris-letras bold">Nota:</span>
                                        <span className="gris-oscuro bold mb-1">{item.notas ? item.notas : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-lg-5 pb-lg-4 mt-3">
                            <div className="row px-auto px-md-3">
                                <div className="d-flex align-items-center col-lg-2 col-12 bg-white mb-lg-0 mb-3 py-lg-0 py-3" style={{ borderRadius: "10px" }}>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-start align-items-center mb-3">
                                            <div className="mr-2" style={{ borderRadius: "50%", width: "15px", height: "15px", backgroundColor: "#BE0000" }} />
                                            <span className="gris-oscuro">Punto de partida</span>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center mb-3">
                                            <div className="mr-2" style={{ borderRadius: "50%", width: "15px", height: "15px", backgroundColor: "#8600BE" }} />
                                            <span className="gris-oscuro">Punto</span>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center mb-3">
                                            <div className="mr-2" style={{ borderRadius: "50%", width: "15px", height: "15px", backgroundColor: "#FA7D09" }} />
                                            <span className="gris-oscuro">Punto de fin</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-10 col-12 pl-lg-3 px-0">
                                    {item.center_map && keys_center_map.length !== 0 && (
                                        <div style={{ height: '50vh', width: '100%' }}>
                                            <GoogleMap
                                                bootstrapURLKeys={{ key: 'AIzaSyBUq39Cd9PdX4qTz55DLK6hpVIE8uUOA00' }}
                                                defaultCenter={item.center_map}
                                                defaultZoom={zoom}
                                            >
                                                {points && points.map((point, index) => (
                                                    <InformacionGPS key={index} lat={point.latitud} lng={point.longitud} numero={index} total_points={total_points - 1} creado={point.creado} />
                                                ))}
                                            </GoogleMap>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                                <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                                    <button className="btn btn-dark btn-block" onClick={() => this.props.history.goBack()}>Regresar</button>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </Card>
            </div>
        );
    }
}
