import React, { Component } from 'react';
import Card from "Utils/Cards/Card";
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderTransportista from "Utils/Grid/HeaderTransportista";
import Select, { Async } from "react-select";
import Slider, { Range } from 'rc-slider';
import classNames from 'classnames';
import { Pagination } from 'antd';
import CardProyecto from '../../../Utils/Cards/CardProyecto';
import { ESTADO_PROYECTO, ADJUDICADO_PENDIENTE_DE_INICIAR, USER_CONTRATISTA_EMPRESA } from '../../../../../utility/constants'
import { api } from "api";
import 'rc-slider/assets/index.css';

const getPaisOrigen = (search) => {
    return api.get("pais/listar_pais", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getVehiculo = (search) => {
    return api.get("tipo_vehiculo", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

export default class HomeTransportista extends Component {
    state = {
        CiudadOrigen : [],
        PaisOrigenId: 0
    }

    getCiudadOrigen = (...datos) => {
        let params = {}

        if (typeof(datos[0]) === 'number') {

            if (datos[0] === 0) {
                this.setState({CiudadOrigen: []});
                this.setState({PaisOrigenId: 0});
                return []
            }

            params.pais = datos[0]
            this.setState({PaisOrigenId: params.pais})

        }else{

            if (this.state.PaisOrigenId === 0) {
                this.setState({CiudadOrigen: []});
                return []
            }

            params.pais = this.state.PaisOrigenId
            params.search = datos[0]
        }

        return api.get("ciudad", params).then((data) => {
            if (data) {
                this.setState({CiudadOrigen: data.results});
                return data.results
            };
            return [];
        }).catch(() => {
            return [];
        });
    };

    componentWillMount() {
        this.props.infoProyectosPublicadados().then(() => this.props.listarProyectosPublicadados());
    }

    eliminar = (id) => {
    }

    onChangeSlider = (value) => {
        this.props.filtroPrecioMinTransportista(value[0]);
        this.props.filtroPrecioMaxTransportista(value[1]);
    }

    filtrarXPrecio = (value) => {
        this.props.listarProyectosPublicadados()
    }

    render() {
        const { listarProyectosPublicadados } = this.props; //Funciones
        const { filtroPaisTransportista, filtroCiudadTransportista, filtroVehiculoTransportista } = this.props; //Funciones
        const { data, loader, page, filtro_pais, filtro_ciudad, filtro_vehiculo, filtro_precio_max, filtro_precio_min, precio_max, precio_min, me } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="PROYECTOS PUBLICADOS">
                    <HeaderTransportista
                        filtro1={
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={filtro_pais}
                                isSearchable={true}
                                loadOptions={getPaisOrigen}
                                getOptionValue={(option) => option["id"]}
                                getOptionLabel={(option) => option["nombre"]}
                                type="text"
                                onChange={(e) => {
                                    filtroPaisTransportista(e)
                                    filtroCiudadTransportista('')
                                    this.getCiudadOrigen(e && e.id ? e.id : 0)
                                }}
                                multi={false}
                                autoload={false}
                                cache={false}
                                className={classNames(
                                    "react-select-container w-100"
                                )}
                                defaultOptions={true}
                                placeholder="Filtrar por pais.."
                            />
                        }
                        filtroText1="País"
                        filtro2={
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={filtro_ciudad}
                                isSearchable={true}
                                loadOptions={this.getCiudadOrigen}
                                getOptionValue={(option) => option["id"]}
                                getOptionLabel={(option) => option["nombre"]}
                                type="text"
                                onChange={(e) => {
                                    if (!e){
                                        // sirve para hacer la consulta de datos cuando se deselecciona una opcion
                                        this.getCiudadOrigen('')
                                    }
                                    filtroCiudadTransportista(e)
                                }}
                                // cacheOptions
                                multi={false}
                                autoload={true}
                                cache={true}
                                className={classNames(
                                    "react-select-container w-100"
                                )}
                                defaultOptions={this.state.CiudadOrigen}
                                placeholder="Filtrar por ciudad.."
                            />
                        }
                        filtroText2="Ciudad"
                        filtro3={
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={filtro_vehiculo}
                                isSearchable={true}
                                loadOptions={getVehiculo}
                                getOptionValue={(option) => option["id"]}
                                getOptionLabel={(option) => option["nombre"]}
                                type="text"
                                onChange={(e) => filtroVehiculoTransportista(e)}
                                multi={false}
                                autoload={false}
                                cache={false}
                                className={classNames(
                                    "react-select-container w-100"
                                )}
                                defaultOptions={true}
                                placeholder="Filtrar por vehiculo.."
                            />
                        }
                        filtroText3="Tipo de transporte"
                        filtro4={
                            <div className="mx-lg-1 d-flex flex-column flex-wrap align-items-end">
                                <Range
                                    min={precio_min}
                                    max={precio_max}
                                    defaultValue={[precio_min, precio_max]}
                                    tipFormatter={value => `${value}%`}
                                    allowCross={false}
                                    step="100"
                                    className="mb-1"
                                    onChange={this.onChangeSlider}
                                    onAfterChange={this.filtrarXPrecio}
                                />
                                <span className="gris-oscuro text-xsm">{`Precio: $.${filtro_precio_min} - $.${filtro_precio_max}`}</span>
                            </div>
                        }
                        filtroText4="Rango de precio"
                    />
                    <LoadMask loading={loader} blur>
                        <div className="row px-0">
                            {data && data.results && data.results.map((proyecto, index) => {
                                return(
                                    <CardProyecto
                                        key={proyecto.id}
                                        id={proyecto.id ? proyecto.id : ""}
                                        nombre={proyecto.nombre ? proyecto.nombre : ""}
                                        fecha_cierre={proyecto.fecha_cierre_ofertas ? proyecto.fecha_cierre_ofertas : ""}
                                        origen={proyecto.pais_origen && proyecto.ciudad_origen && proyecto.pais_origen.nombre && proyecto.ciudad_origen.nombre ? `${proyecto.ciudad_origen.nombre}, ${proyecto.pais_origen.nombre}` : ""}
                                        destino={proyecto.pais_destino && proyecto.ciudad_destino && proyecto.pais_destino.nombre && proyecto.ciudad_destino.nombre ? `${proyecto.ciudad_destino.nombre}, ${proyecto.pais_destino.nombre}` : ""}
                                        tipo_transporte={proyecto.tipo_vehiculo && proyecto.tipo_vehiculo.nombre ? proyecto.tipo_vehiculo.nombre : ""}
                                        presupuesto={proyecto.presupuesto ? proyecto.presupuesto : 0}
                                        fecha_hora_posicionamiento={proyecto.fecha_hora_posicionamiento ? proyecto.fecha_hora_posicionamiento : ""}
                                        ofertas={proyecto.total_ofertas ? proyecto.total_ofertas : 0}
                                        eliminarProyecto={this.eliminar}
                                        listado_transportista={true}
                                        tipo={ADJUDICADO_PENDIENTE_DE_INICIAR}
                                        tipo_usuario={me && me.tipo_usuario ? me.tipo_usuario : USER_CONTRATISTA_EMPRESA}
                                        codigo_tracking={proyecto.codigo_tracking ? proyecto.codigo_tracking : null}
                                    />
                                )
                            })}
                            {data && data.results && data.results.length === 0  && (
                                <div className="col-12 d-flex justify-content-center align-items-center mb-3" style={{height: "5rem"}}>
                                    <h2>Sin resultados</h2>
                                </div>
                            )}
                        </div>
                        {data && data.results && data.count > 0 && (
                            <div className="w-100 p-0 text-right mb-3">
                                <Pagination
                                    size="small"
                                    current={page}
                                    total={data && data.count ? data.count : 0}
                                    onChange={listarProyectosPublicadados}
                                />
                            </div>
                        )}
                    </LoadMask>
                </Card>
            </div>
        )
    }
}
