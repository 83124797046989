import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { validators, combine } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { renderField, renderTextArea, renderFilePicker, renderNumber, renderSwitch, SelectField, renderColorPicker, renderImageUpload } from '../../../Utils/renderField/renderField';
const eliminar_img = require('assets/img/icons/borrar_hover.svg');
const alerta_img = require('assets/img/icons/alert.svg');
import { EN_EDICION, EN_REVISION, VALIDADA, RECHAZADA } from "../../../../../utility/constants"

const renderDetallePublicidad = ({ fields, meta: { error, submitFailed }, estado_publicidad, justificacion_rechazo, setImgsPublicidad, eliminarImgsPublicidad }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <div className="col-12 form-group m-auto px-0 px-sm-0">
            {estado_publicidad == RECHAZADA &&
                <div className="d-flex flex-column flex-sm-row align-items-center">
                    <img src={alerta_img} alt="Alerta" width={25} height={20} className="mr-2  blink-danger" />
                    <span className="bold rojo">{justificacion_rechazo}</span>
                </div>
            }
            <div className="text-center mb-3 mt-4" />
            <div className="row my-n2">
                <div className="react-bs-container-header" style={{ height: 20 }} />
            </div>
            {fields.map((detalle, index) => {
                const detalleActual = fields.get(index)
                return (
                    <div className="row border-publicidad py-1 px-0 px-sm-1 px-lg-1 px-xl-1" key={index}>
                        <div className="d-flex align-items-center px-1 col-md-1 justify-content-center text-center" >
                            {fields.length > 1 &&
                                <img src={eliminar_img} alt="Eliminar"
                                    className="action_img"
                                    onClick={() => {
                                        eliminarImgsPublicidad(index)
                                        // fields.remove(index)
                                    }}
                                />
                            }
                        </div>
                        <div className="col-md-10 px-0 text-center align-self-stretch">
                            <Field
                                name={`${detalle}.imagen`}
                                imagen={detalleActual.imagen ? detalleActual.imagen : null}
                                className="px-2 "
                                setFile={(file) => setImgsPublicidad(index, file)}
                                component={renderImageUpload}
                                componenteHorizontal
                                // aspect={10 / 3}
                                // aspect={5 / 1}
                                aspect={10 / 1}
                            />
                        </div>
                        {/* <Field
                            addClassDiv="col-md-7 px-2 my-2"
                            addClassTextArea="h-100"
                            name={`${detalle}.texto`}
                            component={renderTextArea}
                            maxLength="150"
                        /> */}
                    </div>
                )
            }
            )}
            {/* </div> */}
            {fields.length && fields.length < 5 &&
                <div className="d-flex justify-content-center mt-3">
                    <button type="button" className="btn btn-secondary py-1" onClick={() => {
                        // console.log("tota: ", fields.length)
                        fields.push({})
                    }}>
                        AGREGAR
                    </button>
                </div>
            }


        </div>
    )
}

class PublicidadForm extends Component {
    componentDidMount() {
        if (!this.props.editando) {
            this.props.cleanForm()
        }
    }

    render() {
        const { handleSubmit, item = "", editando, setImgsPublicidad, eliminarImgsPublicidad } = this.props;
        const { estado_publicidad = 0, justificacion_rechazo = "" } = item

        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container col-12 px-0 px-sm-4">

                    <div className="row col-md-12 p-0">
                        <div className="col-md-12 p-0">
                            <div className="col-md-12 col-sm-12 mb-2">
                                <label htmlFor="licencia_img" className="m-0 text-sm">PUBLICIDAD
                                    {estado_publicidad == EN_EDICION ? " EN EDICIÓN" :
                                        estado_publicidad == EN_REVISION ? " EN REVISIÓN" :
                                            estado_publicidad == VALIDADA ? " VALIDADA" :
                                                estado_publicidad == RECHAZADA ? " RECHAZADA" :
                                                    ""}
                                </label>
                            </div>
                            <div className="col-md-12  form-group">
                                <FieldArray name="detalle_publicidad" estado_publicidad={estado_publicidad} justificacion_rechazo={justificacion_rechazo} eliminarImgsPublicidad={eliminarImgsPublicidad} setImgsPublicidad={setImgsPublicidad} component={renderDetallePublicidad} />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                        <div className="col-md-4 col-12">
                            <Link className="btn btn-dark btn-block" to="/publicidades">Cancelar</Link>
                        </div>
                        <div className="col-md-4 col-12 mt-md-0 mt-3">
                            <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
};

const validate = (values) => {

    const errors = {};
    const DetalleArrayErrors = [];
    if (values && values.detalle_publicidad && values.detalle_publicidad.length) {

        values.detalle_publicidad.forEach((detalle, detalleIndex) => {
            const detalleErrors = {};
            if (!detalle.imagen) {
                detalleErrors.imagen = 'Subir una imagen';
                DetalleArrayErrors[detalleIndex] = detalleErrors
            }
        });
    }

    if (DetalleArrayErrors.length) {
        errors.detalle_publicidad = DetalleArrayErrors
    }


    return errors;
};

PublicidadForm = reduxForm({
    form: 'PublicidadForm',
    validate
})(PublicidadForm);

export default PublicidadForm
