import React, { Component } from 'react';

export default class Loader extends Component {
    render() {
        return (
            <div className="blue-gradient-bg">
                <div className="mt-n5 contenedor-principal">
                    <div className="mt-n5 col-12 h-100 d-flex justify-content-center align-items-center flex-wrap">
                        <div className="mt-n5 col-12 login-wrapper d-flex flex-column">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-11 mb-2 text-center">
                                <img src={require('assets/img/logo_completo.svg')} className="img-fluid" alt="Logo" />
                            </div>
                            <span className="naranja bold mt-1 text-lgs">Cargando...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
