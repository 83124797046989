import React from 'react';
import { Modal } from 'react-responsive-modal';
// import './modal-terminos.css';
let ModalTerminos = props => {
    const { open, onClose } = props;
    return (
        <Modal open={open} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} showCloseIcon={false} classNames={{ modal: "d-flex align-items-center" }}>
            <div className="col-12 px-0">
                <div className="form-group grid-container">
                    <div className="col-12 text-center mb-3">
                        <span className="text-sm gris-oscuro bold c8">POLÍTICA DE PRIVACIDAD</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="col-12 px-0">
                        <div className="c7 terminos-estilo pr-2">
                            <p className="c5"><span className="c1"></span></p>
                            <p className="c3"><span className="c1">Por medio de esta Pol&iacute;tica de Privacidad y Seguridad, en </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;le informamos sobre los aspectos que usted debe saber y autorizar en relaci&oacute;n con la privacidad de sus datos en nuestra aplicaci&oacute;n de software que se instala en dispositivos m&oacute;viles o tabletas, o bien desde el sitio web.</span></p>
                            <p className="c3"><span className="c1">Le informamos que contamos con una base de datos en la cual recopilamos, procesamos y almacenamos su informaci&oacute;n personal y con fines exclusivamente dom&eacute;sticos o internos.</span></p>
                            <p className="c3"><span className="c1">I. DEFINICIONES</span></p>
                            <p className="c3"><span className="c1">I.1. APP: </span></p>
                            <p className="c2"><span className="c1">La aplicaci&oacute;n para dispositivos que incluye, pero no se limita, a IOS y Android, que </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;estar&aacute; poniendo a disposici&oacute;n de los USUARIOS.</span></p>
                            <p className="c3"><span className="c1">I.2. CONSUMIDORES: </span></p>
                            <p className="c2"><span className="c1">Los consumidores finales de </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;que podr&aacute;n adquirir los SERVICIOS ofrecidos por </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;trav&eacute;s del SITIO WEB y el APP.</span></p>
                            <p className="c3"><span className="c1">I.3. FUERZA MAYOR: </span></p>
                            <p className="c2"><span className="c1">Significa la producci&oacute;n de un hecho o circunstancia (&ldquo;Caso de Fuerza Mayor&rdquo;) que imposibilita o impide que una parte cumpla una o m&aacute;s de sus obligaciones contractuales de acuerdo con el contrato, en la medida en que la parte afectada por el impedimento pruebe: (a) que dicho impedimento est&aacute; fuera de su control razonable; y (b) que los efectos del impedimento no podr&iacute;an razonablemente haber sido evitados o superados por la Parte Afectada.</span></p>
                            <p className="c3"><span className="c1">I.4. EASY TRUCK: </span></p>
                            <p className="c2"><span className="c0">EASY TRUCK, SOCIEDAD AN&Oacute;NIMA</span><span className="c1">, una sociedad mercantil organizada y constituida de conformidad con las leyes de la Rep&uacute;blica de Guatemala, y todas sus sociedades subsidiarias, de naturaleza mercantil que en el futuro.</span></p>
                            <p className="c3"><span className="c1">I.5. INFORMACION PERSONAL o DATOS PERSONALES o DATOS DE LOS USUARIOS: </span></p>
                            <p className="c2"><span className="c1">Se entiende como &ldquo;Informaci&oacute;n Personal&rdquo; o &ldquo;Datos Personales&rdquo; o &ldquo;Datos de los USUARIOS&rdquo;, el nombre del USUARIO, apellidos, correo electr&oacute;nico, tel&eacute;fono, domicilio exacto, direcci&oacute;n IP, informaci&oacute;n sobre compras y/o cualquier otra informaci&oacute;n personal requerida por </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;a los USUARIOS para efectos de ingresar y realizar sus transacciones en el SITIO WEB y/o APP.</span></p>
                            <p className="c3"><span className="c1">I.6. LEY APLICABLE: </span></p>
                            <p className="c2"><span className="c1">Significa, para cada pa&iacute;s en el que opera </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;o llegue a operar en el futuro, el cuerpo normativo que regule todo lo relativo a protecci&oacute;n de datos, seg&uacute;n sea modificado de tiempo en tiempo. Lo anterior incluye para: El Salvador: Ley de Protecci&oacute;n al Consumidor y su Reglamento; Guatemala: Ley de Protecci&oacute;n de Datos Personales; Nicaragua: Ley de Protecci&oacute;n de Datos Personales; Honduras: (pendiente emisi&oacute;n); Costa Rica: Ley de Protecci&oacute;n de la Persona frente al tratamiento de sus datos personales de Costa Rica N&ordm; 8968 y su Reglamento; y Rep&uacute;blica Dominicana: Ley Org&aacute;nica sobre la Protecci&oacute;n de Datos de Car&aacute;cter Persona.</span></p>
                            <p className="c3"><span className="c1">I.7. POL&Iacute;TICA: </span></p>
                            <p className="c3 c4"><span className="c1">La presente Pol&iacute;tica de Privacidad y Seguridad.</span></p>
                            <p className="c3"><span className="c1">I.8. SERVICIOS: </span></p>
                            <p className="c2"><span className="c1">Todos aquellos servicios que brinda </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;a trav&eacute;s de su SITIO WEB o en el APP.</span></p>
                            <p className="c3"><span className="c1">I.9. SITIO WEB: </span></p>
                            <p className="c2"><span className="c1">El sitio web easytrucks.com u otros del dominio de </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;que se creen de tiempo en tiempo.</span></p>
                            <p className="c3"><span className="c1">I.10. USUARIO: </span></p>
                            <p className="c2"><span className="c1">Los USUARIOS REGISTRADOS y los USUARIOS VISITANTES, podr&aacute;n ser denominados en forma conjunta y para aquello en lo que les aplique a ambos por igual, como el &ldquo;Usuario&rdquo;.</span></p>
                            <p className="c3"><span className="c1">I.11. USUARIO REGISTRADO: </span></p>
                            <p className="c2"><span className="c1">Todo usuario que se registre a la plataforma de </span><span className="c0">EASY TRUCK</span><span className="c1">, a trav&eacute;s del SITIO WEB y/o APP, ya sea como Comprador, Vendedor o </span><span className="c0">EASY TRUCK DRIVER</span><span className="c1">, y que no haya solicitado posteriormente ser removido del registro.</span></p>
                            <p className="c3"><span className="c1">I.12. USUARIO VISITANTE: </span></p>
                            <p className="c2"><span className="c1">Todo usuario que visite el SITIO WEB o APP como visitante, pero que no se registre.</span></p>
                            <p className="c3"><span className="c1">II. CONSENTIMIENTO INFORMADO DE ALMACENAMIENTO Y USO DE INFORMACI&Oacute;N</span></p>
                            <p className="c3"><span className="c1">II.1. Recopilaci&oacute;n de informaci&oacute;n</span></p>
                            <p className="c2"><span className="c1">Al registrarse como USUARIO REGISTRADO, ya sea nuestro SITIO WEB o nuestra APP, o acceder nuestros SERVICIOS o comunicarse con </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;como USUARIO VISITANTE en nuestro SITIO WEB, APP o cualquier otro medio, usted acepta libre y expresamente que su INFORMACI&Oacute;N PERSONAL, sea consultada, procesada, tratada y almacenada por </span><span className="c0">EASY TRUCK</span><span className="c1">.</span></p>
                            <p className="c2"><span className="c1">Salvo que espec&iacute;ficamente se establezca lo contrario, todos los datos que </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;le solicita en los procesos de registro y/o de compra de productos y/o solicitud de SERVICIOS en el SITIO WEB o en la APP son necesarios para poder hacer el registro y/o la prestaci&oacute;n de los SERVICIOS que </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;ofrece. El USUARIO est&aacute; facultado a no brindar sus DATOS PERSONALES; no obstante, esto podr&iacute;a implicar que no le podremos brindar alguno o algunos de los SERVICIOS requeridos, debido a la naturaleza de los mismos.</span></p>
                            <p className="c2"><span className="c1">Los USUARIOS son quienes completan por s&iacute; mismos sus datos personales en el SITIO WEB y en la APP de </span><span className="c0">EASY TRUCK</span><span className="c1">, por lo que ser&aacute; su responsabilidad que la informaci&oacute;n sea real, veraz, completa y actualizada al momento del ingreso de la informaci&oacute;n en la base de datos vinculada con el SITIO WEB y la APP. La omisi&oacute;n o inexactitud en alguno de ellos podr&iacute;a implicar la imposibilidad de que podamos prestarle los SERVICIOS solicitados y/o atender sus solicitudes y facultar&aacute; a </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;para tomar acciones tales como pero no limitadas a: eliminar la cuenta del USUARIO, no brindar los SERVICIOS requeridos, o que los datos suministrados por el USUARIO sean eliminados de la base de datos.</span></p>
                            <p className="c3"><span className="c1">II.1.1. Informaci&oacute;n que usted nos proporciona</span></p>
                            <p className="c2"><span className="c1">Recopilamos la INFORMACION PERSONAL que el USUARIO nos proporciona directamente, por ejemplo, cuando crea o modifica su cuenta, solicita o usa nuestros SERVICIOS, se pone en contacto con atenci&oacute;n al cliente o cuando se comunica de cualquier otra forma (v&iacute;a SITIO WEB, APP, tel&eacute;fono, correo y cualquier otro) con nosotros en </span><span className="c0">EASY TRUCK</span><span className="c1">. Esta informaci&oacute;n puede incluir, pero no est&aacute; limitada a: nombre, correo electr&oacute;nico, n&uacute;mero de tel&eacute;fono, direcci&oacute;n postal, foto del perfil, g&eacute;nero, estado civil, fecha de cumplea&ntilde;os, m&eacute;todo de pago, ciertos datos de tarjeta de cr&eacute;dito (ver cl&aacute;usula VI. de esta POLITICA), los art&iacute;culos solicitados para servicios de entrega, notas de entrega, el contenido de la comunicaci&oacute;n, metadatos y otra informaci&oacute;n personal que el USUARIO proporcione.</span></p>
                            <p className="c3"><span className="c1">II.1.2. Informaci&oacute;n que recopilamos a trav&eacute;s de su uso de nuestros Servicios</span></p>
                            <p className="c2"><span className="c1">Cuando el USUARIO utiliza nuestros SERVICIOS, recopilamos INFORMACION PERSONAL en las siguientes categor&iacute;as generales:</span></p>
                            <p className="c2"><span className="c1">&bull; Informaci&oacute;n de ubicaci&oacute;n: Cu&aacute;ndo, qu&eacute; tan menudo y bajo qu&eacute; circunstancias utiliza nuestros SERVICIOS de entrega bajo demanda, recopilamos datos de ubicaci&oacute;n exacta o aproximada. Si usted permite a la aplicaci&oacute;n de </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;acceder a los SERVICIOS de ubicaci&oacute;n a trav&eacute;s del sistema de permisos que utiliza su sistema operativo para m&oacute;viles, tambi&eacute;n podremos recopilar la ubicaci&oacute;n exacta de su dispositivo cuando la aplicaci&oacute;n se est&eacute; ejecutando. Tambi&eacute;n podemos obtener su ubicaci&oacute;n aproximada a partir de su direcci&oacute;n IP.</span></p>
                            <p className="c2"><span className="c1">&bull; Informaci&oacute;n de la operaci&oacute;n: Informaci&oacute;n de la operaci&oacute;n: Recopilamos datos relacionados con el uso de nuestros SERVICIOS, incluyendo el tipo de servicio solicitado, la fecha y el momento de la prestaci&oacute;n del SERVICIO, cantidad cargada, distancia recorrida, tiempo de entrega y otros detalles relacionados con la operaci&oacute;n.</span></p>
                            <p className="c2"><span className="c1">&bull; Uso e informaci&oacute;n de preferencias: Recopilamos informaci&oacute;n sobre c&oacute;mo el USUARIO del SITIO WEB y/o el APP interact&uacute;a con nuestros SERVICIOS, preferencias expresadas y configuraciones seleccionadas.</span></p>
                            <p className="c2"><span className="c1">&bull; Informaci&oacute;n del ordenador: Cuando el USUARIO interact&uacute;a con los SERVICIOS, recopilamos los registros del servidor, que pueden incluir informaci&oacute;n como direcciones IP del dispositivo, fechas y horas de acceso, funciones de la aplicaci&oacute;n utilizadas, p&aacute;ginas visitadas y uso del SITIO WEB o APP, bloqueos de la aplicaci&oacute;n y otra actividad del sistema, tipo y versi&oacute;n de navegador, sistema operativo y el sitio de terceros o servicio que estaba utilizando antes de interactuar con nuestros Servicio.</span></p>
                            <p className="c3"><span className="c1">II.2. Uso de informaci&oacute;n</span></p>
                            <p className="c2"><span className="c1">Podemos usar la INFORMACION PERSONAL que recopilamos sobre el USUARIO para actividades como, pero no limitadas a:</span></p>
                            <p className="c2"><span className="c1">&bull; Prestar, activar, mantener, administrar y mejorar nuestros SERVICIOS como por ejemplo, facilitar los pagos, enviar recibos o facturas (a las cuales se asociar&aacute; el nombre del USUARIO), proporcionar productos y SERVICIOS que el USUARIO solicite (y enviar informaci&oacute;n relacionada o comunicaciones comerciales), desarrollar nuevas funciones, prestar atenci&oacute;n al cliente tanto a los USUARIOS como a nuestros </span><span className="c0">EASY TRUCK DRIVERS</span><span className="c1">, desarrollar opciones de seguridad, autenticar usuarios y enviar actualizaciones de productos y mensajes administrativos;</span></p>
                            <p className="c2"><span className="c1">&bull; Realizar operaciones internas, como, por ejemplo, mantener protegido el SITIO WEB, prevenir el fraude y el abuso de nuestros SERVICIOS; solucionar los errores de software y problemas de funcionamiento; llevar a cabo an&aacute;lisis de datos, pruebas e investigaci&oacute;n; controlar y analizar las tendencias de uso y de actividad; verificar el cumplimiento de los t&eacute;rminos y condiciones que rigen el uso de nuestros SERVICIOS (incluyendo la monitorizaci&oacute;n de mensajes privados a trav&eacute;s del servicio de mensajer&iacute;a privada que brinda </span><span className="c0">EASY TRUCK</span><span className="c1">);</span></p>
                            <p className="c2"><span className="c1">&bull; Enviar comunicaciones que han sido solicitadas o aceptadas bajo estos t&eacute;rminos por el USUARIO o que puede ser de su inter&eacute;s, incluida la informaci&oacute;n sobre los productos, SERVICIOS, promociones, noticias y eventos de </span><span className="c0">EASY TRUCK</span><span className="c1">, y cuando est&eacute; permitido y de conformidad con las LEYES APLICABLES; y para tramitar concursos, sorteos u otras entradas de promociones y cumplir con los premios relacionados; todo lo anterior, salvo que el USUARIO haya solicitado ser removido del registro o no recibir notificaciones;</span></p>
                            <p className="c2"><span className="c1">&bull; Personalizar y mejorar los SERVICIOS, incluyendo proporcionar o recomendar funciones, contenido, conexiones sociales, referencias y anuncios.</span></p>
                            <p className="c2"><span className="c1">El USUARIO autoriza que la INFORMACION PERSONAL sea compartida con terceros, seg&uacute;n se indicar&aacute; a continuaci&oacute;n, de conformidad con lo establecido en los T&eacute;rminos y Condiciones del SITIO WEB y la APP. A dichos terceros se les exigir&aacute; los mismos est&aacute;ndares de seguridad que sigue </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;y que son requeridos por la LEYES APLICABLES. Podemos compartir la informaci&oacute;n que recopilamos sobre el USUARIO con:</span></p>
                            <p className="c2"><span className="c1">&bull; </span><span className="c0">EASY TRUCK DRIVERS</span><span className="c1">&nbsp;para que puedan prestar los SERVICIOS que el USUARIO solicita. Por ejemplo, compartimos su nombre, n&uacute;mero de tel&eacute;fono, y las ubicaciones de entrega;</span></p>
                            <p className="c2"><span className="c1">&bull; Nuestro personal de atenci&oacute;n al cliente, para dar respuesta a las preguntas y quejas de los USUARIOS con relaci&oacute;n a nuestros SERVICIOS;</span></p>
                            <p className="c2"><span className="c1">&bull; El p&uacute;blico en general si env&iacute;a el contenido a un foro p&uacute;blico, por ejemplo, comentarios en blogs, mensajes de redes sociales u otras opciones de nuestros SERVICIOS a disposici&oacute;n del p&uacute;blico en general, en cuyo caso el USUARIO asumir&aacute; el riesgo y liberar&aacute; de toda responsabilidad a </span><span className="c0">EASY TRUCK</span><span className="c1">, por cualquier uso indebido, no autorizado o ilegal de la informaci&oacute;n por parte de terceros;</span></p>
                            <p className="c2"><span className="c1">&bull; Con terceros con quienes </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;tenga relaciones comerciales (pero de tal forma que estos terceros no podr&aacute;n identificar a ning&uacute;n USUARIO individual con dicha informaci&oacute;n);</span></p>
                            <p className="c2"><span className="c1">&bull; Con empleados, oficiales, subsidiarias, afiliadas, proveedores, asesores, agentes, socios de marketing y otros proveedores o contratistas que razonablemente necesiten acceder a dicha informaci&oacute;n para los fines descritos en esta pol&iacute;tica;</span></p>
                            <p className="c2"><span className="c1">&bull; En caso de que la INFORMACION PERSONAL deba ser divulgada por orden judicial o de alguna autoridad competente con facultades suficientes para requerirla a </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;o para establecer, ejercer o defender los derechos de </span><span className="c0">EASY TRUCK</span><span className="c1">; o en caso de que </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;deba denunciar a las autoridades competentes alg&uacute;n tipo de actividad ilegal por parte del USUARIO en relaci&oacute;n con Los SERVICIOS brindados trav&eacute;s de nuestro SITIO WEB o la APP.</span></p>
                            <p className="c2"><span className="c1">&bull; Con cualquiera de las subsidiarias, afiliadas o miembros del mismo grupo de inter&eacute;s econ&oacute;mico, en los pa&iacute;ses en que operamos, en forma tal que las subsidiarias no podr&aacute;n identificar a un USUARIO en espec&iacute;fico, que razonablemente necesiten para los fines descritos en esta pol&iacute;tica;</span></p>
                            <p className="c2"><span className="c1">&bull; Con terceros en virtud de una eventual negociaci&oacute;n o formalizaci&oacute;n de una fusi&oacute;n, adquisici&oacute;n, financiamiento, cesi&oacute;n u otras transacciones an&aacute;logas. En estos casos, </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;entregar&aacute; &uacute;nicamente la informaci&oacute;n estrictamente necesaria y en cumplimiento de los lineamientos legales establecidos por la legislaci&oacute;n aplicable sobre la transferencia de datos personales.</span></p>
                            <p className="c2"><span className="c1">&bull; Para cualquier otro uso no descrito expresamente en la presente POLITICA, que sea previamente notificado y aceptado por el USUARIO.</span></p>
                            <p className="c2"><span className="c1">Los DATOS DE LOS USUARIOS no ser&aacute;n cedidos, comunicados ni trasferidos a terceros salvo por lo indicado en la presente POL&Iacute;TICA.</span></p>
                            <p className="c2"><span className="c1">El USUARIO se da por enterado con su aceptaci&oacute;n de la presente POL&Iacute;TICA, y autoriza en forma expresa a </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;a realizar cualquier operaci&oacute;n o conjunto de operaciones mediante procedimientos automatizados o manuales en relaci&oacute;n con sus datos personales. </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;podr&aacute; recolectar, llevar a cabo registros, organizar, conservar, modificar, extraer, consultar, utilizar, procesar, transmitir, transferir, cotejar, bloquear, suprimir o destruir la INFORMACI&Oacute;N PERSONAL del USUARIO.</span></p>
                            <p className="c2"><span className="c1">El USUARIO entiende y acepta que los procedimientos automatizados de tratamiento de datos incluyen pero no se limitan a cualquier operaci&oacute;n, conjunto de operaciones o procedimientos que </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;aplique o pueda llegar a aplicar a la INFORMACION PERSONAL, mediante la utilizaci&oacute;n de software, bots, inteligencia artificial, redes, aplicaciones, en sitios f&iacute;sicos o virtuales, o cualquier otra tecnolog&iacute;a que permita la recolecci&oacute;n, el registro, el acceso, la organizaci&oacute;n, la conservaci&oacute;n, la modificaci&oacute;n, la extracci&oacute;n, la consulta, la utilizaci&oacute;n, la comunicaci&oacute;n por medio de transmisi&oacute;n, la transferencia, el cotejo, la interconexi&oacute;n, el bloqueo, la supresi&oacute;n, la destrucci&oacute;n, el intercambio o digitalizaci&oacute;n de datos personales.</span></p>
                            <p className="c2"><span className="c1">La transferencia de datos que se realice de conformidad con el presente consentimiento informado se realizar&aacute; bajo est&aacute;ndares de seguridad que garanticen al USUARIO y a </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;que la informaci&oacute;n ser&aacute; tratada, procesada y almacenada en concordancia con los fines espec&iacute;ficos del presente consentimiento informado.</span></p>
                            <p className="c2"><span className="c1">La informaci&oacute;n y los datos recolectados por </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;ser&aacute;n tratados con absoluta privacidad, confidencialidad y seguridad, seg&uacute;n estipule la legislaci&oacute;n vigente y aplicable en la materia.</span></p>
                            <p className="c2"><span className="c1">El USUARIO, con la lectura y aceptaci&oacute;n expresa de esta POL&Iacute;TICA, de forma informada, individualizada, clara, precisa, inequ&iacute;voca, voluntaria, libre, y consiente de los alcances de lo aqu&iacute; aceptado, otorga su consentimiento informado para el tratamiento de sus datos seg&uacute;n lo que aqu&iacute; se ha indicado.</span></p>
                            <p className="c2"><span className="c1">El USUARIO puede acceder a la informaci&oacute;n de su cuenta en cualquier momento, as&iacute; como a solicitar el acceso, actualizaci&oacute;n, modificaci&oacute;n y eliminaci&oacute;n de la informaci&oacute;n contenida en ella, de forma gratuita. Si desea desactivar su cuenta, env&iacute;enos un mensaje de correo electr&oacute;nico a </span><span className="c0">info@easytrucks.com</span><span className="c1">&nbsp;o por medio de las l&iacute;neas telef&oacute;nicas disponibles en la secci&oacute;n de &ldquo;Cont&aacute;ctanos&rdquo; del portal web de </span><span className="c0">EASY TRUCK</span><span className="c1">. Tenga en cuenta que en algunos casos podemos retener cierta informaci&oacute;n sobre usted como lo requiera la ley o para fines comerciales leg&iacute;timos en la medida permitida por la LEYES APLICABLES. Por ejemplo, si usted tiene un cr&eacute;dito o deuda pendiente en su cuenta, o si creemos que ha cometido fraude o incumplido nuestros T&eacute;rminos y Condiciones, o de haber cualquier procedimiento legal actual o prospectivo. Pero en cualquiera de estos casos le ser&aacute;&ordm;debidamente informado. El USUARIO es el &uacute;nico responsable por mantener su base de datos actualizada.</span></p>
                            <p className="c2"><span className="c1">Respecto a cualquier otro tema relativo a la protecci&oacute;n de datos de car&aacute;cter personal, puede ponerse en contacto con nosotros mediante nuestra direcci&oacute;n de correo electr&oacute;nico </span><span className="c0">info@easytrucks.com</span><span className="c1">&nbsp;o por medio de las l&iacute;neas telef&oacute;nicas disponibles en la secci&oacute;n de &ldquo;Cont&aacute;ctanos&rdquo; del portal web de </span><span className="c0">EASY TRUCK</span><span className="c1">.</span></p>
                            <p className="c2"><span className="c1">Al aceptar nuestra Pol&iacute;tica de Privacidad y facilitar la INFORMACI&Oacute;N DE USUARIO requerida, se entender&aacute; que el USUARIO emite formal Consentimiento Informado, para tratamientos de sus datos seg&uacute;n lo establecido en la presente POLITICA. En este sentido, el USUARIO reconoce que: ha le&iacute;do, entendido y acepta los alcances de esta POL&Iacute;TICA; que no tiene dudas relacionadas con la forma, medio y fin para el cual se le ha solicitado la INFORMACI&Oacute;N PERSONAL; acepta en forma expresa y voluntaria su registro en nuestra Base de Datos, autoriza el tratamiento de la INFORMACION PERSONAL otorgada, y reconoce que fue informado de los medios por los cuales podr&aacute; darse de baja de nuestra Base de Datos y/o controlar la informaci&oacute;n que proporciona a </span><span className="c0">EASY TRUCK</span><span className="c1">.</span></p>
                            <p className="c3"><span className="c1">III. COMUNICACI&Oacute;N CON EL USUARIO</span></p>
                            <p className="c2"><span className="c1">Todo USUARIO REGISTRADO que utilice los SERVICIOS del SITIO WEB y/o la APP, o que nos escriba al correo electr&oacute;nico, podr&aacute; recibir comunicaciones por cualquiera de estos medios o plataformas por parte de </span><span className="c0">EASY TRUCK</span><span className="c1">. El USUARIO acepta que </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;podr&aacute; comunicar por cualquier medio registrado por el USUARIO: informaci&oacute;n sobre promociones, sobre productos, invitaciones a eventos, notificaciones sobre cambios a la presente POL&Iacute;TICA, y/o los T&eacute;rminos y Condiciones de </span><span className="c0">EASY TRUCK</span><span className="c1">, as&iacute; como cualquier otra informaci&oacute;n o notificaci&oacute;n formal que </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;necesite comunicar al USUARIO.</span></p>
                            <p className="c2"><span className="c1">En todas las comunicaciones electr&oacute;nicas remitidas por </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;se informar&aacute; debidamente el nombre del remitente, as&iacute; como la manera en que podr&aacute; oponerse a seguir recibiendo comunicaciones.</span></p>
                            <p className="c2"><span className="c1">El USUARIO autoriza la recepci&oacute;n de correos electr&oacute;nicos por parte de </span><span className="c0">EASY TRUCK</span><span className="c1">. Sin embargo, podr&aacute;, en cualquier momento, solicitar la suspensi&oacute;n de env&iacute;o de correos a su cuenta de correo, haciendo click en la opci&oacute;n de &ldquo;unsubscribe&rdquo; que aparece al pie de cada correo, o por medio de las l&iacute;neas telef&oacute;nicas disponibles en la secci&oacute;n de &ldquo;Cont&aacute;ctanos&rdquo; del portal web de </span><span className="c0">EASY TRUCK</span><span className="c1">. No obstante, esto podr&aacute; ser motivo para cancelar los SERVICIOS al USUARIO, ya que para la correcta utilizaci&oacute;n del SITIO WEB y la APP, es fundamental para </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;el poder comunicarse con el USUARIO v&iacute;a correo electr&oacute;nico.</span></p>
                            <p className="c3"><span className="c1">IV. UTILIZACI&Oacute;N DE COOKIES Y OTROS SISTEMAS Y TECNOLOG&Iacute;AS</span></p>
                            <p className="c2"><span className="c1">Cuando el USUARIO visita nuestro SITIO WEB y/o nuestra APP algunos de los datos de navegaci&oacute;n se env&iacute;an desde su computador o dispositivo m&oacute;vil a nuestros servidores. Esta informaci&oacute;n permite optimizar nuestros SERVICIOS, personalizar y mejorar su experiencia en nuestro SITIO WEB o la APP. Esta informaci&oacute;n puede incluir aspectos tales como pero no limitados a:</span></p>
                            <p className="c3 c4"><span className="c1">- Su direcci&oacute;n IP</span></p>
                            <p className="c3 c4"><span className="c1">- La fecha y hora de su visita</span></p>
                            <p className="c3 c4"><span className="c1">- La URL de referencia</span></p>
                            <p className="c3 c4"><span className="c1">- Las p&aacute;ginas que visit&oacute; en nuestro SITIO WEB.</span></p>
                            <p className="c3 c4"><span className="c1">- Las funciones que utiliz&oacute; dentro de la APP.</span></p>
                            <p className="c3 c4"><span className="c1">- Informaci&oacute;n sobre el navegador que utiliz&oacute;.</span></p>
                            <p className="c3 c4"><span className="c1">- Tiempo que estuvo en el SITIO WEB y/o en la APP.</span></p>
                            <p className="c2"><span className="c1">- Cantidad de veces que ingreso al SITIO WEB y/o la APP y las veces que visito alguna p&aacute;gina del SITIO WEB o utiliz&oacute; alguna de las funciones disponibles en la App.</span></p>
                            <p className="c2"><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;utiliza varias tecnolog&iacute;as para recopilar informaci&oacute;n cuando el USUARIO visita nuestro SITIO WEB y/o nuestra APP y esto puede incluir el env&iacute;o de una o m&aacute;s cookies u otros identificadores para su dispositivo. Los sistemas y tecnolog&iacute;as que utilizamos son los siguientes:</span></p>
                            <p className="c3"><span className="c1">IV.1 Cookies o Galletas Inform&aacute;ticas</span></p>
                            <p className="c2"><span className="c1">Una cookie es un peque&ntilde;o fichero de texto que se almacena en su navegador cuando visita casi cualquier p&aacute;gina web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa p&aacute;gina.</span></p>
                            <p className="c2"><span className="c1">Las cookies suelen almacenar informaci&oacute;n de car&aacute;cter t&eacute;cnico, preferencias personales, personalizaci&oacute;n de contenidos, estad&iacute;sticas de uso, enlaces a redes sociales, acceso a cuentas de USUARIO, entre otros. El objetivo de la cookie es adaptar el contenido de la web a su perfil y necesidades, sin cookies los servicios ofrecidos por cualquier p&aacute;gina se ver&iacute;an mermados notablemente.</span></p>
                            <p className="c2"><span className="c1">Nuestras cookies se utilizan para analizar su forma de interactuar con nuestro SITIO WEB y nuestra APP, con el fin de optimizar nuestros SERVICIOS y para mejorar su experiencia. Por la manera en que funciona el SITIO WEB y la APP, si el USUARIO desactiva o bloquea la utilizaci&oacute;n de este sistema, se pueden ocasionar errores en el funcionamiento y/o la utilizaci&oacute;n del SITIO WEB y de la APP.</span></p>
                            <p className="c3"><span className="c1">IV.2 Web beacons o Faro Web</span></p>
                            <p className="c2"><span className="c1">Un &ldquo;web beacon&rdquo; o faro web es un archivo de imagen utilizado para seguir la pista a la navegaci&oacute;n del USUARIO por medio del Sitio web. Los web beacons, tambi&eacute;n llamados &ldquo;web bugs&rdquo; son utilizados fundamentalmente para dar seguimiento a la navegaci&oacute;n del USUARIO por el SITIO WEB y/o la APP. Por la manera en que funciona el Sitio Web, si el USUARIO desactiva o bloquea la utilizaci&oacute;n de este sistema, se pueden ocasionar errores en el funcionamiento y/o la utilizaci&oacute;n del SITIO WEB y/o la APP.</span></p>
                            <p className="c3"><span className="c1">IV.3 Web server logs</span></p>
                            <p className="c2"><span className="c1">Un web server log es un archivo simple de texto que graba la actividad en un servidor. Este tipo de sistema permite obtener estad&iacute;sticas sobre los patrones de navegaci&oacute;n de los Usuarios. Por la manera en que funciona el Sitio Web y la App, si el USUARIO desactiva o bloquea la utilizaci&oacute;n de este sistema, se pueden ocasionar errores en el funcionamiento y/o la utilizaci&oacute;n del SITIO WEB y la APP.</span></p>
                            <p className="c3"><span className="c1">IV.4 Tecnolog&iacute;a de Geolocalizaci&oacute;n</span></p>
                            <p className="c2"><span className="c1">La tecnolog&iacute;a de geolocalizaci&oacute;n permite la determinaci&oacute;n de la ubicaci&oacute;n del USUARIO por medio de las coordinadas geogr&aacute;ficas (latitud y longitud) en un mapa com&uacute;n. La informaci&oacute;n ser&aacute; obtenida por medio de la identificaci&oacute;n del IP de un aparato conectado a internet, por medio de un proveedor de red, por medio de un aparato con red GSM o por medio de un GPS, por lo que ser&aacute;n aplicables, a tales servicios de geolocalizaci&oacute;n, los t&eacute;rminos y condiciones del tercero proveedor. Por la manera en que funciona el SITIO WEB y la APP, si el USUARIO desactiva o bloquea la utilizaci&oacute;n de este sistema, se pueden ocasionar errores en el funcionamiento y/o la utilizaci&oacute;n del SITIO WEB y la APP.</span></p>
                            <p className="c2"><span className="c1">Para evitar que su aparato permita el uso de las tecnolog&iacute;as antes dichas, el USUARIO debe rechazar la solicitud de autorizaci&oacute;n que se desplegar&aacute; en la pantalla de su aparato, previo a la navegaci&oacute;n por determinadas secciones de nuestro SITIO WEB y la APP.</span></p>
                            <p className="c2"><span className="c1">Si el USUARIO tiene alguna duda sobre las tecnolog&iacute;as antes dichas, c&oacute;mo funcionan, c&oacute;mo desactivarlas o cualquier otro tipo de inquietud en relaci&oacute;n con las que se utilizan en nuestro SITIO WEB y la APP, deber&aacute; comunicarse con nosotros por medio de las l&iacute;neas telef&oacute;nicas disponibles en la secci&oacute;n de &ldquo;Cont&aacute;ctanos&rdquo; del portal web de </span><span className="c0">EASY TRUCK</span><span className="c1">.</span></p>
                            <p className="c3"><span className="c1">V. REORIENTACI&Oacute;N</span></p>
                            <p className="c2"><span className="c1">Nuestro SITIO WEB y nuestra APP pueden utilizar tecnolog&iacute;as de reorientaci&oacute;n. Por medio de estas tecnolog&iacute;as de reorientaci&oacute;n analizamos el comportamiento del USUARIO en nuestra p&aacute;gina web para poder ofrecerte publicidad adaptada a sus necesidades individuales en p&aacute;ginas tales como pero no limitadas a Facebook, Instagram y Google. Estas tecnolog&iacute;as analizan las cookies y muestra anuncios con base en su comportamiento de navegaci&oacute;n pasado y con base en tu comportamiento de consumo.</span></p>
                            <p className="c3"><span className="c1">VI. ASPECTOS DE SEGURIDAD DE LOS DATOS Y TRANSACCIONES</span></p>
                            <p className="c2"><span className="c1">Estamos comprometidos a proteger su INFORMACION PERSONAL y hemos adoptado las medidas t&eacute;cnicas y organizativas adecuadas para proteger dicha informaci&oacute;n contra cualquier p&eacute;rdida, alteraci&oacute;n, acceso o uso indebido.</span></p>
                            <p className="c2"><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;le informa que sus Datos Personales son almacenados en una base de datos propiedad de esta, y se le garantizan las medidas adecuadas de &iacute;ndole t&eacute;cnica y estructural para garantizar la integridad y seguridad de la INFORMACI&Oacute;N PERSONAL aportada de acuerdo con el estado actual de la t&eacute;cnica.</span></p>
                            <p className="c2"><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;mantiene los niveles de seguridad de protecci&oacute;n de sus datos conforme a la legislaci&oacute;n aplicable, relativo a las medidas de seguridad de las bases de datos que contengan datos de car&aacute;cter personal y ha establecido todos los medios t&eacute;cnicos razonables, a su alcance, para evitar la p&eacute;rdida, mal uso, alteraci&oacute;n, acceso no autorizado y robo de los datos que el USUARIO comunique a </span><span className="c0">EASY TRUCK</span><span className="c1">, sin perjuicio de informarle de que las medidas de seguridad en Internet no son infalibles.</span></p>
                            <p className="c2"><span className="c1">A trav&eacute;s de nuestra plataforma no se guarda informaci&oacute;n de tarjeta de cr&eacute;dito ni d&eacute;bito de ninguno de los tarjetahabientes. Esta informaci&oacute;n, en ning&uacute;n momento ser&aacute; retenida, divulgada o registrada, si no que utiliza procesadores terceros de pago. La &uacute;nica informaci&oacute;n que pasa hacia la plataforma del tercero son los datos del propietario, correo electr&oacute;nico y monto total del importe a pagar. Dicha informaci&oacute;n est&aacute; sujeta a los t&eacute;rminos de esta POL&Iacute;TICA.</span></p>
                            <p className="c3"><span className="c1">VII. LIBERACI&Oacute;N DE RESPONSABILIDAD</span></p>
                            <p className="c2"><span className="c1">El USUARIO reconoce y acepta que el uso del SITIO WEB y la APP, se realiza en todo momento bajo su entero riesgo y responsabilidad. </span><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;no ser&aacute; responsable por el uso indebido que pueda realizarse de estos medios de comunicaci&oacute;n y/o tecnolog&iacute;as.</span></p>
                            <p className="c2"><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;no ser&aacute; responsable por da&ntilde;os y perjuicios de cualquier tipo causados en los equipos electr&oacute;nicos (c&oacute;mputo, tel&eacute;fono, tableta, u otros) del USUARIO por virus, gusanos o cualquier otro elemento da&ntilde;ino. El USUARIO reconoce que el uso de la red Internet supone la asunci&oacute;n de un riesgo de que sus equipos inform&aacute;ticos puedan ser afectados por los elementos antes enunciados. A tal efecto, corresponde al USUARIO, en todo caso, la disponibilidad de herramientas adecuadas para la detecci&oacute;n y eliminaci&oacute;n de programas electr&oacute;nicos da&ntilde;inos que puedan suponer un riesgo para su equipo de c&oacute;mputo.</span></p>
                            <p className="c2"><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;no tendr&aacute; responsabilidad por la falta de ejecuci&oacute;n de sus obligaciones cuando medie fuerza mayor, caso fortuito o la entrada en vigencia de legislaci&oacute;n gubernamental, regulaciones o restricciones de cualquier otra &iacute;ndole que le impidan la ejecuci&oacute;n de sus obligaciones.</span></p>
                            <p className="c3"><span className="c1">VIII. CAMBIOS EN LA POLITICA DE PRIVACIDAD</span></p>
                            <p className="c2"><span className="c0">EASY TRUCK</span><span className="c1">&nbsp;se reserva la posibilidad de realizar cambios o ajustes a la presente Pol&iacute;tica de Privacidad. Si ejecutamos modificaciones o ajustes a esta Pol&iacute;tica de Privacidad se lo notificaremos a la hora de utilizar los SERVICIOS o por alguno de los medios de contacto indicados por el USUARIO para recibir comunicaciones de parte de </span><span className="c0">EASY TRUCK</span><span className="c1">.</span></p>
                            <p className="c2"><span className="c1">Si tiene alguna duda que le inhibe de aceptar esta POL&Iacute;TICA, puede aclararla escribi&eacute;ndonos al correo </span><span className="c6">info@easytrucks.com</span><span className="c9">&nbsp;</span><span className="c1">o por medio de las l&iacute;neas telef&oacute;nicas disponibles en la secci&oacute;n de &ldquo;Cont&aacute;ctanos&rdquo; del portal web de </span><span className="c0">EASY TRUCK</span><span className="c1">.</span></p>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                        <div className="col-md-6 col-12">
                            <button className="btn btn-secondary btn-block"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClose();
                                }}
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default ModalTerminos;
