import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/proyectos';

import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import ProyectosAdjudicados from './index'
import Card from "Utils/Cards/Card";
import { ADJUDICADO_PENDIENTE_DE_INICIAR, EN_CURSO, FINALIZADO, VENCIDO } from '../../../../../utility/constants'


class ProyectosAdjudicadosTabs extends Component {
    render() {
        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo={"MIS PROYECTOS"}>
                    <Tabs
                        defaultActiveKey={String(ADJUDICADO_PENDIENTE_DE_INICIAR)}
                        tabBarPosition="top"
                        onChange={(tab) => this.props.estadoProyectoTab(tab)}
                        renderTabBar={() => <ScrollableInkTabBar />}
                        renderTabContent={() => <TabContent />}
                        activeKey={String(this.props.estado_proyecto_tab)}
                    >
                        <TabPane tab="Adjudicados" key={ADJUDICADO_PENDIENTE_DE_INICIAR}>
                            <div className="py-4 px-3">
                                <ProyectosAdjudicados proyecto_estado={ADJUDICADO_PENDIENTE_DE_INICIAR} />
                            </div>
                        </TabPane>
                        <TabPane tab="En curso" key={EN_CURSO}>
                            <div className="py-4 px-3">
                                <ProyectosAdjudicados proyecto_estado={EN_CURSO} />
                            </div>
                        </TabPane>
                        <TabPane tab="Finalizados" key={FINALIZADO} >
                            <div className="py-4 px-3">
                                <ProyectosAdjudicados proyecto_estado={FINALIZADO} />
                            </div>
                        </TabPane>
                    </Tabs>

                </Card>
            </div>
        );
    }
}
const ms2p = (state) => {
    return {
        ...state.proyectos,
    };
};
const md2p = { ...actions };
export default connect(ms2p, md2p)(ProyectosAdjudicadosTabs);
