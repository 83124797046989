import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderFilePicker, renderPasswordField, renderNumber, renderTextArea, renderImageUpload } from '../../Utils/renderField/renderField';
import { USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, USER_TRANSPORTISTA } from '../../../../utility/constants'
import { validationContratista, validationTransportista } from '../../../../utility/validationForm'
const ProfileForm = (props) => {
    const { handleSubmit, me, setAvatar, setRtuImg, editarPerfil, recargarMe, avatar, className } = props;
    useEffect(() => {
        recargarMe()
    }, []);

    return (
        <form action="" onSubmit={handleSubmit} className={`py-4 ${className || ''}`}>
            <div className="form-group grid-container">
                <div className="row col-md-12 p-0 mb-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12 d-flex flex-column">
                            <span className="gris-oscuro text-sm">Usuario</span>
                            <span className="azul bold text-sm">{me && me.username ? me.username : ""}</span>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12 d-flex flex-column">
                            <span className="gris-oscuro text-sm">Tipo de usuario</span>
                            <span className="azul bold text-sm">{me && me.tipo_usuario_text ? me.tipo_usuario_text : ""}</span>
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">
                                {me && me.tipo_usuario && me.tipo_usuario === USER_CONTRATISTA_INDIVIDUAL ? "Nombre completo*" : "Nombre comercial*"}
                            </label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" maxLength={validationContratista.nombre.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">E-Mail*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="email" className="form-control" maxLength={validationContratista.email.MaxLength} />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nit" className="m-0">Nit*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nit" component={renderField} type="text" className="form-control" maxLength={validationContratista.nit.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 d-flex">
                        <div className="col-md-12 col-sm-12 align-self-center">
                            <Field name="new_password" placeholder="Nueva contraseña" component={renderPasswordField} minLength={6} className="form-control" />
                        </div>
                    </div>
                </div>
                {me && me.tipo_usuario && me.tipo_usuario === USER_TRANSPORTISTA && (
                    <div className="row col-md-12 p-0">
                        <div className="col-md-12 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="descripcion" className="m-0">Descripción (describe tu empresa/servicio)*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="descripcion" component={renderTextArea} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                )}
                <div className="row col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Teléfono*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderNumber} type="text" className="form-control" maxLength={validationContratista.telefono.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        {me && me.tipo_usuario && me.tipo_usuario === USER_CONTRATISTA_EMPRESA &&
                            <Fragment>
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="rtu" className="m-0">RTU / patente de comercio*</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="rtu" component={renderField} type="text" className="form-control" maxLength={validationContratista.rtu.MaxLength} />
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="avatar" className="m-0">Avatar</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field setFile={setAvatar} aspect={1} name="avatar" imagen={me && me.avatar ? me.avatar : null} component={renderImageUpload} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        {me && me.tipo_usuario && me.tipo_usuario === USER_CONTRATISTA_EMPRESA &&
                            <Fragment>
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="rtu_img" className="m-0">Imagen de RTU</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field photo={me && me.rtu_img ? me.rtu_img : null} setFile={setRtuImg} name="rtu_img" component={renderFilePicker} />
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
                <div className="row col-12 text-right">
                    <span className="azul bold text-xsm ml-auto">* Campos requeridos</span>
                </div>

                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                        {me && me.tipo_usuario && me.tipo_usuario === USER_TRANSPORTISTA ?
                            <button className="btn btn-dark btn-block" onClick={editarPerfil}>Cancelar</button>
                            :
                            <Link className="btn btn-dark btn-block" to="/">Cancelar</Link>
                        }
                    </div>
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};



export default reduxForm({
    form: 'profile', // a unique identifier for this form
    validate: (data) => {
        let errors = {};
        if (data && data.new_password && data.new_password.length < 6) {
            errors.new_password = "Mínimo 6 carácteres"
        }
        if (data && data.new_password && data.new_password.length > 150) {
            errors.new_password = "Máximo 150 carácteres"
        }
        return {
            ...errors, ...validate(data, {
                nombre: validationContratista.nombre.Combine,
                email: validationContratista.email.Combine,
                telefono: validationContratista.telefono.Combine,
                nit: validationContratista.nit.Combine,
                rtu: validationContratista.rtu.Combine,
                descripcion: validationTransportista.descripcion.Combine,
            })
        }
    },
})(ProfileForm);
