import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/suscripcion';
import PasarelaPago from './Pagarsuscripcion';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.suscripcion,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PasarelaPago);
