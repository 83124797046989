import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';

const LOADER = 'LOADER_PASARELA';
const DATA = 'DATA_PASARELA';
const ITEM_DATA = 'ITEM_PASARELA';
const PAGE = 'PAGE_PASARELA';
const ORDERING = 'ORDERING_PASARELA';
const SEARCH = 'SEARCH_PASARELA';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const leer =  (proyecto, oferta) => (dispatch) => {
    const data= {proyecto, oferta, saludar: "holaass"}
    dispatch(setLoader(true));
    api.post('viaje/calcular_pago_viaje', data).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('PasarelaForm', response));
    }).catch((error) => {
        let mensaje = 'Error al realizar la solicitud, vuelva a intentar';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 4000);
        dispatch(push(`/proyecto/${proyecto}`));

    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const pagar = (data) => (dispatch) => {
    data.proyecto = data.proyecto.id
    data.oferta = data.oferta.id
    dispatch(setLoader(true));
    api.post('viaje', data).then(() => {
        NotificationManager.success('Se ha adjudicado y pagado la oferta', 'Éxito', 3000);
        dispatch(push(`/proyectos`));
    }).catch((error) => {
        let mensaje = 'Error al adjudicar y pagar la oferta, vuelva a intentar';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const eliminar = id => (dispatch, getStore) => {
    const me = getStore().login.me;
    dispatch(setLoader(true));
    api.eliminar(`${'user'}/${id}`).then(() => {
        dispatch(listar(1, me.id));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('PasarelaForm', {}));
};

const searchChange = search => (dispatch, getStore) => {
    const me = getStore().login.me;
    dispatch(setSearch(search));
    dispatch(listar(1, me.id));
};

export const actions = {
    pagar,
    leer,
    eliminar,
    searchChange,
    cleanForm,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
