import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RenderCurrency, RenderDollar } from '../renderField/renderReadField';
import moment from 'moment';
import { Rate } from 'antd';
moment.locale('es');

const persona_img = require('assets/img/icons/person.svg');

function CardOferta(props) {
    const { me, descripcion, presupuesto, creado } = props;
    return (
        <div className={`card-proyecto borde-naranja px-3 pt-3 mb-3`}>
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                <div className="row col-md-12 p-0">
                    <div className="col-lg-4 p-0 d-flex justify-content-center align-items-start">
                        <div className="col-md-12 col-sm-12">
                            <div className="d-flex align-items-center">
                                <div className="flex-2 d-flex justify-content-center align-items-center">
                                    <img src={me && me.avatar ? me.avatar : persona_img} alt="Persona" className="img-fluid" />
                                </div>
                                <div className="flex-5 d-flex flex-column ml-2">
                                    <span className="bold gris-oscuro">{me && me.nombre ? me.nombre : ""}</span>
                                    <Rate disabled defaultValue={me && me.calificacion ? me.calificacion : 1} className="naranja" />
                                    {/* <span className="gris-letras bold parrafo-dos-lineas" >{descripcionTransportista ? descripcionTransportista : ""}  <span className="gris-oscuro bold"> {descripcionTransportista ? descripcionTransportista : " "}</span></span> */}
                                    <span className="gris-letras bold parrafo-dos-lineas" >{me.descripcion ? me.descripcion : ""} </span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 p-0">
                        <div className="col-md-12 col-sm-12">
                            <span htmlFor="descripcion" className="m-0 gris bold">Descripción de oferta: </span>
                        </div>
                        <div className="col-md-12">
                            <p className="gris-oscuro bold mb-2">{descripcion ? descripcion : ""}</p>
                        </div>
                        <div className="col-md-12 col-sm-12 d-flex flex-column">
                            <span htmlFor="presupuesto_oferta" className="mb-1 gris bold">Presupuesto (oferta): <RenderDollar value={presupuesto ? presupuesto : 0} className="gris-oscuro bold" /></span>
                            <span className="gris-letras text-xsm">{`Enviada ${creado ? moment(creado).format('DD [de] MMMM YYYY ') : moment().format('DD [de] MMMM YYYY ')}`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardOferta;
