import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import { RenderCurrency, RenderDollar } from '../renderField/renderReadField';

function CardSuscripcion(props) {
    const { titulo, imagen, precio, redireccion, veneficios } = props;
    return (
        <div className="d-flex col-12 col-md-12 col-lg-4 mb-3 px-0 px-lg-3 ">
            <div className={`align-self-stretch flex-1 card-proyecto ${titulo === "MENSUAL" ? "borde-verde" : titulo === "SEMESTRAL" ? "borde-naranja" : titulo === "ANUAL" ? "borde-azul" : "borde-rojo"}`}>
                <div className="pl-0 pr-3 py-4">
                    {/* <div className="d-flex flex-column text-center justify-content-center align-content-center"> */}
                    <div className="d-flex flex-column text-center justify-content-center align-content-center">
                        {/* <span className={`mb-1 bold text-lgs ${titulo === "MENSUAL" ? "verde" : titulo === "SEMESTRAL" ? "naranja" : titulo === "ANUAL" ? "azul" : "gris-oscuro"}`}>
                            {titulo ? titulo : ""}
                        </span> */}

                        <div className=" justify-content-center align-items-center" >
                            <img src={imagen} alt="Ver" style={{ height: 150 }} />
                        </div>


                        {precio && precio > 0 &&
                            <Fragment>
                                <RenderDollar value={precio ? precio : ""} className={`my-3 mb-1 bold text-lgs ${titulo === "MENSUAL" ? "verde" : titulo === "SEMESTRAL" ? "naranja" : titulo === "ANUAL" ? "azul" : "gris-oscuro"}`} />
                                <div>
                                    <Link
                                        to={redireccion ? redireccion : ""}
                                        className={`px-4 py-2 btn py-1 ${titulo === "ANUAL" ? "btn-secondary" : titulo === "MENSUAL" ? "btn-success" : titulo === "SEMESTRAL" ? "btn-primary" : "btn-rojo"}`}
                                    >
                                        COMPRAR
                                </Link>
                                </div>
                            </Fragment>
                        }

                        <div className="pl-4 pr-3 pt-4 text-left">
                            <span className={`mb-1 bold ${titulo === "MENSUAL" ? "verde" : titulo === "SEMESTRAL" ? "naranja" : titulo === "ANUAL" ? "azul" : "gris-oscuro"}`}>BENEFICIOS</span>
                        </div>
                        <div className="text-left">
                            <ul>
                                {veneficios && veneficios.map((veneficio, index) => {
                                    return (
                                        <li key={index} className="mb-1 bold gris-oscuro">
                                            {veneficio}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardSuscripcion;
