import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { validatorFromFunction, validators, combine, validate } from 'validate-redux-form';
import { USER_TRANSPORTISTA } from '../../../../utility/constants';
import { renderField } from '../../Utils/renderField';
import { SelectField, renderNumber, renderFilePicker, AsyncSelectField2, renderFieldCheck } from '../../Utils/renderField/renderField';
import { tipo_refrigeracion } from '../../../../utility/constants';
import { validationTransportista } from '../../../../utility/validationForm'
const alerta_img = require('assets/img/icons/alert.svg');

import { api } from "api";
const eliminar_img = require('assets/img/icons/borrar_hover.svg');


const getVehiculo = (search) => {
    return api.get("tipo_vehiculo", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};




const renderVehiculos = ({ fields, meta: { error, submitFailed }, contactos }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <div className="col-12 form-group m-auto px-0 px-sm-0 pt-2">
            <div className="row my-n2">
                <div className="react-bs-container-header" style={{ height: 25 }} >
                    <div className="d-none d-md-flex row border-publicidad py-1 px-0 px-sm-1 px-lg-1 px-xl-1">
                        <div className="col-1 mb-2 d-none d-md-flex justify-content-center align-items-center">
                        </div>
                        <div className="col-md-4 mb-2 px-1">
                            <label htmlFor="tipo" className="ml-2 blanco d-block">TIPO</label>
                        </div>
                        <div className="col-md-4 mb-2 px-1">
                            <label htmlFor="regrigeracion" className="blanco d-block">REFRIGERACIÓN</label>
                        </div>
                        <div className="col-md-3 mb-2 px-1">
                            <label htmlFor="capacidad" className="blanco d-block">CAPACIDAD</label>
                        </div>
                    </div>
                </div>
            </div>

            {fields.map((transporte, index) => {
                return (
                    <div className="row border-publicidad py-3 px-0 px-sm-1 flex-md-row flex-column" key={index}>
                        <div className="order-1 order-md-0 mx-auto px-1">
                            {fields.length > 1 &&
                                <img src={eliminar_img} alt="Eliminar"
                                    className="action_img"
                                    onClick={() => {
                                        fields.remove(index)
                                    }}
                                />
                            }
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="tipo" className="gris-oscuro d-block d-md-none">TIPO</label>
                            <Field
                                valueKey="id"
                                name={`${transporte}.vehiculo`}
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField2}
                                loadOptions={getVehiculo}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="regrigeracion" className="gris-oscuro d-block d-md-none">REFRIGERACIÓN</label>
                            <Field name={`${transporte}.refrigeracion`} component={SelectField} options={tipo_refrigeracion} className="form-control" />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="capacidad" className="gris-oscuro d-block d-md-none">CAPACIDAD</label>
                            <Field
                                name={`${transporte}.capacidad`}
                                type="text"
                                component={renderField}
                                placeholder="30 pies"
                                maxLength={validationTransportista.vehiculosCapacidad.MaxLength}
                            />
                        </div>
                    </div>
                )
            })}
            <div className="d-flex justify-content-center mt-3">
                <button type="button" className="btn btn-secondary py-1" onClick={() => {
                    fields.push({})
                }}>
                    AGREGAR
                </button>
            </div>
        </div>
    )
}



class RegisterForm extends Component {

    render() {
        const { handleSubmit, previousStep, openModal } = this.props;

        return (
            // <form name="loginForm" className="form-validate d-flex flex-sm-row align-items-center" onSubmit={handleSubmit}>
            <form name="loginForm" onSubmit={handleSubmit}>


                <div className="row justify-content-center p-0 mt-5">
                    <div className="col-md-10">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="transportes" className="m-0">Vehículos*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <FieldArray name="transportes" component={renderVehiculos} />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center p-0 mt-5">
                    <div className="d-flex justify-content-center align-items-center">
                        <div>
                            <Field
                                name="terminos_condiciones"
                                component={renderFieldCheck}
                            />
                        </div>
                        <div>
                            <span className="gris-oscuro bold mt-0 ml-1">Acepto la política de privacidad y términos</span>
                            <br />
                            <span className="gris-oscuro bold mt-0 ml-1">y condiciones de uso de la plataforma Easy Truck</span>
                            <br />
                            <span className="naranja bold mt-0 ml-1" onClick={openModal}>VER...</span>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center p-0 mt-5 mb-3">
                    <div className="justify-content-center align-items-center">
                        <img src={alerta_img} alt="Alerta" width={25} className="img-fluid blink-danger" />
                        <span className="azul bold mt-0 ml-1">Se enviará confirmación de registro!</span>
                    </div>
                </div>

                <div className="row justify-content-center ">
                    <div className="col-xl-8 col-lg-9 col-md-10 col-sm-10 col-12 d-flex flex-column">

                        <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                            <div className="col-md-5 col-12">
                                <button type="button" className="btn btn-dark btn-block" onClick={previousStep}>Anterior</button>
                            </div>
                            <div className="col-md-5 col-12 mt-md-0 mt-3">
                                <button type="submit" className="btn btn-primary btn-block">Enviar</button>
                            </div>
                        </div>

                    </div>
                </div>


            </form>
        );
    };
}


const validatePrimary = (values) => {
    let errors = {};
    let DetalleArrayErrors = [];
    if (!values.terminos_condiciones == true) {
        // errors.terminos_condiciones = "Se requiere aceptar los términos y condiciones";
        errors.terminos_condiciones = "!Acepte!";
    }

    DetalleArrayErrors = [];
    if (values && values.transportes && values.transportes.length) {
        values.transportes.forEach((detalle, detalleIndex) => {
            DetalleArrayErrors[detalleIndex] = validate(detalle, {
                vehiculo: validationTransportista.vehiculosVehiculo.Combine,
                refrigeracion: validationTransportista.vehiculosRefrigeracion.Combine,
                capacidad: validationTransportista.vehiculosCapacidad.Combine,
            })
        });
    }
    errors.transportes = DetalleArrayErrors
    return errors;
};

RegisterForm = reduxForm({
    form: 'register', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: {
        tipo_usuario: USER_TRANSPORTISTA,
        terminos_condiciones: false
    },
    validate: validatePrimary
})(RegisterForm);

export default RegisterForm;
