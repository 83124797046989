import React, { Component, Fragment } from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card";
import LoadMask from 'Utils/LoadMask/LoadMask';
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Header from "Utils/Grid/Header";
import Select from 'react-select';
import classNames from 'classnames';
import chroma from 'chroma-js';
import { SWALMOD, USER_CONTRATISTA_EMPRESA, ADJUDICADO_PENDIENTE_DE_INICIAR, EN_CURSO, FINALIZADO } from '../../../../../utility/constants'
import CardProyecto from '../../../Utils/Cards/CardProyecto';
import { Pagination } from 'antd';
const alerta_img = require('assets/img/icons/alert.svg');

export default class ProyectosAdjudicados extends Component {
    componentWillMount() {
        const { proyectosAdjudicadosTransportista, proyecto_estado } = this.props;
        proyectosAdjudicadosTransportista(proyecto_estado);
    }

    eliminar = (id) => {
        return () => {
            SWALMOD.fire({
                title: '¿Eliminar?',
                text: '¡No podrás revertir esta acción!',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                imageUrl: alerta_img,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    };
    proyectosAdjudicadosTransportista = (page = 1) => {
        const { proyectosAdjudicadosTransportista, proyecto_estado } = this.props;
        return proyectosAdjudicadosTransportista(proyecto_estado, page);
    }
    paginaActual = () => {

    }




    render() {
        const { data_adjudicados, loader, proyecto_estado, me, page_adjudicados } = this.props; //Constantes

        return (
            <Fragment>
                {data_adjudicados && (
                    proyecto_estado == ADJUDICADO_PENDIENTE_DE_INICIAR ||
                    proyecto_estado == EN_CURSO ||
                    proyecto_estado == FINALIZADO)
                    &&
                    <LoadMask loading={loader} blur>
                        <div className="row px-0">
                            {data_adjudicados && data_adjudicados.results && data_adjudicados.results.map((viaje, index) => {
                                return (

                                    <CardProyecto
                                        key={index}
                                        id={viaje.proyecto && viaje.proyecto.id ? viaje.proyecto.id : index}
                                        nombre={viaje.proyecto && viaje.proyecto.nombre ? viaje.proyecto.nombre : ""}
                                        fecha_cierre={viaje.proyecto && viaje.proyecto.fecha_cierre_ofertas ? viaje.proyecto.fecha_cierre_ofertas : ""}
                                        origen={viaje.proyecto && viaje.proyecto.pais_origen && viaje.proyecto.ciudad_origen && viaje.proyecto.pais_origen.nombre && viaje.proyecto.ciudad_origen.nombre ? `${viaje.proyecto.ciudad_origen.nombre}, ${viaje.proyecto.pais_origen.nombre}` : ""}
                                        destino={viaje.proyecto && viaje.proyecto.pais_destino && viaje.proyecto.ciudad_destino && viaje.proyecto.pais_destino.nombre && viaje.proyecto.ciudad_destino.nombre ? `${viaje.proyecto.ciudad_destino.nombre}, ${viaje.proyecto.pais_destino.nombre}` : ""}
                                        tipo_transporte={viaje.proyecto && viaje.proyecto.tipo_vehiculo && viaje.proyecto.tipo_vehiculo.nombre ? viaje.proyecto.tipo_vehiculo.nombre : ""}
                                        presupuesto={viaje.proyecto && viaje.proyecto.presupuesto ? viaje.proyecto.presupuesto : 0}
                                        fecha_hora_posicionamiento={viaje.proyecto && viaje.proyecto.fecha_hora_posicionamiento ? viaje.proyecto.fecha_hora_posicionamiento : ""}
                                        ofertas={viaje.proyecto && viaje.proyecto.total_ofertas ? viaje.proyecto.total_ofertas : 0}
                                        tipo={viaje.proyecto && viaje.proyecto.estado_proyecto ? viaje.proyecto.estado_proyecto : ""}
                                        eliminarProyecto={this.eliminar}
                                        tipo_usuario={me && me.tipo_usuario ? me.tipo_usuario : USER_CONTRATISTA_EMPRESA}
                                        listado_adjudicado_transportista={true}
                                        adjudicado={viaje.proyecto && viaje.proyecto.adjudicado}
                                        codigo_tracking={viaje && viaje.proyecto && viaje.proyecto.codigo_tracking ? viaje.proyecto.codigo_tracking : null}
                                    />
                                )
                            })}
                            {data_adjudicados && data_adjudicados.results && data_adjudicados.results.length === 0 && (
                                <div className="col-12 d-flex justify-content-center align-items-center mb-3" style={{ height: "5rem" }}>
                                    <h2>Sin resultados</h2>
                                </div>
                            )}
                        </div>
                        {data_adjudicados && data_adjudicados.results && data_adjudicados.count > 0 && (
                            <div className="w-100 p-0 text-right mb-3">
                                <Pagination
                                    size="small"
                                    current={page_adjudicados}
                                    total={data_adjudicados && data_adjudicados.count ? data_adjudicados.count : 0}
                                    onChange={this.proyectosAdjudicadosTransportista}
                                />
                            </div>
                        )}
                    </LoadMask>

                }
            </Fragment>
        )
    }
}
