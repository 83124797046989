import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { USER_TRANSPORTISTA } from '../../../../utility/constants';
import { renderField, renderTextArea } from '../../Utils/renderField';
import { renderNumber, AsyncSelectField2 } from '../../Utils/renderField/renderField';
import { api } from "api";
import { validationTransportista } from '../../../../utility/validationForm'

const getPais = (search) => {
    return api.get("pais/listar_pais", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


class RegisterForm extends Component {
    state = {
        Ciudad: []
    }
    getCiudad = (search) => {
        const { pais_residencia } = this.props;
        const params = { search }

        if (pais_residencia.id) {
            params.pais = pais_residencia.id
        }

        return api.get("ciudad", params).then((data) => {
            if (data) {
                this.setState({ Ciudad: data.results });
                return data.results
            };
            return [];
        }).catch(() => {
            return [];
        });
    };
    componentDidUpdate(prevProps) {
        if (this.props.pais_residencia !== prevProps.pais_residencia) {
            this.getCiudad('');
        }
    }
    render() {
        const { openModal, handleSubmit, clearSelectPais } = this.props;

        return (
            // <form name="loginForm" className="form-validate d-flex flex-sm-row align-items-center" onSubmit={handleSubmit}>
            <form name="loginForm" onSubmit={handleSubmit}>

                <div className="row justify-content-center my-5 ">

                    <div className="col-12 col-md-5 mb-2 mb-sm-3 align-self-end">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="username">Nombre de usuario*</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field
                                name="username"
                                component={renderField}
                                type="text"
                                className="form-control"
                                maxLength={validationTransportista.username.MaxLength}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-5 mb-2 mb-sm-3 align-self-end">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="nombre">Nombre de transporte (razón social o comercial)  *</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field
                                name="nombre"
                                component={renderField}
                                type="text"
                                className="form-control"
                                maxLength={validationTransportista.nombre.MaxLength}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-5 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="email">E-Mail*</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field name="email" component={renderField} type="email" className="form-control" maxLength={validationTransportista.email.MaxLength} />
                        </div>
                    </div>

                    <div className="col-12 col-md-5 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="telefono">Teléfono*</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field name="telefono" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationTransportista.telefono.MaxLength} />
                        </div>
                    </div>

                    <div className="col-12 col-md-5 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="pais_residencia">País*</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field
                                valueKey="id"
                                name="pais_residencia"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField2}
                                loadOptions={getPais}
                                onCambio={clearSelectPais}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-5 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="ciudad_residencia">Ciudad*</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field
                                valueKey="id"
                                name="ciudad_residencia"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField2}
                                loadOptions={this.getCiudad}
                                defaultOptions={this.state.Ciudad}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-10 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="px-0" htmlFor="descripcion">Descripción (describe tu empresa/servicio)*</label>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Field
                                name="descripcion"
                                component={renderTextArea}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-10 mb-2 mb-sm-3">
                        <div className="col-md-12 col-sm-12 text-right">
                            <span className="azul bold text-xsm">* Campos requeridos</span>
                        </div>
                    </div>
                </div>


                <div className="row justify-content-center ">
                    <div className="col-xl-8 col-lg-9 col-md-10 col-sm-10 col-12 d-flex flex-column">

                        <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                            <div className="col-md-5 col-12">
                                <Link className="btn btn-dark btn-block" to="/login">Cancelar</Link>
                            </div>
                            <div className="col-md-5 col-12 mt-md-0 mt-3">
                                <button type="submit" className="btn btn-primary btn-block">Siguiente</button>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        );
    };
}


const selector = formValueSelector('CuponForm');
RegisterForm = reduxForm({
    form: 'register', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: {
        tipo_usuario: USER_TRANSPORTISTA
    },
    validate: (data) => {
        return validate(data, {
            nombre: validationTransportista.nombre.Combine,
            email: validationTransportista.email.Combine,
            telefono: validationTransportista.telefono.Combine,
            descripcion: validationTransportista.descripcion.Combine,
            username: validationTransportista.username.Combine,
            pais_residencia: validationTransportista.pais_residencia.Combine,
            ciudad_residencia: validationTransportista.ciudad_residencia.Combine,
        });
    },
})(RegisterForm);



RegisterForm = connect(state => {
    const form = state.form.register;
    let pais_residencia = "";

    if (form && form.values) {
        const values = form.values;

        if (values.pais_residencia) {
            pais_residencia = values.pais_residencia
        }
    }
    return {
        pais_residencia
    }
})(RegisterForm);

export default RegisterForm;
